/*=========================================================================================
  M.Djezou
  File Name: moduleOperationActions.js
  Description: Operation Module Actions
  ----------------------------------------------------------------------------------------

==========================================================================================*/

import axios from '@/axios.js'

export default {

  getOperationToDay ({commit}, payload) {
    const {selectDate, Officine} = payload
    return new Promise((resolve, reject) => {
      axios.get(`/api/operation/today/${selectDate}/${Officine}`)
        .then((response) => {
          commit('SET_OPERATION_TO_DAY', response.data)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getOperationAll ({commit}, payload) {
    const {selectDate} = payload
    return new Promise((resolve, reject) => {
      axios.get(`/api/operation/all/${selectDate}`)
        .then((response) => {
          commit('SET_OPERATIONS', response.data)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  removeOperation ({commit}, payload) {
    const {id} = payload
    return new Promise((resolve, reject) => {
      axios.delete(`/api/operation/delete/${id}`)
        .then((response) => {
          commit('REMOVE_OPERATION', id)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  getOperationPeriode ({commit}, payload) {
    const {gteDate, ltDate, Officine} = payload
    return new Promise((resolve, reject) => {
      axios.get(`/api/operation/${gteDate}/${ltDate}/${Officine}`)
        .then((response) => {
          commit('SET_OPERATIONS', response.data)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getOperationForDashBoard ({commit}, payload) {
    const {dashDate, officine} = payload
    return new Promise((resolve, reject) => {
      axios.get(`/api/operation/today/${dashDate}/${officine}`)
        .then((response) => {
          /* console.log(response.data) */
          const operations = response.data.length > 0 ? response.data.filter((operation) => {
            if (operation.typeOperation === 'Règlement cmde cli' || operation.typeOperation === 'Règlement cpt') return true
          }) : []
          const data = {
            OfficineId: officine,
            Operations: operations
          }
          commit('ADD_ENCAISSEMENT_TODAY_BY_AGENCE', data)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getOperationForPrevDayDashBoard ({commit}, payload) {
    const {dashDate, officine} = payload
    return new Promise((resolve, reject) => {
      axios.get(`/api/operation/today/${dashDate}/${officine}`)
        .then((response) => {
          /* console.log(response.data) */
          const operations = response.data.length > 0 ? response.data.filter((operation) => {
            if (operation.typeOperation === 'Règlement cmde cli' || operation.typeOperation === 'Règlement cpt') return true
          }) : []
          const data = {
            OfficineId: officine,
            Operations: operations
          }
          commit('ADD_ENCAISSEMENT_PREVDAY_BY_AGENCE', data)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  initialisationCaisseAvecClotureCaisse (context, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/caisse/initialisation-avec-cloture', {payload})
        .then((response) => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  initialisationCaisse (context, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/caisse/initialisation', {payload})
        .then((response) => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }


  /* makeClotureCaisseRecette ({commit}, payload) {

  } */
}

