/*=========================================================================================
  File Name: moduleCommandeVerreMutation.js
  Description: mutation of module commande verre
  ----------------------------------------------------------------------------------------
==========================================================================================*/

export default {
  SET_VERRE_COMMANDE (state, commandes) {
    state.verresCommandes = commandes
  },
  ADD_VERRE_COMMANDE (state, commande) {
    state.verresCommandes.unshift(commande)
  },
  UPDATE_VERRE_COMMANDE (state, commande) {
    const index = state.verresCommandes.findIndex((c) => c._id === commande._id)
    Object.assign(state.verresCommandes[index], commande)
  },
  DELETE_VERRE_COMMANDE (state, id) {
    const index = state.verresCommandes.findIndex((c) => c._id === id)
    state.verresCommandes.splice(index, 1)
  }
}
