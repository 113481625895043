/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
  SET_USERS (state, users) {
    state.users = users
  },
  ADD_USER (state, user) {
    state.users.unshift(user)
  },
  UPDATE_USER (state, user) {
    const userIndex = state.users.findIndex((m) => m._id === user._id)
    Object.assign(state.users[userIndex], user)
  },

  REMOVE_RECORD (state, itemId) {
    const userIndex = state.users.findIndex((u) => u._id === itemId)
    state.users.splice(userIndex, 1)
  }
}
