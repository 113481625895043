/*=========================================================================================
  File Name: moduleEtatActions.js
  Description: Etat Module Actions
  ----------------------------------------------------------------------------------------
==========================================================================================*/

import axios from '@/axios.js'

export default {
  getReglementClient ({ commit }, payload) {
    const {DateDebut, DateFin, idOfficine} = payload
    return new Promise((resolve, reject) => {
      axios.get(`/api/etat/reglement-commande-client/${DateDebut}/${DateFin}/${idOfficine}`)
        .then((response) => {
          commit('SET_ETAT_REGLEMENT_CLIENT', response.data)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  getBROnSpecifiquePeriode ({commit}, payload) {
    const {DateDebut, DateFin, idOfficine, status } = payload
    return new Promise((resolve, reject) => {
      axios.get(`/api/etat/br/${DateDebut}/${DateFin}/${idOfficine}/${status}`)
        .then((response) => {
          commit('SET_ETAT_BR', response.data)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getStockArticleOnSpecifiquePeriode ({commit}, payload) {
    const {idOfficine, categorie_article } = payload
    return new Promise((resolve, reject) => {
      axios.get(`/api/etat/stock-article/${idOfficine}/${categorie_article}`)
        .then((response) => {
          commit('SET_ETAT_STOCK_ARTICLE', response.data)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getArticleSortieOnSpecifiquePeriode ({commit}, payload) {
    const {DateDebut, DateFin, idOfficine } = payload
    return new Promise((resolve, reject) => {
      axios.get(`/api/etat/article-sortie/${DateDebut}/${DateFin}/${idOfficine}`)
        .then((response) => {
          commit('SET_ETAT_ARTICLE_SORTIE', response.data)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getProformaOnSpecifiquePeriode ({commit}, payload) {
    const {DateDebut, DateFin, idOfficine } = payload
    return new Promise((resolve, reject) => {
      axios.get(`/api/etat/proforma/${DateDebut}/${DateFin}/${idOfficine}`)
        .then((response) => {
          commit('SET_ETAT_PROFORMA', response.data)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  getDepenseOnPeriode ({commit}, payload) {
    const {DateDebut, DateFin, idOfficine } = payload
    return new Promise((resolve, reject) => {
      axios.get(`/api/depense/${idOfficine}/${DateDebut}/${DateFin}`)
        .then((response) => {
          commit('SET_ETAT_DEPENSE', response.data)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  getApproDepenseOnPeriode ({commit}, payload) {
    const {DateDebut, DateFin, idOfficine } = payload
    return new Promise((resolve, reject) => {
      axios.get(`/api/appro-caisse-recette/${idOfficine}/${DateDebut}/${DateFin}`)
        .then((response) => {
          commit('SET_ETAT_APPRO_DEPENSE', response.data)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  getCA ({commit}, payload) {
    const {DateDebut, DateFin, idOfficine } = payload
    return new Promise((resolve, reject) => {
      axios.get(`/api/ca/${idOfficine}/${DateDebut}/${DateFin}`)
        .then((response) => {
          commit('SET_ETAT_CA', response.data)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

}
