/*=========================================================================================
  File Name: moduleProformaAction.js
  Description: mutation of module proforma
  ----------------------------------------------------------------------------------------
==========================================================================================*/


export default {

// store content all proformas
  SET_PROFORMAS (state, proformas) {
    state.proformas = proformas
  },

  ADD_PROFORMA (state, proformas) {
    state.proformas.unshift(proformas)
  },

  ADD_SEARCH_PROFORMA (state, proforma) {
    const proformaserach = state.proformas.find((p) => p._id === proforma._id)

    if (!proformaserach) state.proformas.unshift(proforma)
  },

  UPDATE_PROFORMA (state, proforma) {
    const proformaIndex = state.proformas.findIndex((m) => m._id === proforma._id)
    Object.assign(state.proformas[proformaIndex], proforma)
  },

  REMOVE_PROFORMA (state, itemId) {
    const proformaIndex = state.proformas.findIndex((u) => u._id === itemId)
    state.proformas.splice(proformaIndex, 1)
  }
}
