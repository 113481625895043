/*=========================================================================================
  File Name: moduleDepense.js
  Description: Depense Module
  ----------------------------------------------------------------------------------------
==========================================================================================*/


import state from './moduleConfigsState.js'
import mutations from './moduleConfigsMutations.js'
import actions from './moduleConfigsActions.js'
import getters from './moduleConfigsGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

