/*=========================================================================================
// <<<<<<< M.Djezou
  File Name: moduleInventaire.js
  Description: Inventaire Module
  ----------------------------------------------------------------------------------------
// =======
  File Name: moduleFournisseur.js
  Description: module Fournisseur
  ----------------------------------------------------------------------------------------
  Author: Eudoxie Djetouan
  Author email: eudoxiedjetouan@gmail.com
// >>>>>>> main
==========================================================================================*/


import state from './moduleInventaireState.js'
import mutations from './moduleInventaireMutations.js'
import actions from './moduleInventaireActions.js'
import getters from './moduleInventaireGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

