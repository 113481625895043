/*=========================================================================================
  File Name: moduleCompte.js
  Description: Compte Module
  ----------------------------------------------------------------------------------------
  Author: Djezou Eric Martial
==========================================================================================*/


import state from './moduleCompteState.js'
import mutations from './moduleCompteMutations.js'
import actions from './moduleCompteActions.js'
import getters from './moduleCompteGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
