/*=========================================================================================
  Nom du fichier: moduleContactMutations.js
  Description: Contact Module Mutations
  ----------------------------------------------------------------------------------------
  Author: Djezou Eric Martial
==========================================================================================*/

export default {
  SET_CONTACT (state, data) {
    state.contacts = data
  },
  ADD_CONTACT (state, data) {
    state.contacts.unshift(data)
  },
  UPDATE_CONTACT (state, data) {
    const index = state.contacts.length > 0 ? state.contacts.findIndex((s) => s._id === data._id) : null

    if (index === 0 || index !== null) {
      Object.assign(state.contacts[index], data)
    }
  },
  DELETE_CONTACT (state, id) {
    const index = state.contacts.length > 0 ? state.contacts.findIndex((s) => s._id === id) : null
    state.contacts.splice(index, 1)
  }
}