/*=========================================================================================
  File Name: moduleCalendarGetters.js
  Description: Calendar Module Getters
  ----------------------------------------------------------------------------------------
==========================================================================================*/


export default {
  getArticleByCategorieArticle: state => categorieId => {
    const articlesTheorique = state.articles.filter((a) => a.IdCateg === categorieId)
    const articlesReel = articlesTheorique.filter((a) => a.stock > 0)

    const activeUserInfos = JSON.parse(localStorage.getItem('userInfo'))
    return articlesReel.length ? articlesReel.filter((a) => a.IdOfficine === activeUserInfos.IdOfficine) : []
  },
  getArticleWhereQtyIsSupThanZeroByOfficineId: state => officineId => {
    const articles = state.articles.filter((a) => a.stock > 0)
    const articlesOfficine = articles.filter((a) => a.IdOfficine === officineId)
    return articlesOfficine.length ? articlesOfficine : []
  },
  getArticleByOfficineId: state => officineId => {
    return state.articles.filter((a) => a.IdOfficine === officineId)
  },
  getArticleTransfererById: state => id => {
    return state.articles_transferer.find((a) => a._id === id)
  },
  getArticleById: state => id => {
    return state.articles.find((a) => a._id === id) ? state.articles.find((a) => a._id === id) : {}
  },
  getStockArticleLength: state => {
    return state.articles.length
  }

}
