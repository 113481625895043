
import axios from '@/axios.js'

export default {
  createCategorieArticle ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/categorie-article/create', {payload})
        .then((response) => {
          commit('ADD_CATEGORIE_ARTICLE', response.data)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getCategorieArticles ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/categorie-article')
        .then((response) => {
          commit('SET_CATEGORIE_ARTICLE', response.data)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updateCategorieArticle ({ commit }, payload) {
    const { id } = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/categorie-article/${id}`, {payload})
        .then((response) => {
          commit('UPDATE_COMMANDE_FOURN', response.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  deleteCategorieArticle ({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/categorie-article/${id}`)
        .then((response) => {
          commit('DELETE_CATEGORIE_ARTICLE', id)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

}
