/*=========================================================================================
  File Name: moduleEtatMutations.js
  Description: Etat Module Mutations
  ----------------------------------------------------------------------------------------
==========================================================================================*/

export default {
  SET_COMMANDES_FOURN (state, data) {
    state.pager = data.pager
    state.pageOfItems = data.pageOfItems
  },
  SET_ETAT_REGLEMENT_CLIENT (state, data) {
    state.reglements = data
  },
  SET_ETAT_BR (state, data) {
    state.br = data
  },
  SET_ETAT_STOCK_ARTICLE (state, data) {
    state.articles_en_stock = data
  },
  SET_ETAT_ARTICLE_SORTIE (state, data) {
    state.articles_sortie = data
  },
  SET_ETAT_PROFORMA (state, data) {
    state.proformas = data
  },
  SET_ETAT_DEPENSE (state, data) {
    state.caisse_depenses = data
  },
  SET_ETAT_APPRO_DEPENSE (state, data) {
    state.appro_depenses = data
  },
  SET_ETAT_CA (state, data) {
    state.ca = data
  },
  /* SET_TRANSFERER_COMMANDES_FOURN (state, data) {
    state.pager = data.pager
    state.pageOfItems = data.pageOfItems
  }, */
  ADD_COMMANDE_FOURN (state, commande_fourns) {
    state.pageOfItems.unshift(commande_fourns)
  },
  UPDATE_COMMANDE_FOURN (state, id) {
    const commandeIndex = state.pageOfItems.findIndex((m) => m._id === id)
    Object.assign(state.pageOfItems[commandeIndex], id)
  },
  DELETE_COMMANDE_FOURN (state, itemId) {
    const commandeIndex = state.pageOfItems.findIndex((u) => u._id === itemId)
    state.pageOfItems.splice(commandeIndex, 1)
  }
}
