/*=========================================================================================
  M.Djezou
  File Name: moduleBilletageDepenseState.js
  Description: BilletageDepense Module State
  ----------------------------------------------------------------------------------------
==========================================================================================*/

export default {
  billetage_depense: null
}
