/*=========================================================================================
  File Name: moduleBanqueActions.js
  Description: Banque Module Actions
  ----------------------------------------------------------------------------------------
==========================================================================================*/

import axios from '@/axios.js'

export default {

  addBanque ({ commit }, payload) {

    return new Promise((resolve, reject) => {
      axios.post('/api/add/banque', { payload })
        .then((response) => {
          commit('ADD_BANQUE', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateBanque ({commit}, payload) {
    const { id } = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/banque/update/${id}`, { payload })
        .then((response) => {
          commit('UPDATE_BANQUE', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  fetchBanques ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/banques')
        .then((response) => {
          commit('SET_BANQUES', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  removeBanque ({ commit }, banqueId) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/banque/${banqueId}`)
        .then((response) => {
          commit('REMOVE_RECORD', banqueId)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
