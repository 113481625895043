/*=========================================================================================
  File Name: moduleCommandeVerre.js
  Description: module commande verre
  ----------------------------------------------------------------------------------------
==========================================================================================*/


import state from './moduleCommandeVerreState.js'
import mutations from './moduleCommandeVerreMutations.js'
import actions from './moduleCommandeVerreActions.js'
import getters from './moduleCommandeVerreGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

