/*=========================================================================================
  File Name: moduleCentreHospitalierMutations.js
  Description: CentreHospitalier Module Mutations
  ----------------------------------------------------------------------------------------
==========================================================================================*/


export default {
  SET_CENTRE_HOSPITALIERS (state, centre_hospitalier) {
    state.centre_hospitaliers = centre_hospitalier
  },
  ADD_CENTRE_HOSPITALIER (state, centre_hospitalier) {
    state.centre_hospitaliers.unshift(centre_hospitalier)
  },
  UPDATE_CENTRE_HOSPITALIER (state, centre_hospitalier) {
    const centre_hospitalierIndex = state.centre_hospitaliers.findIndex((m) => m._id === centre_hospitalier._id)
    Object.assign(state.centre_hospitaliers[centre_hospitalierIndex], centre_hospitalier)
  },
  REMOVE_RECORD (state, itemId) {
    const centre_hospitalierIndex = state.centre_hospitaliers.findIndex((u) => u._id === itemId)
    state.centre_hospitaliers.splice(centre_hospitalierIndex, 1)
  }
}
