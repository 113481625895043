/*=========================================================================================
  File Name: moduleCommandeVerreMutation.js
  Description: mutation of module commande verre
  ----------------------------------------------------------------------------------------
==========================================================================================*/

export default {
  filterCommandesVerres: state => status => {
    if (status === true | status === false) return state.verresCommandes.length > 0 ? state.verresCommandes.filter((c) => c.livre === status) : null
    return state.verresCommandes
    
  }
}
