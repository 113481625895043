/*=========================================================================================
  File Name: moduleEtatGetters.js
  Description: Etat Module Getters
  ----------------------------------------------------------------------------------------
==========================================================================================*/

export default {
  untransfertCommande: state => {
    const commandes_four = state.pageOfItems ? state.pageOfItems.filter((c) => c.transfert_total_in_stock === false) : []
    return commandes_four.length ? commandes_four : []
  },
  transfertCommande: state => {
    const commandes_four = state.pageOfItems ? state.pageOfItems.filter((c) => c.transfert_total_in_stock === true) : []
    return commandes_four.length ? commandes_four : []
  },
  totalPaArticleBr: state => {
    let total = 0
    state.br.forEach(bon => {
      total = total + bon.details.reduce((accumulator, article) => {
        return accumulator + (article.PrixAchatBrut)
      }, 0)
    })

    return total > 0 ? total : 0
  },
  totalPvHtArticleBr: state => {
    let total = 0
    state.br.forEach(bon => {
      total = total + bon.details.reduce((accumulator, article) => {
        return accumulator + (article.PrixVenteHT)
      }, 0)
    })

    return total > 0 ? total : 0
  },
  totalPvTtcArticleBr: state => {
    let total = 0
    state.br.forEach(bon => {
      total = total + bon.details.reduce((accumulator, article) => {
        return accumulator + (article.PrixVenteTTC !== 0 ? +article.PrixVenteTTC : +article.PrixVenteHT)
      }, 0)
    })

    return total > 0 ? total : 0
  },
  totalQteArticleBr: state => {
    let total = 0
    state.br.forEach(bon => {
      total = total + bon.details.length
    })
    return total > 0 ? total : 0
  },
  getDepenseAndAppro: state => {
    const depenses = state.caisse_depenses
    const appros = state.appro_depenses
    const data = appros.concat(depenses)
    return data.sort(function (a, b) {
      return new Date(a.createdAt) - new Date(b.createdAt)
    })
  }
}
