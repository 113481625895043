/*=========================================================================================
  File Name: state.js
  Description: Vuex Store - state
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import navbarSearchAndPinList from '@/layouts/components/navbar/navbarSearchAndPinList'
import clientSearchAutosuggestion from '@/layouts/components/navbar/clientSearchAutosuggestion'
import themeConfig, { colors } from '@/../themeConfig.js'

// /////////////////////////////////////////////
// Helper
// /////////////////////////////////////////////

// *From Auth - Data will be received from auth provider
const userDefaults = {
  uid         : 0,          // From Auth
  displayName : 'Djezou Eric', // From Auth
  about       : 'Dessert chocolate cake lemon drops jujubes. Biscuit cupcake ice cream bear claw brownie brownie marshmallow.',
  photoURL    : require('@/assets/images/portrait/small/avatar-s-11.jpg'), // From Auth
  status      : 'online',
  userRole    : 'admin'
}

const userInfoLocalStorage = JSON.parse(localStorage.getItem('userInfo')) || {}

// Set default values for active-user
// More data can be added by auth provider or other plugins/packages
const getUserInfo = () => {
  const userInfo = {}

  // Update property in user
  Object.keys(userDefaults).forEach((key) => {
    // If property is defined in localStorage => Use that
    userInfo[key] = userInfoLocalStorage[key] ?  userInfoLocalStorage[key] : userDefaults[key]
  })

  // Include properties from localStorage
  Object.keys(userInfoLocalStorage).forEach((key) => {
    if (userInfo[key] === undefined && userInfoLocalStorage[key] !== null) userInfo[key] = userInfoLocalStorage[key]
  })

  return userInfo
}


// Check if device is touch device
// This is used to remove perfect scrollbar from touch devices
// Using Dynamic components
const is_touch_device = () => {
  const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ')
  const mq = function (query) {
    return window.matchMedia(query).matches
  }

  if ('ontouchstart' in window || window.DocumentTouch) {
    return true
  }

  // include the 'heartz' as a way to have a non matching MQ to help terminate the join
  // https://git.io/vznFH
  const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('')
  return mq(query)
}


const languages = [
  {code: 'af', langue: 'Afrikaans'},
  {code: 'sq', langue: 'Albanian - shqip'},
  {code: 'am', langue: 'Amharic - አማርኛ'},
  {code: 'ar', langue: 'Arabic - العربية'},
  {code: 'an', langue: 'Aragonese - aragonés'},
  {code: 'hy', langue: 'Armenian - հայերեն'},
  {code: 'ast', langue: 'Asturian - asturianu'},
  {code: 'az', langue: 'Azerbaijani - azərbaycan dili'},
  {code: 'eu', langue: 'Basque - euskara'},
  {code: 'be', langue: 'Belarusian - беларуская'},
  {code: 'bn', langue: 'Bengali - বাংলা'},
  {code: 'bs', langue: 'Bosnian - bosanski'},
  {code: 'br', langue: 'Breton - brezhoneg'},
  {code: 'bg', langue: 'Bulgarian - български'},
  {code: 'ca', langue: 'Catalan - català'},
  {code: 'ckb', langue: 'Central Kurdish - کوردی (دەستنوسی عەرەبی)'},
  {code: 'zh', langue: 'Chinese - 中文'},
  {code: 'zh-HK', langue: 'Chinese (Hong Kong) - 中文（香港）'},
  {code: 'zh-CN', langue: 'Chinese (Simplified) - 中文（简体）'},
  {code: 'zh-TW', langue: 'Chinese (Traditional) - 中文（繁體）'},
  {code: 'co', langue: 'Corsican'},
  {code: 'hr', langue: 'Croatian - hrvatski'},
  {code: 'cs', langue: 'Czech - čeština'},
  {code: 'da', langue: 'Danish - dansk'},
  {code: 'nl', langue: 'Dutch - Nederlands'},
  {code: 'en', langue: 'English'},
  {code: 'en-AU', langue: 'English (Australia)'},
  {code: 'en-CA', langue: 'English (Canada)'},
  {code: 'en-IN', langue: 'English (India)'},
  {code: 'en-NZ', langue: 'English (New Zealand)'},
  {code: 'en-ZA', langue: 'English (South Africa)'},
  {code: 'en-GB', langue: 'English (United Kingdom)'},
  {code: 'en-US', langue: 'English (United States)'},
  {code: 'eo', langue: 'Esperanto - esperanto'},
  {code: 'et', langue: 'Estonian - eesti'},
  {code: 'fo', langue: 'Faroese - føroyskt'},
  {code: 'fil', langue: 'Filipino'},
  {code: 'fi', langue: 'Finnish - suomi'},
  {code: 'fr', langue: 'French - français'},
  {code: 'fr-CA', langue: 'French (Canada) - français (Canada)'},
  {code: 'fr-FR', langue: 'French (France) - français (France)'},
  {code: 'fr-CH', langue: 'French (Switzerland) - français (Suisse)'},
  {code: 'gl', langue: 'Galician - galego'},
  {code: 'ka', langue: 'Georgian - ქართული'},
  {code: 'de', langue: 'German - Deutsch'},
  {code: 'de-AT', langue: 'German (Austria) - Deutsch (Österreich)'},
  {code: 'de-DE', langue: 'German (Germany) - Deutsch (Deutschland)'},
  {code: 'de-LI', langue: 'German (Liechtenstein) - Deutsch (Liechtenstein)'},
  {code: 'de-CH', langue: 'German (Switzerland) - Deutsch (Schweiz)'},
  {code: 'el', langue: 'Greek - Ελληνικά'},
  {code: 'gn', langue: 'Guarani'},
  {code: 'gu', langue: 'Gujarati - ગુજરાતી'},
  {code: 'ha', langue: 'Hausa'},
  {code: 'haw', langue: 'Hawaiian - ʻŌlelo Hawaiʻi'},
  {code: 'he', langue: 'Hebrew - עברית'},
  {code: 'hi', langue: 'Hindi - हिन्दी'},
  {code: 'hu', langue: 'Hungarian - magyar'},
  {code: 'is', langue: 'Icelandic - íslenska'},
  {code: 'id', langue: 'Indonesian - Indonesia'},
  {code: 'ia', langue: 'Interlingua'},
  {code: 'ga', langue: 'Irish - Gaeilge'},
  {code: 'it', langue: 'Italian - italiano'},
  {code: 'it-IT', langue: 'Italian (Italy) - italiano (Italia)'},
  {code: 'it-CH', langue: 'Italian (Switzerland) - italiano (Svizzera)'},
  {code: 'ja', langue: 'Japanese - 日本語'},
  {code: 'kn', langue: 'Kannada - ಕನ್ನಡ'},
  {code: 'kk', langue: 'Kazakh - қазақ тілі'},
  {code: 'km', langue: 'Khmer - ខ្មែរ'},
  {code: 'ko', langue: 'Korean - 한국어'},
  {code: 'ku', langue: 'Kurdish - Kurdî'},
  {code: 'ky', langue: 'Kyrgyz - кыргызча'},
  {code: 'lo', langue: 'Lao - ລາວ'},
  {code: 'la', langue: 'Latin'},
  {code: 'lv', langue: 'Latvian - latviešu'},
  {code: 'ln', langue: 'Lingala - lingála'},
  {code: 'lt', langue: 'Lithuanian - lietuvių'},
  {code: 'mk', langue: 'Macedonian - македонски'},
  {code: 'ms', langue: 'Malay - Bahasa Melayu'},
  {code: 'ml', langue: 'Malayalam - മലയാളം'},
  {code: 'mt', langue: 'Maltese - Malti'},
  {code: 'mr', langue: 'Marathi - मराठी'},
  {code: 'mn', langue: 'Mongolian - монгол'},
  {code: 'ne', langue: 'Nepali - नेपाली'},
  {code: 'no', langue: 'Norwegian - norsk'},
  {code: 'nb', langue: 'Norwegian Bokmål - norsk bokmål'},
  {code: 'nn', langue: 'Norwegian Nynorsk - nynorsk'},
  {code: 'oc', langue: 'Occitan'},
  {code: 'or', langue: 'Oriya - ଓଡ଼ିଆ'},
  {code: 'om', langue: 'Oromo - Oromoo'},
  {code: 'ps', langue: 'Pashto - پښتو'},
  {code: 'fa', langue: 'Persian - فارسی'},
  {code: 'pl', langue: 'Polish - polski'},
  {code: 'pt', langue: 'Portuguese - português'},
  {code: 'pt-BR', langue: 'Portuguese (Brazil) - português (Brasil)'},
  {code: 'pt-PT', langue: 'Portuguese (Portugal) - português (Portugal)'},
  {code: 'pa', langue: 'Punjabi - ਪੰਜਾਬੀ'},
  {code: 'qu', langue: 'Quechua'},
  {code: 'ro', langue: 'Romanian - română'},
  {code: 'mo', langue: 'Romanian (Moldova) - română (Moldova)'},
  {code: 'rm', langue: 'Romansh - rumantsch'},
  {code: 'ru', langue: 'Russian - русский'},
  {code: 'gd', langue: 'Scottish Gaelic'},
  {code: 'sr', langue: 'Serbian - српски'},
  {code: 'sh', langue: 'Serbo-Croatian - Srpskohrvatski'},
  {code: 'sn', langue: 'Shona - chiShona'},
  {code: 'sd', langue: 'Sindhi'},
  {code: 'si', langue: 'Sinhala - සිංහල'},
  {code: 'sk', langue: 'Slovak - slovenčina'},
  {code: 'sl', langue: 'Slovenian - slovenščina'},
  {code: 'so', langue: 'Somali - Soomaali'},
  {code: 'st', langue: 'Southern Sotho'},
  {code: 'es', langue: 'Spanish - español'},
  {code: 'es-AR', langue: 'Spanish (Argentina) - español (Argentina)'},
  {code: 'es-419', langue: 'Spanish (Latin America) - español (Latinoamérica)'},
  {code: 'es-MX', langue: 'Spanish (Mexico) - español (México)'},
  {code: 'es-ES', langue: 'Spanish (Spain) - español (España)'},
  {code: 'es-US', langue: 'Spanish (United States) - español (Estados Unidos)'},
  {code: 'su', langue: 'Sundanese'},
  {code: 'sw', langue: 'Swahili - Kiswahili'},
  {code: 'sv', langue: 'Swedish - svenska'},
  {code: 'tg', langue: 'Tajik - тоҷикӣ'},
  {code: 'ta', langue: 'Tamil - தமிழ்'},
  {code: 'tt', langue: 'Tatar'},
  {code: 'te', langue: 'Telugu - తెలుగు'},
  {code: 'th', langue: 'Thai - ไทย'},
  {code: 'ti', langue: 'Tigrinya - ትግርኛ'},
  {code: 'to', langue: 'Tongan - lea fakatonga'},
  {code: 'tr', langue: 'Turkish - Türkçe'},
  {code: 'tk', langue: 'Turkmen'},
  {code: 'tw', langue: 'Twi'},
  {code: 'uk', langue: 'Ukrainian - українська'},
  {code: 'ur', langue: 'Urdu - اردو'},
  {code: 'ug', langue: 'Uyghur'},
  {code: 'uz', langue: 'Uzbek - o‘zbek'},
  {code: 'vi', langue: 'Vietnamese - Tiếng Việt'},
  {code: 'wa', langue: 'Walloon - wa'},
  {code: 'cy', langue: 'Welsh - Cymraeg'},
  {code: 'fy', langue: 'Western Frisian'},
  {code: 'xh', langue: 'Xhosa'},
  {code: 'yi', langue: 'Yiddish'},
  {code: 'yo', langue: 'Yoruba - Èdè Yorùbá'},
  {code: 'zu', langue: 'Zulu - isiZulu'}
]


// /////////////////////////////////////////////
// State
// /////////////////////////////////////////////

const state = {
  AppActiveUser           : getUserInfo(),
  bodyOverlay             : false,
  isVerticalNavMenuActive : true,
  is_touch_device         : is_touch_device(),
  mainLayoutType          : themeConfig.mainLayoutType || 'vertical',
  navbarSearchAndPinList,
  clientSearchAutosuggestion,
  reduceButton            : themeConfig.sidebarCollapsed,
  verticalNavMenuWidth    : 'default',
  verticalNavMenuItemsMin : false,
  scrollY                 : 0,
  starredPages            : navbarSearchAndPinList['pages'].data.filter((page) => page.is_bookmarked),
  theme                   : themeConfig.theme || 'light',
  themePrimaryColor       : colors.primary,
  Languages               : languages,

  // Can be used to get current window with
  // Note: Above breakpoint state is for internal use of sidebar & navbar component
  windowWidth: null
}

export default state
