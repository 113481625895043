/*=========================================================================================
  M.Djezou
  File Name: moduleOfficineActions.js
  Description: Officine Module Actions
  ----------------------------------------------------------------------------------------

==========================================================================================*/

import axios from '@/axios.js'

export default {

  getOfficines ({commit}) {
    return new Promise((resolve, reject) => {
      axios.get('/api/officines')
        .then((response) => {
          commit('SET_OFFICINES', response.data)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  createOfficine ({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/officine/create', {payload})
        .then((response) => {
          commit('ADD_OFFICINE', response.data)
          resolve(response)
        })
        .catch(error => {

          reject(error)
        })
    })
  },

  updateOfficine ({commit}, payload) {
    const {id} = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/officine/update/${id}`, {payload})
        .then((response) => {
          commit('UPDATE_OFFICINE', response.data)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  dropOfficine ({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/officine/drop/${id}`)
        .then((response) => {
          commit('REMOVE_RECORD', id)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}

