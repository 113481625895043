/*=========================================================================================
  File Name: moduleAssurance.js
  Description: module assurance
  ----------------------------------------------------------------------------------------
  Author: Eudoxie Djetouan
  Author email: eudoxiedjetouan@gmail.com
==========================================================================================*/


import state from './moduleClientState.js'
import mutations from './moduleClientMutations.js'
import actions from './moduleClientActions.js'
import getters from './moduleClientGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

