import axios from '@/axios.js'
import store from '../../../../store/store.js'

// Token Refresh
let isAlreadyFetchingAccessToken = false
let subscribers = []

//Api base url
//const baseUrl = 'http://127.0.0.1:5001'

function onAccessTokenFetched (access_token) {
  subscribers = subscribers.filter(callback => callback(access_token))
}

function addSubscriber (callback) {
  subscribers.push(callback)
}

export default {
  init () {
    axios.interceptors.response.use(function (response) {
      return response
    }, function (error) {
      // const { config, response: { status } } = error
      const { config, response } = error
      const originalRequest = config

      // if (status === 401) {
      if (response && response.status === 401) {
        if (!isAlreadyFetchingAccessToken) {
          isAlreadyFetchingAccessToken = true
          store.dispatch('auth/fetchAccessToken')
            .then((access_token) => {
              isAlreadyFetchingAccessToken = false
              onAccessTokenFetched(access_token)
            })
        }

        const retryOriginalRequest = new Promise((resolve) => {
          addSubscriber(access_token => {
            originalRequest.headers.authorization = `Bearer ${access_token}`
            resolve(axios(originalRequest))
          })
        })
        return retryOriginalRequest
      }
      return Promise.reject(error)
    })
  },
  login (email, pwd) {
    return axios.post('/api/authentification', {
      email,
      password: pwd
    })
  },
  /*   registerUser (nom, prenoms, email, emailPro, adresse, contact1, contact2, contrat, poste, ville, genre, pays, anniv, nationalite, situationMat, dateEnbauche, permis, agence, status, roles, password) {
    return axios.post('/api/auth/signup', {
      nom,
      prenoms,
      email,
      emailPro,
      adresse,
      contact1,
      contact2,
      contrat,
      poste,
      ville,
      genre,
      pays,
      anniv,
      nationalite,
      situationMat,
      dateEnbauche,
      permis,
      agence,
      status,
      roles,
      password
    })
  }, */
  refreshToken () {
    return axios.post('/api/auth/refresh-token')
  },
  checkValidToken () {
    return new Promise((resolve, reject) => {
      axios.get('/api/verify-token')
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })

    })
  },
  resetPassword (email) {
    return axios.get(`/api/auth/reset-password/${email}`)
  }
}
