/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
  SET_PERSONNELS (state, personnels) {
    state.personnels = personnels
  },
  ADD_PERSONNEL (state, personnel) {
    state.personnels.unshift(personnel)
  },
  UPDATE_PERSONNEL (state, personnel) {
    const personnelIndex = state.personnels.findIndex((m) => m._id === personnel._id)
    Object.assign(state.personnels[personnelIndex], personnel)
  },
  REMOVE_RECORD (state, itemId) {
    const personnelIndex = state.personnels.findIndex((u) => u._id === itemId)
    state.personnels.splice(personnelIndex, 1)
  }
}
