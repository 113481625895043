/*=========================================================================================
  File Name: modulePrescripteurAction.js
  Description: mutation of module precripteur
  ----------------------------------------------------------------------------------------
==========================================================================================*/


export default {

  CREATE_COMPTE_SMS_OFFICINE (state, data) {
    state.comptes.unshift(data)
  },

  UPDATE_COMPTE_SMS_OFFICINE (state, data) {
    const index = state.comptes.findIndex(c => c._id === data._id)
    Object.assign(state.comptes[index], data)
  },

  DELETE_COMPTE_SMS_OFFICINE (state, id) {
    const index = state.comptes.findIndex(c => c._id === id)
    state.comptes.splice(index, 1)
  },

  SET_COMPTE_SMS_OFFICINE (state, data) {
    state.comptes = data
  },

  SET_DIFFUSION (state, data) {
    state.diffusions = data
  },

  ADD_DIFFUSION (state, data) {
    state.diffusions.unshift(data)
  },

  UPDATE_DIFFUSION (state, data) {
    const index = state.diffusions.findIndex((m) => m._id === data._id)
    Object.assign(state.diffusions[index], data)
  },

  REMOVE_DIFFUSION (state, id) {
    const index = state.diffusions.findIndex((u) => u._id === id)
    state.diffusions.splice(index, 1)
  },

  SET_REPERTOIRE (state, data) {
    state.repertoires = data
  },

  ADD_REPERTOIRE (state, data) {
    state.repertoires.unshift(data)
  },

  UPDATE_REPERTOIRE (state, data) {
    const index = state.repertoires.findIndex((r) => r._id === data._id)
    Object.assign(state.repertoires[index], data)
  },

  REMOVE_REPERTOIRE (state, id) {
    const index = state.repertoires.findIndex((r) => r._id === id)
    state.repertoires.splice(index, 1)
  },
  FORMAT_NOM_PRENOM_AND_CONTACT (state, id_repertoire) {
    const index = state.repertoires.findIndex((r) => r._id === id_repertoire)
    const repertoire = state.repertoires[index]
    const indicatifPaysCIV = '225'

    // Filtrer les contacts vides
    repertoire.contacts = repertoire.contacts.filter((contact) => {
      return contact.Nom.trim() !== '' && contact.Contacts.trim() !== ''
    })
    repertoire.contacts.map((contact) => {
      const tabNom = contact.Nom.split(' ')
      if (tabNom.length > 1) {
        contact.Nom = tabNom[0]
        tabNom.splice(0, 1)
        contact.Prenoms = tabNom.flat().join(' ')
      }
      if (!contact.Contacts.startsWith(indicatifPaysCIV)) {
        contact.Contacts = (`${indicatifPaysCIV}${contact.Contacts}`).replace(/\s+/g, '')
      }
    })
  },
  SET_CONTACT (state, data) {
    state.contacts = data
  },
  DELETE_CONTACT (state, id) {
    const index = state.contacts.findIndex((c) => c._id === id)
    state.contacts.splice(index, 1)
  }
}
