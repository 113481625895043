/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
  SET_APPRO_CAISSE_RECETTES (state, appro_caisse_recettes) {
    state.appro_caisse_recettes = appro_caisse_recettes
  },
  ADD_APPRO_CAISSE_RECETTE (state, appro_caisse_recette) {
    state.appro_caisse_recettes.unshift(appro_caisse_recette)
  },
  UPDATE_APPRO_CAISSE_RECETTE (state, appro_caisse_recette) {
    const appro_caisse_recetteIndex = state.appro_caisse_recettes.findIndex((m) => m._id === appro_caisse_recette._id)
    Object.assign(state.appro_caisse_recettes[appro_caisse_recetteIndex], appro_caisse_recette)
  },
  REMOVE_RECORD (state, itemId) {
    const appro_caisse_recetteIndex = state.appro_caisse_recettes.findIndex((u) => u._id === itemId)
    state.appro_caisse_recettes.splice(appro_caisse_recetteIndex, 1)
  }
}
