/*=========================================================================================
  Nom du fichier: moduleSuspectMutations.js
  Description: Suspect Module Mutations
  ----------------------------------------------------------------------------------------
  Author: Djezou Eric Martial
==========================================================================================*/

export default {
  SET_COMPTES (state, data) {
    state.comptes = data
  },
  ADD_COMPTE (state, data) {
    state.comptes.unshift(data)
  },
  UPDATE_COMPTE (state, data) {
    const index = state.comptes.length > 0 ? state.comptes.findIndex((s) => s._id === data._id) : null

    if (index === 0 || index !== null) {
      Object.assign(state.comptes[index], data)
    }
  },
  DELETE_COMPTE (state, id) {
    const index = state.comptes.length > 0 ? state.comptes.findIndex((s) => s._id === id) : null
    state.comptes.splice(index, 1)
  }
}