/*=========================================================================================
  M.Djezou
  File Name: moduleBilletageDepenseMutations.js
  Description: BilletageDepense Module Mutations
  ----------------------------------------------------------------------------------------
==========================================================================================*/

export default {
  SET_BILLETAGE_DEPENSE (state, billetage) {
    state.billetage_depense = billetage
  }
}
