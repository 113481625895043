import axios from '@/axios.js'

export default{
  addProfession ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/profession/create', { payload })
        .then((response) => {
          commit('ADD_PROFESSION', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  updateProfession ({ commit }, payload) {
    const { id } = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/profession/update/${id}`, { payload })
        .then((response) => {
          commit('UPDATE_FOURNISSEUR')
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getProfessions ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/professions')
        .then((response) => {
          commit('SET_PROFESSION', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  getProfessionById (context, id) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/profession/${id}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  deleteProfession ({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`api/profession/${id}`)
        .then((response) => {
          commit('REMOVE_PROFESSION')
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }

}
