
import axios from '@/axios.js'

export default {

// get all Assurance
  getAssurances ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/assurances')
        .then((response) => {
          commit('SET_ASSURANCES', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  getAssuranceById (context, assuranceId) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/assurance/${assuranceId}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  addAssurance ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/assurance/create', { payload })
        .then((response) => {
          console.log(response.data.data)
          commit('ADD_ASSURANCE', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  updateAssurance ({commit}, payload) {
    const {id} = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/assurance/update/${id}`, { payload })
        .then((response) => {
          commit('UPDATE_ASSURANCE', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },


  removeAssurance ({ commit }, assuranceId) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/assurance/${assuranceId}`)
        .then((response) => {
          commit('REMOVE_ASSURANCE', assuranceId)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }


}