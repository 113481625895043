/*=========================================================================================
  File Name: moduleApproDepenseAction.js
  Description: action of module approvisionnement caisse depense
  ----------------------------------------------------------------------------------------
  Author: Eudoxie Djetouan
  Author email: eudoxiedjetouan@gmail.com
==========================================================================================*/

import axios from '@/axios.js'

export default {

// get all Assurance
  fetchApproDepenses ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/approDepense')
        .then((response) => {
          commit('SET_APPRODEPENSE', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }


}