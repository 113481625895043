import axios from '@/axios.js'

export default {
  nbreClientbyOfficines ({commit}) {
    return new Promise((resolve, reject) => {
      axios.get('/api/v1/statistique/nbre-client-officine').then((response) => {
        commit('SET_NBRE_CLIENT', response.data)
        resolve(response)
      }).catch((error) => { reject(error) })
    })
  },
  nbreClientGenreByOfficines ({commit}) {
    return new Promise((resolve, reject) => {
      axios.get('/api/v1/statistique/nbre-genre-client-officine').then((response) => {
        commit('SET_NBRE_GENRE_CLIENT', response.data)
        resolve(response)
      }).catch((error) => { reject(error) })
    })
  }
}