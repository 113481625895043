/*=========================================================================================
  File Name: modulePrecripteur.js
  Description: module prescripteur
  ----------------------------------------------------------------------------------------
==========================================================================================*/


import state from './modulePrescripteurState.js'
import mutations from './modulePrescripteurMutations.js'
import actions from './modulePrescripteurActions.js'
import getters from './modulePrescripteurGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

