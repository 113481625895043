/*=========================================================================================
  File Name: modulePartenaireMutations.js
  Description: Partenaire Module Mutations
  ----------------------------------------------------------------------------------------
==========================================================================================*/


export default {
  SET_PARTENAIRES (state, partenaires) {
    state.partenaires = partenaires
  },
  ADD_PARTENAIRE (state, partenaire) {
    state.partenaires.unshift(partenaire)
  },
  UPDATE_PARTENAIRE (state, partenaire) {
    const partenaireIndex = state.partenaires.findIndex((m) => m._id === partenaire._id)
    Object.assign(state.partenaires[partenaireIndex], partenaire)
  },
  REMOVE_RECORD (state, itemId) {
    const partenaireIndex = state.partenaires.findIndex((u) => u._id === itemId)
    state.partenaires.splice(partenaireIndex, 1)
  }
}
