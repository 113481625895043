

export default {
  getCategorieArticleById: state => idCategorieArticle => {
    return state.categorie_articles.find((c) => c._id === idCategorieArticle)
  },
  getCategorieName: state => id => {
    const cat = state.categorie_articles.find((c) => c._id === id)
    return cat ? cat.Abreg_Categ : ''
  }
}
