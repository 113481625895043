/*=========================================================================================
  M.Djezou
  File Name: moduleOfficineMutations.js
  Description: Officine Module Mutations
  ----------------------------------------------------------------------------------------
==========================================================================================*/

export default {
  SET_OFFICINES (state, officines) {
    state.officines = officines
  },
  ADD_OFFICINE (state, officine) {
    state.officines.unshift(officine)
  },
  UPDATE_OFFICINE (state, officine) {
    const officineIndex = state.officines.findIndex((o) => o._id === officine._id)
    Object.assign(state.officines[officineIndex], officine)
  },
  REMOVE_RECORD (state, itemId) {
    const officineIndex = state.officines.findIndex((o) => o._id === itemId)
    state.officines.splice(officineIndex, 1)
  }
}
