/*=========================================================================================
 M.Djezou
  File Name: moduleOfficineMutations.js
  Description: Officine Module Mutations
  ----------------------------------------------------------------------------------------
==========================================================================================*/

export default {
  SET_MARQUES (state, marques) {
    state.marques = marques
  },
  ADD_MARQUE (state, marque) {
    state.marques.unshift(marque)
  },
  UPDATE_MARQUE (state, marque) {
    const marqueIndex = state.marques.findIndex((o) => o._id === marque._id)
    Object.assign(state.marques[marqueIndex], marque)
  },
  REMOVE_RECORD (state, itemId) {
    const marqueIndex = state.marques.findIndex((o) => o._id === itemId)
    state.marques.splice(marqueIndex, 1)
  }
}

