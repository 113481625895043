/*=========================================================================================
  File Name: moduleDepenseState.js
  Description: Depense Module State
  ----------------------------------------------------------------------------------------
==========================================================================================*/

export default {
  depenses: [],
  approCaisse: [],
  decaissements_toDay_by_agence: [],
  decaissements_prev_day_by_agence: []
}
