/*=========================================================================================
  M.Djezou
  File Name: moduleFournisseurActions.js
  Description: Fournisseur Module Actions
  ----------------------------------------------------------------------------------------
==========================================================================================*/

import axios from '@/axios.js'

export default {
  getFournisseurs ({commit}) {
    return new Promise((resolve, reject) => {
      axios.get('/api/fournisseurs/groupe')
        .then((response) => {
          commit('SET_FOURNISSEURS', response.data)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getFournisseursByOfficine ({commit}, officine_id) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/fournisseur/officine/${officine_id}`)
        .then((response) => {
          commit('SET_FOURNISSEURS', response.data)
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  createFournisseur ({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/fournisseur/create', {payload})
        .then((response) => {
          commit('ADD_FOURNISSEUR', response.data)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  updateFournisseur ({commit}, payload) {
    const {id} = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/fournisseur/update/${id}`, {payload})
        .then((response) => {
          commit('UPDATE_FOURNISSEUR', response.data)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  dropFournisseur ({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/fournisseur/drop/${id}`)
        .then((response) => {
          commit('REMOVE_RECORD', id)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}

