/*=========================================================================================
  File Name: moduleCommandeVerreActions.js
  Description: actions of module commande verre
  ----------------------------------------------------------------------------------------
==========================================================================================*/
import axios from '@/axios.js'

export default {
  getCommandesVerres ({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/commandes-verres/${id}`)
        .then((response) => {
          commit('SET_VERRE_COMMANDE', response.data)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updateCommandeVerre ({commit}, payload) {
    const {id} = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/commande-verre/update/${id}`, {payload})
        .then((response) => {
          commit('UPDATE_VERRE_COMMANDE', response.data)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  valideLivraisonCommandeVerre ({commit}, payload) {
    const {id} = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/commande-verre/valide/livraison/${id}`, {payload})
        .then((response) => {
          commit('UPDATE_VERRE_COMMANDE', response.data)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  RetourCommandeVerre ({commit}, payload) {
    const {id} = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/commande-verre/retour/${id}`, {payload})
        .then((response) => {
          commit('UPDATE_VERRE_COMMANDE', response.data)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  valideMontage ({commit}, payload) {
    const {id} = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/valide-montage/${id}`, {payload})
        .then((response) => {
          commit('UPDATE_VERRE_COMMANDE', response.data)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  verificationMontage ({commit}, payload) {
    const {id} = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/verification-montage/${id}`, {payload})
        .then((response) => {
          commit('UPDATE_VERRE_COMMANDE', response.data)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  deleteCommandeVerre ({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/commande-verre/delete/${id}`)
        .then((response) => {
          commit('DELETE_VERRE_COMMANDE', id)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}
