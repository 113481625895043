import axios from '@/axios.js'

export default {
  searchCompte (context, searchValue) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/search-comptes-sale/${searchValue}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getComptesByUser ({ commit }, id_user) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/comptes-sale/user/${id_user}`)
        .then((response) => {
          commit('SET_COMPTES', response.data.comptesSale)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getCompteById (context, id) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/compte-sale/${id}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getCompteBySlug (context, slug) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/compte-sale/slug/${slug}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  createCompte ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/compte-sale/create', { payload })
        .then((response) => {
          commit('ADD_COMPTE', response.data.compteSale)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateCompte ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.put('/api/compte-sale/update', { payload })
        .then((response) => {
          commit('UPDATE_COMPTE', response.data.compteSale)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  softdeleteCompte ({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.put(`/api/compte-sale/softdelete/${id}`)
        .then((response) => {
          commit('DELETE_COMPTE', id)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}