/*=========================================================================================
  File Name: moduleEntetePiedpageActions.js
  Description: EntetePiedpage Module Actions
  ----------------------------------------------------------------------------------------
==========================================================================================*/

import axios from '@/axios.js'

export default {
  getLogo ({commit}) {
    return new Promise((resolve, reject) => {
      axios.get('/api/logo-active')
        .then((response) => {
          commit('SET_LOGO', response.data)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  removeLogo ({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/logo-active/${id}`)
        .then((response) => {
          commit('REMOVE_LOGO')
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getPiedPage ({commit}) {
    return new Promise((resolve, reject) => {
      axios.get('/api/piedpage-active')
        .then((response) => {
          commit('SET_PIEDPAGE', response.data)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  removePiedPage ({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/piedpage-active/${id}`)
        .then((response) => {
          commit('REMOVE_PIEDPAGE')
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getAllModelEntetePiedPage ({commit}) {
    return new Promise((resolve, reject) => {
      axios.get('/api/model-entete-piedpage')
        .then((response) => {
          commit('SET_MODELE', response.data)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  createModelEntete ({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/create-model-entete', {payload})
        .then((response) => {
          commit('ADD_MODELE', response.data)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updateCreateModelEntete ({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.put('/api/update-model-entete', {payload})
        .then((response) => {
          commit('UPDATE_MODELE', response.data)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  removeModelEntete ({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/model-entete-piedpage/${id}`)
        .then((response) => {
          commit('REMOVE_MODELE', id)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

}
