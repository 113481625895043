/*=========================================================================================
  File Name: moduleStockState.js
  Description: Stock Module State
  ----------------------------------------------------------------------------------------
==========================================================================================*/

export default {
  verresProg: [],
  verresUnifoco: [],
  nomVerres: []
}
