/*=========================================================================================
  File Name: moduleCommandeFournActions.js
  Description: CommandeFourn Module Actions
  ----------------------------------------------------------------------------------------
==========================================================================================*/

import axios from '@/axios.js'

export default {
  createCommandeFournisseur ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/commande/fournisseur/create', {payload})
        .then((response) => {
          commit('ADD_COMMANDE_FOURN', response.data)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getCommandesFournisseur ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/commandes/fournisseurs/groupe')
        .then((response) => {
          console.log(response)
          commit('SET_COMMANDES_FOURN', response)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getBonReceptionByOfficine ({ commit}, payload) {
    const {officineId, Page} = payload
    return new Promise((resolve, reject) => {
      axios.get(`/api/commandes/fournisseurs/officine/${officineId}/${Page}`)
        .then((response) => {
          commit('SET_COMMANDES_FOURN', response.data)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getTransfererBonReceptionByOfficine ({ commit}, payload) {
    const {officineId} = payload
    return new Promise((resolve, reject) => {
      axios.get(`/api/commandes/fournisseurs/transferer/officine/${officineId}`)
        .then((response) => {
          commit('SET_COMMANDES_FOURN_TRANS', response.data)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getCommandeFournisseurById (context, id) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/commande/fournisseur/${id}`)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updateCommandeFournisseur (context, payload) {
    const { id } = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/commande/fournisseur/${id}`, {payload})
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  deleteCommandeFournisseur ({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/commande/fournisseur/drop/${id}`)
        .then((response) => {
          commit('DELETE_COMMANDE_FOURN', id)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  transfertCommandeFournisseurInToStock ({commit}, payload) {
    const { id } = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/commande/fournisseur/transfert/${id}`, {payload})
        .then((response) => {
          commit('UPDATE_COMMANDE_FOURN', id)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  validationTransfertCommandeFournisseurInStockAgence ({commit}, payload) {
    const { id } = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/commande/fournisseur/validation/${id}`, {payload})
        .then((response) => {
          commit('UPDATE_COMMANDE_FOURN', id)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  AnnulationBr ({commit}, payload) {
    const { id } = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/commande/fournisseur/annulation/${id}`, {payload})
        .then((response) => {
          commit('UPDATE_COMMANDE_FOURN', id)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  reglementCommandeFournisseur ({commit}, payload) {
    const {id} = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/commande/fournisseur/reglement-commande/${id}`)
        .then((response) => {
          commit('UPDATE_COMMANDE_FOURN', response.data)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  addNewLigne (context, payload) {
    const {id} = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/commande/fournisseur/add_new_ligne/${id}`, {payload})
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  updateLigne (context, payload) {
    const {id} = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/commande/fournisseur/update_ligne/${id}`, {payload})
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  deleteLigne (context, payload) {
    const {id} = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/commande/fournisseur/delete_ligne/${id}`, {payload})
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

}
