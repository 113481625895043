/*=========================================================================================
  File Name: moduleStockActions.js
  Description: Stock Module Actions
  ----------------------------------------------------------------------------------------
==========================================================================================*/

import axios from '@/axios.js'

export default {
  updateStock ({commit}, payload) {

    const { id } = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/stock/article/update/${id}`, { payload })
        .then((response) => {
          commit('UPDATE_SOCIETE', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getStocks ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/stock/articles')
        .then((response) => {
          commit('SET_STOCKS', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  getAllArticleSortie ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/stock/articles/sortie')
        .then((response) => {
          commit('SET_STOCKS', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  getStockByOfficine ({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/stock/officine/${id}`)
        .then((response) => {
          commit('SET_STOCKS', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  stockArticleLengthVerify ({commit}, payload) {
    const {qteArticle, officine} = payload
    return new Promise((resolve, reject) => {
      axios.get(`/api/stock/officine/verify-length/${officine}/${qteArticle}`)
        .then((response) => {
          if (response.data.message === 'sync') {
            console.log('stock article sync')
            commit('SET_STOCKS', response.data.data)
          } else console.log('stock article same size')
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  transfererArticle ({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/stock/transfererArticle', { payload })
        .then((response) => {
          commit('ADD_ARTICLE_TRANSFERER', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getTransfertArticleByOfficine ({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/stock/article-transferer/${id}`)
        .then((response) => {
          commit('SET_ARTICLE_TRANSFERER', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getTransfertArticleById ({context}, id) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/stock/transfert-article/${id}`)
        .then((response) => {
          console.log(context)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getArticleById (context, id) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/stock/article/${id}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
