/*=========================================================================================
  File Name: moduleDepenseMutations.js
  Description: Depense Module Mutations
  ----------------------------------------------------------------------------------------
==========================================================================================*/

export default {
  SET_DEPENSE (state, depenses) {
    state.depenses = depenses
  },
  SET_APPRO_CAISSE_DEPENSE (state, appros) {
    state.approCaisse = appros
  },
  ADD_DEPENSE (state, depense) {
    state.depenses.unshift(depense)
  },
  UPDATE_DEPENSE (state, data) {
    const depenseIndex = state.depenses.findIndex((d) => d._id === data._id)
    Object.assign(state.depenses[depenseIndex], data)
  },
  DELETE_DEPENSE (state, id) {
    const depenseIndex = state.depenses.findIndex((d) => d._id === id)
    state.depenses.splice(depenseIndex, 1)
  },
  ADD_APPRO_CAISSE_DEPNSE (state, appro) {
    state.approCaisse.unshift(appro)
  },
  ADD_DEPENSE_TODAY_BY_AGENCE (state, data) {
    const {OfficineId, Depenses} = data
    state.decaissements_toDay_by_agence.push({officine: OfficineId, decaissements: Depenses})
  },
  ADD_DEPENSE_PREVDAY_BY_AGENCE (state, data) {
    const {OfficineId, Depenses} = data
    state.decaissements_prev_day_by_agence.push({officine: OfficineId, decaissements: Depenses})
  }
}
