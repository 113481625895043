/*=========================================================================================
  File Name: moduleConsultationActions.js
  Description: Consultation Module Actions
  ----------------------------------------------------------------------------------------
==========================================================================================*/

import axios from '@/axios.js'

export default {
  makeConsultation ({commit}, payload) {
    console.log(payload)
    return new Promise((resolve, reject) => {
      axios.post('/api/consultation/new', {payload})
        .then((response) => {
          commit('ADD_CONSULTATION', response.data.consultation)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  getConsultationByOfficine ({commit}, payload) {
    const {Officine} = payload
    return new Promise((resolve, reject) => {
      axios.get(`/api/consultations/${Officine}`)
        .then((response) => {
          commit('SET_CONSULTATION', response.data)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  getConsultationOnPeriodeByOfficine ({commit}, payload) {
    const {Officine, debut, fin} = payload
    return new Promise((resolve, reject) => {
      axios.get(`/api/consultations/${Officine}/${debut}/${fin}`)
        .then((response) => {
          commit('SET_CONSULTATION', response.data)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  getConsultationOnPeriode ({commit}, payload) {
    const {debut, fin} = payload
    return new Promise((resolve, reject) => {
      axios.get(`/api/consultations/${debut}/${fin}`)
        .then((response) => {
          commit('SET_CONSULTATION', response.data)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  archiveConsultation ({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/consultation/archive/${id}`)
        .then((response) => {
          commit('ARCHIVE_CONSULTATION', response.data)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

}
