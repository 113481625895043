/*=========================================================================================
  File Name: moduleConfigsActions.js
  Description: Configs Module Actions
  ----------------------------------------------------------------------------------------
==========================================================================================*/

import axios from '@/axios.js'

export default {
  addFerie ({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/ferie/create', {payload})
        .then((response) => {
          commit('ADD_FERIE', response.data)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  getFeriesByYear ({commit}, annee) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/feries/${annee}`)
        .then((response) => {
          commit('SET_FERIE', response.data)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updateFerie ({commit}, payload) {
    const {id} = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/ferie/update/${id}`, {payload})
        .then((response) => {
          commit('UPDATE_FERIE', response.data)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  deleteFerie ({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/ferie/delete/${id}`)
        .then((response) => {
          commit('DELETE_FERIE', id)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  createTypeSms ({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/create-type-sms', {payload})
        .then((response) => {
          commit('ADD_TYPE_SMS', response.data.typeSms)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updateTypeSms ({commit}, payload) {
    const { id } = payload
    return new Promise((resolve, reject) => {
      axios.post(`/api/update-type-sms/${id}`, {payload})
        .then((response) => {
          commit('UPDATE_TYPE_SMS', response.data.typeSms)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getSmsConfig ({commit}) {
    return new Promise((resolve, reject) => {
      axios.get('/api/sms-config-info')
        .then((response) => {
          commit('SET_SMS_CONFIG', response.data.smsConfig)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getSmsConfigSenderNameSenderAddressMessageTemplate ({commit}) {
    return new Promise((resolve, reject) => {
      axios.get('/api/sms-config-sendername-senderaddress-messagetemplate')
        .then((response) => {
          commit('SET_SMS_CONFIG', response.data)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  setSmsConfig ({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/set-sms-config', {payload})
        .then((response) => {
          commit('SET_SMS_CONFIG', response.data.smsConfig)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  setGarantie ({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('api/set-garantie', {payload})
        .then((response) => {
          commit('SET_GARANTIE', response.data.garantie)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getGarantie ({commit}) {
    return new Promise((resolve, reject) => {
      axios.get('/api/garantie-info')
        .then((response) => {
          commit('SET_GARANTIE', response.data.garantie)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getCaisseAlertInfo ({commit}, payload) {
    const {id} = payload
    return new Promise((resolve, reject) => {
      axios.get(`/api/alerte-caisse-info/${id}`, {payload})
        .then((response) => {
          commit('SET_ALERTE_CAISSE', response.data)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  addCaisseAlert ({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/alerte-caisse', {payload})
        .then((response) => {
          commit('SET_ALERTE_CAISSE', response.data)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updateCaisseAlert ({commit}, payload) {
    const {id} = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/alerte-caisse/update/${id}`, {payload})
        .then((response) => {
          commit('SET_ALERTE_CAISSE', response.data)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

}
