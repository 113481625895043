/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
  SET_BANQUES (state, banques) {
    state.banques = banques
  },
  ADD_BANQUE (state, banque) {
    state.banques.unshift(banque)
  },
  UPDATE_BANQUE (state, banque) {
    const banqueIndex = state.banques.findIndex((m) => m._id === banque._id)
    Object.assign(state.banques[banqueIndex], banque)
  },
  REMOVE_RECORD (state, itemId) {
    const banqueIndex = state.banques.findIndex((u) => u._id === itemId)
    state.banques.splice(banqueIndex, 1)
  }
}
