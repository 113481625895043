/*=========================================================================================
  M.Djezou
  File Name: moduleOperation.js
  Description: Operation Module
  ----------------------------------------------------------------------------------------

==========================================================================================*/


import state from './moduleOfficineState.js'
import mutations from './moduleOfficineMutations.js'
import actions from './moduleOfficineActions.js'
import getters from './moduleOfficineGetters.js'

export default {

  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

