/*=========================================================================================
// <<<<<<< M.Djezou
  File Name: moduleOfficine.js
  Description: Officine Module
  ----------------------------------------------------------------------------------------
// =======
  File Name: moduleFournisseur.js
  Description: module Fournisseur
  ----------------------------------------------------------------------------------------
  Author: Eudoxie Djetouan
  Author email: eudoxiedjetouan@gmail.com
// >>>>>>> main
==========================================================================================*/


import state from './moduleMarqueState.js'
import mutations from './moduleMarqueMutations.js'
import actions from './moduleMarqueActions.js'
import getters from './moduleMarqueGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

