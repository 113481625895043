function date_sort (a, b) {
  return new Date(b.commande.DateCommd).getTime() - new Date(a.commande.DateCommd).getTime()
}

export default {

  sortVentesByDate: state => {
    const ventes = state.ventes

    return ventes.length > 0 ? ventes.sort(date_sort) : []
  }

}

