/*=========================================================================================
  M.Djezou
  File Name: moduleBilletageDepenseActions.js
  Description: BilletageDepense Module Actions
  ----------------------------------------------------------------------------------------

==========================================================================================*/

import axios from '@/axios.js'

export default {


  makeClotureCaisseDepense ({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/billetage-depense', {payload})
        .then((response) => {
          commit('SET_BILLETAGE_DEPENSE', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getBilletageDepenseOnPeriode ({commit}, payload) {
    const {idOfficine, DateCloture} = payload
    return new Promise((resolve, reject) => {
      axios.get(`/api/billetage-depense/${idOfficine}/${DateCloture}`)
        .then((response) => {
          commit('SET_BILLETAGE_DEPENSE', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}

