
import axios from '@/axios.js'

export default {

// get all Prescripteur
  getPrescripteurs ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/prescripteurs')
        .then((response) => {
          commit('SET_PRESCRIPTEURS', response.data)
          /* localStorage.setItem('prescripteurs', JSON.stringify(response.data)) */
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  getPrescripteurById (context, prescripteurId) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/prescripteur/${prescripteurId}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  addPrescripteur ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/prescripteur/create', { payload })
        .then((response) => {
          commit('ADD_PRESCRIPTEUR', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  updatePrescripteur ({commit}, payload) {
    const {id} = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/prescripteur/update/${id}`, { payload })
        .then((response) => {
          commit('UPDATE_PRESCRIPTEUR', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },


  removePrescripteur ({ commit }, prescripteurId) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/prescripteur/${prescripteurId}`)
        .then((response) => {
          commit('REMOVE_PRESCRIPTEUR', prescripteurId)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }


}
