/*=========================================================================================
  M.Djezou
  File Name: moduleBilletageActions.js
  Description: Billetage Module Actions
  ----------------------------------------------------------------------------------------

==========================================================================================*/

import axios from '@/axios.js'

export default {


  makeClotureCaisseRecette ({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/billetage', {payload})
        .then((response) => {
          commit('SET_BILLETAGE', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getBilletageOnPeriode ({commit}, payload) {
    const {idOfficine, DateCloture} = payload
    return new Promise((resolve, reject) => {
      axios.get(`/api/billetage/${idOfficine}/${DateCloture}`)
        .then((response) => {
          commit('SET_BILLETAGE', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}

