

export default {
  getMarqueById:  state => id  => {
    return state.marques.find((f) => f._id === id)
  },
  getMarqueName: state => id => {
    const marque = state.marques.find((f) => f._id === id)
    return marque ? marque.Libelle_marq : ''
  }
}
