/*=========================================================================================
  M.Djezou
  File Name: moduleRubriqueDepenseMutations.js
  Description: Rubrique Depense Module Mutations
  ----------------------------------------------------------------------------------------
==========================================================================================*/

export default {
  SET_RUBRIQUE (state, data) {
    state.rubriques = data
  },
  ADD_RUBRIQUE (state, data) {
    state.rubriques.unshift(data)
  },
  UPDATE_RUBRIQUE (state, data) {
    const index = state.rubriques.findIndex((r) => r._id === data._id)
    Object.assign(state.rubriques[index], data)
  },
  DELETE_RUBRIQUE (state, data) {
    const index = state.rubriques.findIndex((r) => r._id === data._id)
    state.rubriques.splice(index, 1)
  }
}