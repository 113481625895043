/*=========================================================================================
  File Name: moduleUserManagementActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
==========================================================================================*/

import axios from '@/axios.js'

export default {
  searchUser (context, searchValue) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/search-users/${searchValue}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  addUser ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/user/create', {payload})
        .then((response) => {
          commit('ADD_USER', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateUser ({ commit }, payload) {
    const {id} = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/user/${id}`, {payload})
        .then((response) => {
          commit('UPDATE_USER', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchUsers ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/users', {})
        .then((response) => {
          console.log(response.data)
          commit('SET_USERS', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchUser (context, userId) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/user/${userId}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  removeUser ({ commit }, userId) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/user/${userId}`)
        .then((response) => {
          commit('REMOVE_RECORD', userId)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  resetUserPassword (context, payload) {
    const { id } = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/user/reset_password/${id}`, {payload})
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.log(error.message)
          reject(error) 
        })
    })
  }
}
