/*=========================================================================================
  File Name: moduleEntetePiedpageState.js
  Description: EntetePiedpage Module State
  ----------------------------------------------------------------------------------------
==========================================================================================*/

export default {
  logo: {},
  piedpage: {},
  modeles: []
}
