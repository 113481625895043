/*=========================================================================================
  File Name: moduleversementBanqMutation.js
  Description: mutation of module versement banque
  ----------------------------------------------------------------------------------------
  Author: Eudoxie Djetouan
  Author email: eudoxiedjetouan@gmail.com
==========================================================================================*/


export default {

  // store content all assurances
  SET_VERSEMENT_BANQUE (state, versements) {
    state.versements = versements
  },
  ADD_VERSEMENT (state, versement) {
    state.versements.unshift(versement)
  },
  UPDATE_VERSEMENT (state, vers) {
    const indexVer = state.versements.findIndex((v) => v._id === vers._id)
    Object.assign(state.versements[indexVer], vers)
  },
  REMOVE_VERSEMENT (state, id) {
    const indexVer = state.versements.findIndex((v) => v._id === id)
    state.versements.splice(indexVer, 1)
  }
}
