/*=========================================================================================
  File Name: moduleEntetePiedpageMutations.js
  Description: EntetePiedpage Module Mutations
  ----------------------------------------------------------------------------------------
==========================================================================================*/

export default {
  SET_LOGO (state, logo) {
    state.logo = logo
  },
  SET_PIEDPAGE (state, piedpage) {
    state.piedpage = piedpage
  },
  REMOVE_LOGO (state) {
    state.logo = {}
  },
  REMOVE_PIEDPAGE (state) {
    state.piedpage = {}
  },
  SET_MODELE (state, data) {
    state.modeles = data
  },
  ADD_MODELE (state, data) {
    state.modeles.unshift(data)
  },
  UPDATE_MODELE (state, data) {
    const index = state.modeles.findIndex(d => d._id === data._id)
    Object.assign(state.modeles[index], data)
  },
  REMOVE_MODELE (state, id) {
    const index = state.modeles.findIndex(d => d._id === id)
    state.modeles.splice(index, 1)
  }
}
