/*=========================================================================================
  File Name: moduleAssuranceAction.js
  Description: mutation of module assurance
  ----------------------------------------------------------------------------------------
  Author: Eudoxie Djetouan
  Author email: eudoxiedjetouan@gmail.com
==========================================================================================*/


export default {

// store content all assurances
  SET_ASSURANCES (state, assurances) {
    state.assurances = assurances
  },

  ADD_ASSURANCE (state, assurances) {
    state.assurances.unshift(assurances)
  },

  UPDATE_ASSURANCE (state, assurance) {
    const assuranceIndex = state.assurances.findIndex((m) => m._id === assurance._id)
    Object.assign(state.assurances[assuranceIndex], assurance)
  },

  REMOVE_ASSURANCE (state, itemId) {
    const assuranceIndex = state.assurances.findIndex((u) => u._id === itemId)
    state.assurances.splice(assuranceIndex, 1)
  }
}