/*=========================================================================================
  M.Djezou
  File Name: moduleOperationMutations.js
  Description: Operation Module Mutations
  ----------------------------------------------------------------------------------------
==========================================================================================*/

export default {
  SET_OPERATION_TO_DAY (state, operations) {
    state.operationsToday = operations
  },
  SET_OPERATIONS (state, operations) {
    state.operations = operations
  },
  REMOVE_OPERATION (state, id) {
    const index = state.operations.findIndex((o) => o._id === id)
    state.operations.splice(index, 1)
  },
  ADD_ENCAISSEMENT_TODAY_BY_AGENCE (state, data) {
    const {OfficineId, Operations} = data
    state.encaissements_toDay_by_agence.push({officine: OfficineId, encaissements: Operations})
  },
  ADD_ENCAISSEMENT_PREVDAY_BY_AGENCE (state, data) {
    const {OfficineId, Operations} = data
    state.encaissements_prev_day_by_agence.push({officine: OfficineId, encaissements: Operations})
  }
}
