/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
  SET_FONDDECAISSES (state, fonddecaisses) {
    state.fonddecaisses = fonddecaisses
  },
  ADD_FONDDECAISSE (state, fonddecaisse) {
    state.fonddecaisses.unshift(fonddecaisse)
  },
  UPDATE_FONDDECAISSE (state, fonddecaisse) {
    const fonddecaisseIndex = state.fonddecaisses.findIndex((m) => m._id === fonddecaisse._id)
    Object.assign(state.fonddecaisses[fonddecaisseIndex], fonddecaisse)
  },
  REMOVE_RECORD (state, itemId) {
    const fonddecaisseIndex = state.fonddecaisses.findIndex((u) => u._id === itemId)
    state.fonddecaisses.splice(fonddecaisseIndex, 1)
  }
}
