/*=========================================================================================
  File Name: moduleFonctionMutations.js
  Description: Fonction Module Mutations
  ----------------------------------------------------------------------------------------
==========================================================================================*/


export default {
  SET_FONCTIONS (state, fonctions) {
    state.fonctions = fonctions
  },
  ADD_FONCTION (state, fonction) {
    state.fonctions.unshift(fonction)
  },
  UPDATE_FONCTION (state, fonction) {
    const fonctionIndex = state.fonctions.findIndex((m) => m._id === fonction._id)
    console.log(fonctionIndex)
    Object.assign(state.fonctions[fonctionIndex + 1], fonction)
  },
  REMOVE_RECORD (state, itemId) {
    const fonctionIndex = state.fonctions.findIndex((u) => u._id === itemId)
    state.fonctions.splice(fonctionIndex, 1)
  }
}
