/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/personnel/pixinvent
==========================================================================================*/

import axios from '@/axios.js'

export default {
  // addItem({ commit }, item) {
  //   return new Promise((resolve, reject) => {
  //     axios.post("/api/data-list/products/", {item: item})
  //       .then((response) => {
  //         commit('ADD_ITEM', Object.assign(item, {id: response.data.id}))
  //         resolve(response)
  //       })
  //       .catch((error) => { reject(error) })
  //   })
  // },
  addAppro_Caisse_Recette ({ commit }, payload) {
    const {NomSociete, RegistreCom} = payload
    return new Promise((resolve, reject) => {
      axios.post('/api/appro_caisse_recettes', {NomSociete, RegistreCom})
        .then((response) => {
          console.log(response.data.data)
          commit('ADD_APPRO_CAISSE_RECETTE', response.data.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateAppro_Caisse_Recette ({commit}, payload) {
    const {NomSociete, RegistreCom, id} = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/appro_caisse_recettes/${id}`, {NomSociete, RegistreCom})
        .then((response) => {
          console.log(response.data.data)
          commit('UPDATE_APPRO_CAISSE_RECETTE', response.data.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  fetchAppro_Caisse_Recettes ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/appro_caisse_recettes')
        .then((response) => {
          commit('SET_APPRO_CAISSE_RECETTES', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchAppro_Caisse_Recette (context, appro_caisse_recetteId) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/appro_caisse_recettes/${appro_caisse_recetteId}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  removeAppro_Caisse_Recette ({ commit }, appro_caisse_recetteId) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/appro_caisse_recettes/${appro_caisse_recetteId}`)
        .then((response) => {
          commit('REMOVE_RECORD', appro_caisse_recetteId)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}