/*=========================================================================================
  File Name: moduleClientMutation.js
  Description: mutation of module client
  ----------------------------------------------------------------------------------------
==========================================================================================*/


export default {

// store content all clients
  SET_CLIENTS (state, clients) {
    state.clients = clients
  },

  SET_CLIENTS_BIRTHDAY (state, clients) {
    state.clientsBirthday = clients
  },

  SET_COMMANDES_BIRTHDAY (state, commandes) {
    state.commandesBirthday = commandes
  },

  ADD_CLIENT (state, clients) {
    state.clients.unshift(clients)
  },

  ADD_SEARCH_CLIENT (state, client) {
    const client_search = state.clients.find((c) => c._id === client._id)
    if (!client_search) {
      state.clients.unshift(client)
    }
  },

  UPDATE_CLIENT (state, client) {
    console.log(client)
    const clientIndex = state.clients.findIndex((m) => m._id === client._id)
    if (clientIndex > -1) {
      Object.assign(state.clients[clientIndex], client)
    }
  },

  REMOVE_CLIENT (state, itemId) {
    const clientIndex = state.clients.findIndex((u) => u._id === itemId)
    if (clientIndex > -1) {
      state.clients.splice(clientIndex, 1)
    }
  },

  SET_ORDONNANCES (state, ordonnances) {
    state.ordonnances = ordonnances
  },
  ADD_ORDONNANCE (state, ordonnance) {
    /* const clientIndex = state.clients.findIndex((u) => u._id === ordonnance._id)
    Object.assign(state.clients[clientIndex], ordonnance) */
    state.ordonnances.unshift(ordonnance)
  },

  UPDATE_ORDONNANCE (state, ordonnance) {
    const clientIndex = state.clients.findIndex((u) => u._id === ordonnance._id)
    if (clientIndex > -1) Object.assign(state.clients[clientIndex], ordonnance)

    const ordonnanceIndex = state.ordonnances.findIndex((m) => m._id === ordonnance._id)
    if (ordonnanceIndex > -1) Object.assign(state.ordonnances[ordonnanceIndex], ordonnance)
  },

  REMOVE_ORDONNANCE (state, payload) {
    const {clientId, refOrd } = payload
    const client = state.clients.find((c) => c._id === clientId)
    const ordonnanceIndex =  client.ordonnances.findIndex((o) => o.RefOrd === refOrd)
    if (ordonnanceIndex > -1) client.splice(ordonnanceIndex, 1)

  },
  SET_COMMANDES (state, commandes) {
    state.commandes = commandes
  },
  SET_COMMANDE_JOUR (state, commandes) {
    state.commandes_jour = commandes
  },
  SET_COMMANDE_JOUR_PRECEDENT (state, commandes) {
    state.commandes_jour_precedent = commandes
  },
  ADD_COMMANDE (state, commande) {
    const clientIndex = state.clients.findIndex((u) => u._id === commande._id)
    if (clientIndex > -1) Object.assign(state.clients[clientIndex], commande)
    state.commandes.unshift(commande)
  },

  UPDATE_COMMANDE (state, commande) {
    const clientIndex = state.clients.findIndex((u) => u._id === commande._id)
    if (clientIndex > -1) Object.assign(state.clients[clientIndex], commande)
    /* const commandeIndex = state.commandes.findIndex((m) => m._id === commande._id)
    if (commandeIndex) Object.assign(state.commandes[commandeIndex], commande) */
  },

  REMOVE_COMMANDE (state, itemId) {
    const commandeIndex = state.commandes.findIndex((u) => u._id === itemId)
    if (commandeIndex > -1) state.commandes.splice(commandeIndex, 1)
  },
  ADD_COMMANDE_TODAY_BY_AGENCE (state, data) {
    const {OfficineId, respData, dateDashBoard} = data
    respData.filter((client) => {
      client.commandes.filter((commande) => {
        const date_commande = (new Date(commande.DateCommd)).getTime()
        if (date_commande === (new Date(dateDashBoard)).getTime()) {
          state.commandes_toDay.push(commande)
        }
      })
    })
    state.commandes_today_by_agence.push({officine: OfficineId, commandes: state.commandes_toDay})
    state.commandes_toDay = []
  },
  ADD_COMMANDE_PREVDAY_BY_AGENCE (state, data) {
    const {OfficineId, respData, dateDashBoard} = data
    respData.filter((client) => {
      client.commandes.filter((commande) => {
        const date_commande = (new Date(commande.DateCommd)).getTime()
        if (date_commande === (new Date(dateDashBoard)).getTime()) {
          state.commandes_hier.push(commande)
        }
      })
    })
    state.commandes_hier_by_agence.push({officine: OfficineId, commandes: state.commandes_hier})
    state.commandes_hier = []
  },
  ADD_COMMANDE_CURRENTWEEK_BY_AGENCE (state, data) {
    const {OfficineId, respData, from, to} = data
    respData.filter((client) => {
      client.commandes.filter((commande) => {
        const date_commande = (new Date(commande.DateCommd)).getTime()
        if (date_commande >= (new Date(from)).getTime() && date_commande <= (new Date(to)).getTime()) {
          state.commandes_current_week.push(commande)
        }
      })
    })
    state.commandes_current_week_by_agence.push({officine: OfficineId, commandes: state.commandes_current_week})
    state.commandes_current_week = []
  },
  ADD_COMMANDE_PREVWEEK_BY_AGENCE (state, data) {
    const { OfficineId, respData, from, to } = data
  
    const filteredCommandes = []
  
    respData.forEach((client) => {
      client.commandes.forEach((commande) => {
        const date_commande = new Date(commande.DateCommd).getTime()
  
        if (date_commande >= new Date(from).getTime() && date_commande <= new Date(to).getTime()) {
          filteredCommandes.push(commande)
        }
      })
    })
  
    if (state.commandes_prev_week_by_agence) {
      state.commandes_prev_week_by_agence.push({ officine: OfficineId, commandes: filteredCommandes })
    }
  },
    
  ADD_COMMANDE_CURRENTMONTH_BY_AGENCE (state, data) {
    const {OfficineId, respData, from, to} = data
    respData.filter((client) => {
      client.commandes.filter((commande) => {
        const date_commande = (new Date(commande.DateCommd)).getTime()
        if (date_commande >= (new Date(from)).getTime() && date_commande <= (new Date(to)).getTime()) {
          state.commandes_current_month.push(commande)
        }
      })
    })
    state.commandes_current_month_by_agence.push({officine: OfficineId, commandes: state.commandes_current_month})
    state.commandes_current_month = []
  },
  ADD_COMMANDE_PREVMONTH_BY_AGENCE (state, data) {
    const {OfficineId, respData, from, to} = data
    respData.filter((client) => {
      client.commandes.filter((commande) => {
        const date_commande = (new Date(commande.DateCommd)).getTime()
        if (date_commande >= (new Date(from)).getTime() && date_commande <= (new Date(to)).getTime()) {
          state.commandes_prev_month.push(commande)
        }
      })
    })
    state.commandes_prev_month_by_agence.push({officine: OfficineId, commandes: state.commandes_prev_month})
    state.commandes_prev_month = []
  },
  ADD_COMMANDE_CURRENTYEAR_BY_AGENCE (state, data) {
    const {OfficineId, respData, from, to} = data
    respData.filter((client) => {
      client.commandes.filter((commande) => {
        const date_commande = (new Date(commande.DateCommd)).getTime()
        if (date_commande >= (new Date(from)).getTime() && date_commande <= (new Date(to)).getTime()) {
          state.commandes_current_year.push(commande)
        }
      })
    })
    state.commandes_current_year_by_agence.push({officine: OfficineId, commandes: state.commandes_current_year})
    state.commandes_current_year = []
  },
  ADD_COMMANDE_PREVYEAR_BY_AGENCE (state, data) {
    const {OfficineId, respData, from, to} = data
    respData.filter((client) => {
      client.commandes.filter((commande) => {
        const date_commande = (new Date(commande.DateCommd)).getTime()
        if (date_commande >= (new Date(from)).getTime() && date_commande <= (new Date(to)).getTime()) {
          state.commandes_prev_year.push(commande)
        }
      })
    })
    state.commandes_prev_year_by_agence.push({officine: OfficineId, commandes: state.commandes_prev_year})
    state.commandes_prev_year = []
  }
/*   VALIDATION_LIVRAISON_CLIENT (state, data) {
    const clientIndex = state.clients.findIndex((c) => c._id === data._id)
    Object.assign(state.clients[clientIndex], data)

  } */

}
