/*=========================================================================================
  File Name: moduleAssuranceState.js
  Description: state of module assurance
  ----------------------------------------------------------------------------------------
  Author: Eudoxie Djetouan
  Author email: eudoxiedjetouan@gmail.com
==========================================================================================*/

export default {
  assurances: []
}
  