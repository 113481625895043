/*=========================================================================================
  File Name: moduleEtatState.js
  Description: Etat Module State
  ----------------------------------------------------------------------------------------
==========================================================================================*/

export default {
  reglements: [],
  commandes: [],
  caisse_recettes: [],
  caisse_depenses: [],
  appro_depenses: [],
  br: [],
  articles_en_stock: [],
  articles_sortie: [],
  proformas: [],
  factures_traiter: [],
  ca: []
}
