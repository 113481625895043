/*=========================================================================================
  File Name: moduleCentreHospitalier.js
  Description: Centre Hospitalier Module
  ----------------------------------------------------------------------------------------
==========================================================================================*/


import state from './moduleCentreHospitalierState.js'
import mutations from './moduleCentreHospitalierMutations.js'
import actions from './moduleCentreHospitalierActions.js'
import getters from './moduleCentreHospitalierGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

