/*=========================================================================================
  File Name: moduleStockMutations.js
  Description: Stock Module Mutations
  ----------------------------------------------------------------------------------------
==========================================================================================*/


export default {
  SET_STOCKS (state, articles) {
    state.articles = articles
  },
  ADD_STOCK (state, article) {
    state.articles.unshift(article)
  },
  UPDATE_STOCK (state, article) {
    const articleIndex = state.articles.findIndex((a) => a._id === article._id)
    console.log(articleIndex)
    Object.assign(state.articles[articleIndex], article)
  },
  REMOVE_RECORD (state, itemId) {
    const articleIndex = state.articles.findIndex((a) => a._id === itemId)
    state.articles.splice(articleIndex, 1)
  },
  ADD_ARTICLE_TRANSFERER (state, article_transferer) {
    state.articles_transferer.unshift(article_transferer)
  },
  SET_ARTICLE_TRANSFERER (state, articles_transferer) {
    state.articles_transferer = articles_transferer
  }
}
