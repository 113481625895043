/*=========================================================================================
  File Name: moduleCommandeFournGetters.js
  Description: CommandeFourn Module Getters
  ----------------------------------------------------------------------------------------
==========================================================================================*/

export default {
  untransfertCommande: state => {
    const commandes_four = state.pageOfItems ? state.pageOfItems.filter((c) => c.transfert_total_in_stock === false) : []
    return commandes_four.length ? commandes_four : []
  },
  transfertCommande: state => {
    const commandes_four = state.pageOfItems_commande_fourns_transferer ? state.pageOfItems_commande_fourns_transferer.filter((c) => c.transfert_total_in_stock === true) : []
    return commandes_four.length ? commandes_four : []
  },
  getCommandeFournisseurById: state => id => {

    const commande = state.commande_fourns_transferer !== null ? (state.commande_fourns_transferer.find((c) => c._id === id) ? state.commande_fourns_transferer.find((c) => c._id === id) : state.pageOfItems.find((c) => c._id === id)) : state.pageOfItems.find((p) => p._id === id)
    return commande ? commande : {}
  }
}
