/*=========================================================================================
  File Name: moduleFondDeCaisseActions.js
  Description: FondDeCaisse Module Actions
  ----------------------------------------------------------------------------------------

==========================================================================================*/

import axios from '@/axios.js'

export default {
  addFondDeCaisse ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/fond_de_caisse/add', {payload})
        .then((response) => {
          commit('ADD_FONDDECAISSE', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateFondDeCaisse ({commit}, payload) {
    const {id} = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/fonddecaisses/update/${id}`, {payload})
        .then((response) => {
          commit('UPDATE_FONDDECAISSE', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getFondDecaisses ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/fond_de_caisses')
        .then((response) => {
          commit('SET_FONDDECAISSES', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  getFondDeCaisseByOfficine ({ commit }, officineId) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/fond_de_caisse/officine/${officineId}`)
        .then((response) => {
          commit('SET_FONDDECAISSES', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchFonddecaisse (context, fonddecaisseId) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/fond_de_caisse/${fonddecaisseId}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  removeFondDeCaisse ({ commit }, fonddecaisseId) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/fond_de_caisse/${fonddecaisseId}`)
        .then((response) => {
          commit('REMOVE_RECORD', fonddecaisseId)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
