/*=========================================================================================
  File Name: moduleFacturation.js
  Description: Facturation Module
  ----------------------------------------------------------------------------------------
==========================================================================================*/


import state from './moduleFacturationState.js'
import mutations from './moduleFacturationMutations.js'
import actions from './moduleFacturationActions.js'
import getters from './moduleFacturationGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

