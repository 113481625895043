/*=========================================================================================
  File Name: moduleDepense.js
  Description: Depense Module
  ----------------------------------------------------------------------------------------
==========================================================================================*/


import state from './moduleConsultationState.js'
import mutations from './moduleConsultationMutations.js'
import actions from './moduleConsultationActions.js'
import getters from './moduleConsultationGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

