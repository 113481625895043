/*=========================================================================================
  File Name: moduleDepense.js
  Description: Depense Module
  ----------------------------------------------------------------------------------------
==========================================================================================*/


import state from './moduleDepenseState.js'
import mutations from './moduleDepenseMutations.js'
import actions from './moduleDepenseActions.js'
import getters from './moduleDepenseGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

