/*=========================================================================================
  File Name: moduleConfigsState.js
  Description: Configs Module State
  ----------------------------------------------------------------------------------------
==========================================================================================*/

export default {
  alertCaisse: {},
  smsConfig: {},
  typeSms: [],
  garantie: {},
  feries: [],
  devises: [],
  fuseauHoraire: [],
  langues: [],
  emailsAlertCaisse: [],
  themeModes: [],
  routerAnimation: [],
  RTL: []
}
