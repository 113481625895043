
import axios from '@/axios.js'

export default {

// get all Proforma
  getProformas ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/proformas')
        .then((response) => {
          commit('SET_PROFORMAS', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  getProformaById (context, proformaId) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/proforma/${proformaId}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  getProformaByOfficine ({ commit }, officineId) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/proformas/${officineId}`)
        .then((response) => {
          commit('SET_PROFORMAS', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  getProformaByClientId ({ commit }, clientId) {
    console.log(clientId)
    return new Promise((resolve, reject) => {
      axios.get(`/api/proforma/client/${clientId}`)
        .then((response) => {
          commit('SET_PROFORMAS', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  addProforma ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/proforma/create', { payload })
        .then((response) => {
          //console.log(response.data.data)
          commit('ADD_PROFORMA', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  updateProforma ({commit}, payload) {
    const {id} = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/proforma/update/${id}`, { payload })
        .then((response) => {
          commit('UPDATE_PROFORMA', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },


  removeProforma ({ commit }, proformaId) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/proforma/drop/${proformaId}`)
        .then((response) => {
          commit('REMOVE_PROFORMA', proformaId)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }


}
