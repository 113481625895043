// axios
import axios from 'axios'

//<<<<<<< M.Djezou
//const baseURL = 'http://localhost:3000'
const baseURL = 'https://api.fovea-erp.com'
export default axios.create({
  baseURL,
  headers: {
    authorization: `Bearer ${localStorage.getItem('accessToken')}`
  }
  // You can add your headers here
})
