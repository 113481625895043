import state from './moduleVerreState'
import mutations from './moduleVerreMutations'
import actions from './moduleVerreActions'
import getters from './moduleVerreGetters'

export default {
  namespaced:true,
  state,
  mutations,
  actions,
  getters
}
