/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  Object Strucutre:
                    path => router path
                    name => router name
                    component(lazy loading) => component to load
                    meta : {
                      rule => which user can have access (ACL)
                      breadcrumb => Add breadcrumb to specific page
                      pageTitle => Display title besides breadcrumb
                    }
  ----------------------------------------------------------------------------------------

==========================================================================================*/


import Vue from 'vue'
import Router from 'vue-router'
import auth from '@/auth/authService'


Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes: [

    {
    // =============================================================================
    // MAIN LAYOUT ROUTES
    // =============================================================================
      path: '',
      component: () => import('./layouts/main/Main.vue'),
      children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
        {
          path: '/',
          redirect: '/login'
        },

        {
          path: '/dashboard',
          name: 'dashboard',
          component: () => import('@/views/DashboardAnalytics.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Tableau de Borad', active: true }
            ],
            parent: 'dashboard',
            pageTitle: 'Tableau de Board',
            rule: 'editor',
            authRequired: true
          }

        },

        {
          path: '/dashboard/ecommerce',
          name: 'dashboard-ecommerce',
          component: () => import('./views/DashboardECommerce.vue'),
          meta: {
            rule: 'admin',
            authRequired: true
          }
        },

        //******************************************************************** */
        // Parametrage
        //******************************************************************** */
        /* entete peidpage */
        {
          path: '/apps/config-facture/entete-piedpage',
          name: 'entete-piedpage',
          component: () => import('@/views/apps/entete_piedpage/entete_piedpage.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Entête-PiedPage', active: true }
            ],
            parent: 'entete-piedpage',
            pageTitle: 'Entête & Pied de page',
            rule: 'editor',
            authRequired: true
          }
        },

        /*Officines */
        {
          path: '/apps/officine/officine-list',
          name: 'app-officine-list',
          component: () => import('@/views/apps/officine/officine_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Officine' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-officine-list',
            pageTitle: 'Liste',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/officine/officine-add',
          name: 'app-officine-add',
          component: () => import('@/views/apps/officine/officine_add.vue'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/dashboard'},
              {title: 'Officine', url: '/apps/officine/officine-list'},
              {title: 'Ajouter', active: true}
            ],
            parent: 'app-officine-list',
            pageTitle: 'Ajouter',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/officine/officine-edit/:officineId',
          name: 'app-officine-list',
          component: () => import('@/views/apps/officine/officine_edit.vue'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/dashboard'},
              {title: 'Officine', url: '/apps/officine/officine-list'},
              {title: 'Modifier', active: true}
            ],
            parent: 'app-officine-edit',
            pageTitle: 'Modifier',
            rule: 'editor',
            authRequired: true
          }
        },

        //******************************************************************** */
        /*Personnels */
        {
          path: '/apps/personnel/personnel-list',
          name: 'app-personnel-list',
          component: () => import('@/views/apps/personnel/personnel-list/PersonnelView.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Personnel' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-personnel-list',
            pageTitle: 'Personnel Liste',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/personnel/personnel-view/:personnelId',
          name: 'app-personnel-view',
          component: () => import('@/views/apps/personnel/PersonnelDetails.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Personnel' },
              { title: 'View', active: true }
            ],
            parent: 'app-personnel-view',
            pageTitle: 'Personnel View',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/personnel/personnel-edit/:personnelId',
          name: 'app-personnel-edit',
          component: () => import('@/views/apps/personnel/personnel-edit/PersonnelEdit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Personnel', url: '/apps/personnel/personnel-list'},
              { title: 'Edit', active: true }
            ],
            parent: 'app-personnel-edit',
            pageTitle: 'Personnel Edit',
            rule: 'editor',
            authRequired: true
          }
        },

        {
          path: '/apps/personnel/personnel-add',
          name: 'app-personnel-add',
          component: () => import('@/views/apps/personnel/personnel-list/PersonnelNew.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Personnel' },
              { title: 'Add', active: true }
            ],
            parent: 'app-personnel-add',
            pageTitle: 'Personnel Add',
            rule: 'editor',
            authRequired: true
          }
        },

        /******************************************************************** */
        /*Utilisateur */

        {
          path: '/apps/user/user-list',
          name: 'app-user-list',
          component: () => import('@/views/apps/user/user-list/UserList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Utilisateur' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-user-list',
            pageTitle: 'Liste',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/user/user-view/:userId',
          name: 'app-user-view',
          component: () => import('@/views/apps/user/UserView.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'User' },
              { title: 'View', active: true }
            ],
            parent: 'app-user-view',
            pageTitle: 'User View',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/user/user-edit/:userId',
          name: 'app-user-edit',
          component: () => import('@/views/apps/user/user-edit/UserEdit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'User' },
              { title: 'Edit', active: true }
            ],
            parent: 'app-user-edit',
            pageTitle: 'User Edit',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/user/user-add',
          name: 'app-user-add',
          component: () => import('@/views/apps/user/UserAdd.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'User' },
              { title: 'Add', active: true }
            ],
            parent: 'app-user-add',
            pageTitle: 'User Add',
            rule: 'editor',
            authRequired: true
          }
        },

        //******************************************************************** */
        /*Fonctions */
        {
          path: '/apps/fonction/fonction-list',
          name: 'app-fonction-list',
          component: () => import('@/views/apps/fonction/fonction-list/FonctionList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Fonction' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-fonction-list',
            pageTitle: 'Fonction List',
            rule: 'editor',
            authRequired: true
          }
        },

        {
          path: '/apps/fonction/fonction-add',
          name: 'app-fonction-add',
          component: () => import('@/views/apps/fonction/Fonction_add.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Nouvelle Fonction' },
              { title: 'Add', active: true }
            ],
            parent: 'app-fonction-add',
            pageTitle: 'Fonction ajout',
            rule: 'editor',
            authRequired: true
          }
        },


        //******************************************************************** */
        /*Societes */
        {
          path: '/apps/societes/societes-list',
          name: 'app-societes-list',
          component: () => import('@/views/apps/societe/societe_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'societes' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-societes-list',
            pageTitle: 'Societes Liste',
            rule: 'editor',
            authRequired: true
          }
        },

        //******************************************************************** */
        /*Banques */
        {
          path: '/apps/banques/banques-list',
          name: 'app-banques-list',
          component: () => import('@/views/apps/banque/banque_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'banques' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-banques-list',
            pageTitle: 'Liste',
            rule: 'editor',
            authRequired: true
          }
        },

        //******************************************************************** */
        /*Assurances */

        {
          path: '/apps/assurances/assurances-list',
          name: 'app-assurances-list',
          component: () => import('@/views/apps/assurance/assurance_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'assurances' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-assurances-list',
            pageTitle: 'Assurances Liste',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/assurances/assurance-edit/:assuranceId',
          name: 'app-assurance-edit',
          component: () => import('@/views/apps/assurance/assurance_edit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'assurances', url: '/apps/assurances/assurances-list' },
              { title: 'Edit', active: true }
            ],
            parent: 'app-assurance-edit',
            pageTitle: 'Assurance Edit',
            rule: 'editor',
            authRequired: true
          }
        },


        //******************************************************************** */
        /*centre_hopitalier*/

        {
          path: '/apps/centre_hopitalier/centre_hopitalier-list',
          name: 'app-centre_hopitalier-list',
          component: () => import('@/views/apps/centre_hopitalier/centre_hopitalier_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Centres hospitaliers' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-centre_hopitalier-list',
            pageTitle: 'Liste',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/centre_hopitalier/centre_hopitalier-add',
          name: 'app-centre_hopitalier-add',
          component: () => import('@/views/apps/centre_hopitalier/centre_hopitalier_add.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Centres hospitaliers', url: '/apps/centre_hopitalier/centre_hopitalier-list' },
              { title: 'Nouveau', active: true }
            ],
            parent: 'app-centre_hopitalier-list',
            pageTitle: 'Nouveau Centre Hospitalier',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/centre_hopitalier/centre_hopitalier-edit/:centreId',
          name: 'app-centre_hopitalier-edit',
          component: () => import('@/views/apps/centre_hopitalier/centre_hopitalier_edit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Centres hospitaliers', url: '/apps/centre_hopitalier/centre_hopitalier-list' },
              { title: 'Modifier', active: true }
            ],
            parent: 'app-centre_hopitalier-modif',
            pageTitle: 'Modification Centre Hospitalier',
            rule: 'editor',
            authRequired: true
          }
        },

        //******************************************************************** */
        /*prescripteur*/

        {
          path: '/apps/prescripteur/prescripteur-list',
          name: 'app-prescripteur-list',
          component: () => import('@/views/apps/prescripteur/prescripteur_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Prescripteurs' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-prescripteur-list',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/prescripteur/prescripteur-add',
          name: 'app-prescripteur-add',
          component: () => import('@/views/apps/prescripteur/prescripteur_add.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Prescripteur', url: '/apps/prescripteur/prescripteur-list' },
              { title: 'Nouveau', active: true }
            ],
            parent: 'app-prescripteur-list',
            pageTitle: 'Nouveau prescripteur',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/prescripteur/prescripteur-view',
          name: 'app-prescripteur-view',
          component: () => import('@/views/apps/prescripteur/prescripteur_view.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Prescripteur', url: '/apps/prescripteur/prescripteur-list'},
              { title: 'View', active: true }
            ],
            parent: 'app-prescripteur-list',
            pageTitle: 'Informations Centre Hospitalier',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/prescripteur/prescripteur-edit/:prescripteurId',
          name: 'app-prescripteur-edit',
          component: () => import('@/views/apps/prescripteur/prescripteur_edit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Prescripteurs', url: '/apps/prescripteur/prescripteur-list' },
              { title: 'Modifier', active: true }
            ],
            parent: 'app-prescripteur-modif',
            pageTitle: 'Modifier',
            rule: 'editor',
            authRequired: true
          }
        },


        //******************************************************************** */
        /*Categories Articles*/
        {
          path: '/apps/categorie_article/categorie_article-list',
          name: 'app-categorie_article-list',
          component: () => import('@/views/apps/categorie_article/categorie_article_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'categorie_article' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-categorie_article-list',
            pageTitle: 'Categories Articles Liste',
            rule: 'editor',
            authRequired: true
          }
        },


        //******************************************************************** */
        /*Professions*/
        {
          path: '/apps/profession/profession-list',
          name: 'app-profession_article-list',
          component: () => import('@/views/apps/profession/profession_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'professions' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-profession_article-list',
            pageTitle: 'Professions Liste',
            rule: 'editor',
            authRequired: true
          }
        },


        //******************************************************************** */
        /*Partenaires*/
        {
          path: '/apps/partenaire/partenaire-list',
          name: 'app-profession_article-list',
          component: () => import('@/views/apps/partenaire/partenaire_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Partenaires' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-profession_article-list',
            pageTitle: 'Partenaires Liste',
            rule: 'editor',
            authRequired: true
          }
        },

        //******************************************************************** */
        /*Fournisseurs*/
        {
          path: '/apps/fournisseur/fournisseur-list',
          name: 'app-fournisseur_article-list',
          component: () => import('@/views/apps/fournisseur/fournisseur_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Fournisseur' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-fournisseur_article-list',
            pageTitle: 'Fournisseurs List',
            rule: 'editor',
            authRequired: true
          }
        },

        //******************************************************************** */
        /*Fournisseurs verres*/
        {
          path: '/apps/fournisseurverre/fournisseurverre-list',
          name: 'app-fournisseurverre-list',
          component: () => import('@/views/apps/fournisseurverre/fournisseurverre_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Fournisseurs verres' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-fournisseurverre-list',
            pageTitle: 'Fournisseurs verres',
            rule: 'editor',
            authRequired: true
          }
        },

        //******************************************************************** */
        /*Catalogue verres*/

        {
          path: '/apps/fournisseurverre/catalogue-verre',
          name: 'app-catalogue-verre',
          component: () => import('@/views/apps/catalogue_verre/catalogue_verre.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard'},
              { title: 'Catalogue verre', active: true}
            ],
            parent: 'catalogue-verre',
            pageTitle: 'Catalogue verre',
            rule: 'editor',
            authRequired: true
          }
        },

        //******************************************************************** */
        /*Marques*/
        {
          path: '/apps/marque/marque-list',
          name: 'app-marque-list',
          component: () => import('@/views/apps/marque/marque_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'marque' },
              { title: 'List', active: true }
            ],
            parent: 'app-marque-list',
            pageTitle: 'Marques Liste',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/marque/marque-add',
          name: 'app-marque-add',
          component: () => import('@/views/apps/marque/marque_add.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'marque' },
              { title: 'Ajout', active: true }
            ],
            parent: 'app-marque-list',
            pageTitle: 'Ajout de marque',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/marque/marque-view',
          name: 'app-marque-view',
          component: () => import('@/views/apps/marque/marque_view.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Centres hospitaliers' },
              { title: 'View', active: true }
            ],
            parent: 'app-marque-list',
            pageTitle: 'Informations Centre Hospitalier',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/marque/marque-edit',
          name: 'app-marque-edit',
          component: () => import('@/views/apps/marque/marque_edit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Marques' },
              { title: 'Modif', active: true }
            ],
            parent: 'app-marque-modif',
            pageTitle: 'Modification Marque',
            rule: 'editor',
            authRequired: true
          }
        },

        //******************************************************************** */
        /*Types*/
        {
          path: '/apps/type/type-list',
          name: 'app-type-list',
          component: () => import('@/views/apps/type/type_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Types' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-type-list',
            pageTitle: 'Types article',
            rule: 'editor',
            authRequired: true
          }
        },

        //******************************************************************** */
        /*Styles*/
        {
          path: '/apps/style/style-list',
          name: 'app-type-list',
          component: () => import('@/views/apps/style/style_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Styles' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-type-list',
            pageTitle: 'Styles article',
            rule: 'editor',
            authRequired: true
          }
        },

        //******************************************************************** */
        /*Pays*/
        {
          path: '/apps/pays/pays-list',
          name: 'app-pays-list',
          component: () => import('@/views/apps/pays/pays_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Pays' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-pays-list',
            pageTitle: 'Pays Liste',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/pays/pays-add',
          name: 'app-pays-add',
          component: () => import('@/views/apps/pays/pays_add.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'pays' },
              { title: 'Ajout', active: true }
            ],
            parent: 'app-pays-list',
            pageTitle: 'Ajout de pays',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/pays/pays-view',
          name: 'app-pays-view',
          component: () => import('@/views/apps/pays/pays_view.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Pays' },
              { title: 'View', active: true }
            ],
            parent: 'app-pays-list',
            pageTitle: 'Informations Pays',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/pays/pays-edit',
          name: 'app-pays-edit',
          component: () => import('@/views/apps/pays/pays_edit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Centres hospitaliers' },
              { title: 'Modif', active: true }
            ],
            parent: 'app-pays-modif',
            pageTitle: 'Modification Centre Hospitalier',
            rule: 'editor',
            authRequired: true
          }
        },
        //******************************************************************** */
        /*Quartiers*/
        {
          path: '/apps/ville/ville-list',
          name: 'app-ville-list',
          component: () => import('@/views/apps/ville/ville_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Villes' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-ville-list',
            pageTitle: 'Villes List',
            rule: 'editor',
            authRequired: true
          }
        },

        //******************************************************************** */
        /*Quartiers*/
        {
          path: '/apps/quartier/quartier-list',
          name: 'app-quartier-list',
          component: () => import('@/views/apps/quartier/quartier_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Quartiers' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-quartier-list',
            pageTitle: 'Quartiers Liste',
            rule: 'editor',
            authRequired: true
          }
        },

        //******************************************************************** */
        // Commercial
        //******************************************************************** */

        {
          path: '/commercial/agenda',
          name: 'Agenda',
          component: () => import('@/views/apps/agenda/Agenda.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Commercial' },
              { title: 'Agenda', active: true }
            ],
            parent: 'commercial',
            pageTitle: 'Rendez-vous',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/commercial/repertoire',
          name: 'Repertoire',
          component: () => import('@/views/apps/repertoire/Repertoire.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Commercial' },
              { title: 'Répertoire', active: true }
            ],
            parent: 'commercial',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/commercial/opportunites',
          name: 'Opportunites',
          component: () => import('@/views/apps/opportunite/Opportunite.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Commercial' },
              { title: 'Opportunités', active: true }
            ],
            parent: 'commercial',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/commercial/opportunite/:slug',
          name: 'OpportuniteView',
          component: () => import('@/views/apps/opportunite/OpportuniteView.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Commercial' },
              { title: 'Opportunités', url: '/commercial/opportunites' },
              { title: 'Détails', active: true }
            ],
            parent: 'commercial',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/commercial/suspects',
          name: 'Suspectes',
          component: () => import('@/views/apps/suspect/Suspect.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Commercial' },
              { title: 'Suspectes', active: true }
            ],
            parent: 'commercial',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/commercial/suspect/:id',
          name: 'SuspecteView',
          component: () => import('@/views/apps/suspect/SuspectView.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Commercial' },
              { title: 'Suspectes', url: '/commercial/suspects' },
              { title: 'Détails', active: true }
            ],
            parent: 'commercial',
            rule: 'editor',
            authRequired: true
          }
        },


        //******************************************************************** */
        // Gestion de stock
        //******************************************************************** */
        /*Commande fournisseur*/
        {
          path: '/apps/commandefourn/commandefourn-list',
          name: 'app-commandefourn-list',
          component: () => import('@/views/apps/commandefourn/commandefourn_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Bon de réception' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-commandefourn-list',
            pageTitle: 'Bon de réception',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/commandefourn/commandefourn-transferer',
          name: 'app-commandefourn-transferer',
          component: () => import('@/views/apps/commandefourn/commande_transferer.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Bon de réception' },
              { title: 'Transferés', active: true }
            ],
            parent: 'app-commandefourn-transferer',
            pageTitle: 'Bon de réception transferés',
            rule: 'editor',
            authRequired: true
          }
        },

        {
          path: '/apps/commandefourn/add',
          name: 'app-commandefourn-list',
          component: () => import('@/views/apps/commandefourn/commandefourn_add.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Bon de réception', url: '/apps/commandefourn/commandefourn-list' },
              { title: 'Add', active: true }
            ],
            parent: 'app-commandefourn-list',
            pageTitle: 'Bon de réception',
            rule: 'editor',
            authRequired: true
          }
        },

        {
          path: '/apps/commandefourn/edit/:commandeFourId',
          name: 'app-commandefourn-edit',
          component: () => import('@/views/apps/commandefourn/commandefourn_edit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Bon de réception', url: '/apps/commandefourn/commandefourn-list' },
              { title: 'Modifier', active: true }
            ],
            parent: 'app-commandefourn-list',
            pageTitle: 'Bon de réception modifier',
            rule: 'editor',
            authRequired: true
          }
        },

        {
          path: '/apps/commandefourn/view/:commandeFourId',
          name: 'app-commandefourn-view',
          component: () => import('@/views/apps/commandefourn/commandefourn_view.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Bon de réception', url: '/apps/commandefourn/commandefourn-list' },
              { title: 'Aperçu', active: true }
            ],
            parent: 'app-commandefourn-list',
            pageTitle: 'Bon de réception',
            rule: 'editor',
            authRequired: true
          }
        },

        {
          path: '/apps/stock',
          name: 'stock',
          component: () => import('@/views/apps/stock/stock_view.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard'},
              { title: 'Stock', active: true}
            ],
            parent: 'app-stock',
            pageTitle: 'Stock',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/stock-verre',
          name: 'stock-verre',
          component: () => import('@/views/apps/stock/stock_verre.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard'},
              { title: 'Stock verre', active: true}
            ],
            parent: 'app-stock',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/stock/articles-transferer',
          name: 'Articles_transferes',
          component: () => import('@/views/apps/stock/article_transferer.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard'},
              { title: 'Articles transferés', active: true}
            ],
            parent: 'app-stock',
            pageTitle: 'Articles Transferés',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/stock/transfert-article/details/:articleTransfereId',
          name: 'Article_transfere_detail',
          component: () => import('@/views/apps/stock/article_transferer_details.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard'},
              { title: 'Articles transferés', url: '/apps/stock/articles-transferer'},
              { title: 'Détails', active: true}
            ],
            parent: 'app-stock',
            pageTitle: 'Détails',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/inventaire',
          name: 'inventaire',
          component: () => import('@/views/apps/stock/inventaire.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard'},
              { title: 'Inventaire', active: true}
            ],
            parent: 'app-stock',
            pageTitle: 'Inventaire',
            rule: 'editor',
            authRequired: true
          }
        },

        //******************************************************************** */
        /*Enrégistrement facture*/


        //******************************************************************** */
        /*Enrégistrement facture*/


        //******************************************************************** */
        // Exploitation'
        //******************************************************************** */

        /*Clients*/

        {
          path: '/apps/client/client-list',
          name: 'app-client-list',
          component: () => import('@/views/apps/client/client_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'clients' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-client-list',
            pageTitle: 'Liste',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/client/client-add',
          name: 'app-client-add',
          component: () => import('@/views/apps/client/client_add.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Clients', url: '/apps/client/client-list' },
              { title: 'Nouveau', active: true }
            ],
            parent: 'app-client-list',
            pageTitle: 'Nouveau',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/client/client-view/:clientId',
          name: 'app-client-view',
          component: () => import('@/views/apps/client/client_view.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Clients', url: '/apps/client/client-list' },
              { title: 'Dossier client', active: true }
            ],
            parent: 'app-client-list',
            pageTitle: 'Dossier Client',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/client/client-edit/:clientId',
          name: 'app-client-edit',
          component: () => import('@/views/apps/client/client_edit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Client', url: '/apps/client/client-list' },
              { title: 'Modifier', active: true }
            ],
            parent: 'app-client-list',
            pageTitle: 'Modifier',
            rule: 'editor',
            authRequired: true
          }
        },
        //******************************************************************** */
        /*Ordonnances*/
        {
          path: '/apps/ordonnance/ordonnance-list',
          name: 'app-ordonnance-list',
          component: () => import('@/views/apps/ordonnance/ordonnance_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'ordonnances' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-ordonnance-list',
            pageTitle: 'Ordonnances Liste',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/ordonnance/ordonnance-add',
          name: 'app-ordonnance-add',
          component: () => import('@/views/apps/ordonnance/ordonnance_add.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'ordonnance', url: '/apps/ordonnance/ordonnance-list' },
              { title: 'Nouveau', active: true }
            ],
            parent: 'app-ordonnance-list',
            pageTitle: 'Nouveau',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/ordonnance/ordonnance-add/:clientId',
          name: 'app-ordonnance-add',
          component: () => import('@/views/apps/ordonnance/ordonnance_add_direct.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Ordonnance', url: '/apps/ordonnance/ordonnance-list' },
              { title: 'Nouveau', active: true }
            ],
            parent: 'app-ordonnance-list',
            pageTitle: 'Nouveau',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/ordonnance/ordonnance-view/:clientId/:refOrd',
          name: 'app-ordonnance-view',
          component: () => import('@/views/apps/ordonnance/ordonnance_view.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Ordonnance', url: '/apps/ordonnance/ordonnance-list' },
              { title: 'Détails', active: true }
            ],
            parent: 'app-ordonnance-list',
            pageTitle: 'Détail',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/ordonnance/ordonnance-edit/:clientId/:refOrd',
          name: 'app-ordonnance-edit',
          component: () => import('@/views/apps/ordonnance/ordonnance_edit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Ordonnance', url: '/apps/ordonnance/ordonnance-list'  },
              { title: 'Modifier', active: true }
            ],
            parent: 'app-pays-modif',
            pageTitle: 'Modifier',
            rule: 'editor',
            authRequired: true
          }
        },
        //******************************************************************** */
        /*Proformas clients*/
        {
          path: '/apps/proformaclient/proforma-list',
          name: 'app-proformaclient-list',
          component: () => import('@/views/apps/proformaclient/proformaclient_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Proforma' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-proformaclient-list',
            pageTitle: 'Proforma Liste',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/proformaclient/add',
          name: 'app-proformaclient-add',
          component: () => import('@/views/apps/proformaclient/proformaclient_add_direct.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Proformas', url: '/apps/proformaclient/proforma-list' },
              { title: 'Nouvelle', active: true }
            ],
            parent: 'app-proformaclient-list',
            pageTitle: 'Nouvelle',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/proformaclient/add/:clientId',
          name: 'app-proformaclient-add',
          component: () => import('@/views/apps/proformaclient/proformaclient_add.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Proformas Clients' },
              { title: 'Nouveau', active: true }
            ],
            parent: 'app-proformaclient-list',
            pageTitle: 'Proforma',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/proforma-client/edit/:proformaId',
          name: 'app-proformaclient-edit',
          component: () => import('@/views/apps/proformaclient/proformaclient_edit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Proforma', url: '/apps/proformaclient/proforma-list' },
              { title: 'Modifier', active: true }
            ],
            parent: 'app-proformaclient-list',
            pageTitle: 'Modifier',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/proforma-client/details/:proformaId',
          name: 'app-proformaclient-details',
          component: () => import('@/views/apps/proformaclient/proformaclient_view.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Proforma', url: '/apps/proformaclient/proforma-list' },
              { title: 'Détails', active: true }
            ],
            parent: 'app-proformaclient-list',
            pageTitle: 'Détails',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/proforma-commande/:proformaId',
          name: 'app-proforma-commande',
          component: () => import('@/views/apps/proformaclient/proforma_to_commande.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Proforma', url: '/apps/proformaclient/proforma-list' },
              { title: 'Commande', active: true }
            ],
            parent: 'app-proformaclient-list',
            pageTitle: 'Commande',
            rule: 'editor',
            authRequired: true
          }
        },

        //******************************************************************** */
        /*Commandes clients*/
        {
          path: '/apps/commandeclient/commande-list',
          name: 'app-commandeclient-list',
          component: () => import('@/views/apps/commandeclient/commandeclient_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Ventes' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-commandeclient-list',
            pageTitle: 'Ventes',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/commandeclient/add',
          name: 'app-proformaclient-add',
          component: () => import('@/views/apps/commandeclient/commandeclient_direct_add.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Ventes' },
              { title: 'Nouvelle', active: true }
            ],
            parent: 'app-commandeclient-add',
            pageTitle: 'Nouvelle',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/commandeclient/add/:clientId',
          name: 'app-proformaclient-add',
          component: () => import('@/views/apps/commandeclient/commandeclient_add.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Vente' },
              { title: 'Nouvelle', active: true }
            ],
            parent: 'app-commandeclient-add',
            pageTitle: 'Nouvelle',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/commandeclient/edit/:clientId/:RefCmmd',
          name: 'app-proformaclient-edit',
          component: () => import('@/views/apps/commandeclient/commandeclient_edit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Ventes', url: '/apps/commandeclient/commande-list'},
              { title: 'Modifier', active: true }
            ],
            parent: 'app-commandeclient-edit',
            pageTitle: 'Modifier',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/commandeclient/detail/:clientId/:RefCmmd',
          name: 'app-proformaclient-detail',
          component: () => import('@/views/apps/commandeclient/commandeclient_view.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Ventes', url: '/apps/commandeclient/commande-list' },
              { title: 'Détail', active: true }
            ],
            parent: 'app-commandeclient-list',
            pageTitle: 'Détail',
            rule: 'editor',
            authRequired: true
          }
        },

        //******************************************************************** */
        /*Commandes verre*/

        {
          path: '/apps/commandes-verres',
          name: 'app-commandes-verre',
          component: () => import('@/views/apps/commande_verre/commande_verre.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Commandes verres', active: true}
            ],
            parent: 'app-commandes-verre',
            pageTitle: 'Commandes verres',
            rule: 'editor',
            authRequired: true
          }
        },

        //******************************************************************** */
        /*Atelier*/

        {
          path: '/apps/atelier',
          name: 'atelier',
          component: () => import('@/views/apps/atelier/atelier.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Atelier', active: true}
            ],
            parent: 'atelier',
            pageTitle: 'Atelier',
            rule: 'editor',
            authRequired: true
          }
        },

        //******************************************************************** */
        /*Examen de vue*/

        {
          path: '/apps/examen-vue',
          name: 'examen-vue',
          component: () => import('@/views/apps/examen_vue/examen_vue.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Examen de vue', active: true}
            ],
            parent: 'examen-vue',
            pageTitle: 'Examen de vue',
            rule: 'editor',
            authRequired: true
          }
        },

        //******************************************************************** */
        /*Commandes comptoires*/
        {
          path: '/apps/commandecomptoir/commandecomptoir-list',
          name: 'app-commandecomptoir-list',
          component: () => import('@/views/apps/commandecomptoir/commandecomptoir_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Commandes comptoires' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-commandecomptoir-list',
            pageTitle: 'Commandes comptoires List',
            rule: 'editor',
            authRequired: true
          }
        },

        //******************************************************************** */
        // Caisse
        //******************************************************************** */

        /*caisse recette*/

        {
          path: '/apps/caisse',
          name: 'app-caisse',
          component: () => import('@/views/apps/caisse/caisse_view.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Caisse', active: true }
            ],
            parent: 'app-caisse',
            pageTitle: 'Caisse',
            rule: 'editor',
            authRequired: true
          }
        },
        /*opération*/
        {
          path: '/apps/operations',
          name: 'operations',
          component: () => import('@/views/apps/caisse/operation.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Opérations', active: true }
            ],
            parent: 'app-caisse',
            pageTitle: 'Opérations',
            rule: 'editor',
            authRequired: true
          }
        },

        /*caisse depense*/

        {
          path: '/apps/depense',
          name: 'app-depense',
          component: () => import('@/views/apps/caisse/caisse_depense.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Dépense', active: true }
            ],
            parent: 'app-depense',
            pageTitle: 'Dépense',
            rule: 'editor',
            authRequired: true
          }
        },

        /* reglement client */
        {
          path: '/apps/caisse/:clientId/:RefCmmd',
          name: 'app-caisse',
          component: () => import('@/views/apps/caisse/caisse_view.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Caisse', active: true }
            ],
            parent: 'app-caisse',
            pageTitle: 'Caisse',
            rule: 'editor',
            authRequired: true
          }
        },

        /*Fond de caisse*/

        {
          path: '/apps/fonddecaisse/fonddecaisse-list',
          name: 'app-fonddecaisse-list',
          component: () => import('@/views/apps/fonddecaisse/fonddecaisse_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Fond de caisse' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-fonddecaisse-list',
            pageTitle: 'Fond de caisse List',
            rule: 'editor',
            authRequired: true
          }
        },

        //******************************************************************** */

        /*Appro. caisse Recettes*/

        {
          path: '/apps/appro_caisse_recettes/appro_caisse_recettes-list',
          name: 'app-appro_caisse_recettes-list',
          component: () => import('@/views/apps/appro_caisse_recettes/appro_caisse_recettes_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Appro. caisse Recettes' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-appro_caisse_recettes-list',
            pageTitle: 'Appro. caisse Recettes List',
            rule: 'editor',
            authRequired: true
          }
        },

        //******************************************************************** */

        /*Appro. caisse dépenses*/

        {
          path: '/apps/appro_depense/appro_depense-list',
          name: 'app-appro_depense-list',
          component: () => import('@/views/apps/appro_depense/appro_depense_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Appro. caisse dépenses' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-appro_depense-list',
            pageTitle: 'Appro. caisse dépenses List',
            rule: 'editor',
            authRequired: true
          }
        },

        //******************************************************************** */

        /*Réglements clients cmdes*/

        {
          path: '/apps/reglement_cmdecli/reglement_cmdecli-list',
          name: 'app-reglement_cmdecli-list',
          component: () => import('@/views/apps/reglement_cmdecli/reglement_cmdecli_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Réglements clients cmdes' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-reglement_cmdecli-list',
            pageTitle: 'Réglements clients cmdes List',
            rule: 'editor',
            authRequired: true
          }
        },

        //******************************************************************** */

        /*Réglements clients comptoire*/

        {
          path: '/apps/reglement_cmd_cptoire/reglement_cmd_cptoire-list',
          name: 'app-reglement_cmd_cptoire-list',
          component: () => import('@/views/apps/reglement_cmd_cptoire/reglement_cmd_cptoire_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Réglements comptoires' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-reglement_cmd_cptoire-list',
            pageTitle: 'Réglements comptoires List',
            rule: 'editor',
            authRequired: true
          }
        },

        //******************************************************************** */

        /*Réglements clients cmdes*/

        {
          path: '/apps/versement_banque/versement_banque-list',
          name: 'app-versement_banque-list',
          component: () => import('@/views/apps/versement_banque/versement_banque_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Versements Banque' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-versement_banque-list',
            pageTitle: 'Versements Banque List',
            rule: 'editor',
            authRequired: true
          }
        },


        //******************************************************************** */

        /*Billetages*/

        {
          path: '/apps/billetage/billetage-list',
          name: 'app-billetage-list',
          component: () => import('@/views/apps/billetage/billetage_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Billetages' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-billetage-list',
            pageTitle: 'Billetages List',
            rule: 'editor',
            authRequired: true
            //no_scroll: true // add this
          }
        },
        {
          path: '/apps/billetage/billetage-add',
          name: 'app-billetage-add',
          component: () => import('@/views/apps/billetage/billetage_add.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'billetage' },
              { title: 'Ajout', active: true }
            ],
            parent: 'app-billetage-list',
            pageTitle: 'Ajout de billetage',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/billetage/billetage-view',
          name: 'app-billetage-view',
          component: () => import('@/views/apps/billetage/billetage_view.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'billetage' },
              { title: 'View', active: true }
            ],
            parent: 'app-billetage-list',
            pageTitle: 'Informations billetage',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/billetage/billetage-edit',
          name: 'app-billetage-edit',
          component: () => import('@/views/apps/billetage/billetage_edit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Billetage' },
              { title: 'Modif', active: true }
            ],
            parent: 'app-pays-modif',
            pageTitle: 'Modification Billetage',
            rule: 'editor',
            authRequired: true
          }
        },
        //******************************************************************** */

        /* Caisse Dépenses */

        {
          path: '/apps/depense/depense-list',
          name: 'app-depense-list',
          component: () => import('@/views/apps/depense/depense_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Caisse Dépenses' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-depense-list',
            pageTitle: 'Caisse Dépenses List',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/depense/depense-add',
          name: 'app-depense-add',
          component: () => import('@/views/apps/depense/depense_add.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'depense' },
              { title: 'Ajout', active: true }
            ],
            parent: 'app-depense-list',
            pageTitle: 'Ajout de depense',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/depense/depense-view',
          name: 'app-depense-view',
          component: () => import('@/views/apps/depense/depense_view.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'depense' },
              { title: 'View', active: true }
            ],
            parent: 'app-depense-list',
            pageTitle: 'Informations depense',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/depense/depense-edit',
          name: 'app-depense-edit',
          component: () => import('@/views/apps/depense/depense_edit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'depense' },
              { title: 'Modif', active: true }
            ],
            parent: 'app-pays-modif',
            pageTitle: 'Modification depense',
            rule: 'editor',
            authRequired: true
          }
        },
        // =======

        //******************************************************************** */
        // Parametrage
        //******************************************************************** */
        /*Officines */
        {
          path: '/apps/officine/officine-list',
          name: 'app-officine-list',
          component: () => import('@/views/apps/officine/officine_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Officine' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-officine-list',
            pageTitle: 'Officines Liste',
            rule: 'editor',
            authRequired: true
          }
        },

        //******************************************************************** */
        /*Personnels */
        {
          path: '/apps/personnel/personnel-list',
          name: 'app-personnel-list',
          component: () => import('@/views/apps/personnel/personnel-list/PersonnelView.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Personnel' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-personnel-list',
            pageTitle: 'Personnel Liste',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/personnel/personnel-view/:personnelId',
          name: 'app-personnel-view',
          component: () => import('@/views/apps/personnel/PersonnelDetails.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Personnel' },
              { title: 'View', active: true }
            ],
            parent: 'app-personnel-view',
            pageTitle: 'Personnel View',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/personnel/personnel-edit/:personnelId',
          name: 'app-personnel-edit',
          component: () => import('@/views/apps/personnel/personnel-edit/PersonnelEdit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Personnel' },
              { title: 'Edit', active: true }
            ],
            parent: 'app-personnel-edit',
            pageTitle: 'Personnel Edit',
            rule: 'editor',
            authRequired: true
          }
        },

        {
          path: '/apps/personnel/personnel-add',
          name: 'app-personnel-add',
          component: () => import('@/views/apps/personnel/personnel-list/PersonnelNew.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Personnel' },
              { title: 'Add', active: true }
            ],
            parent: 'app-personnel-add',
            pageTitle: 'Personnel Add',
            rule: 'editor',
            authRequired: true
          }
        },

        //******************************************************************** */
        /*Utilisateur */

        {
          path: '/apps/user/user-list',
          name: 'app-user-list',
          component: () => import('@/views/apps/user/user-list/UserList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'User' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-user-list',
            pageTitle: 'User Liste',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/user/user-view/:userId',
          name: 'app-user-view',
          component: () => import('@/views/apps/user/UserView.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'User' },
              { title: 'View', active: true }
            ],
            parent: 'app-user-view',
            pageTitle: 'User View',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/user/user-edit/:userId',
          name: 'app-user-edit',
          component: () => import('@/views/apps/user/user-edit/UserEdit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'User' },
              { title: 'Edit', active: true }
            ],
            parent: 'app-user-edit',
            pageTitle: 'User Edit',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/user/user-add',
          name: 'app-user-add',
          component: () => import('@/views/apps/user/UserAdd.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'User' },
              { title: 'Add', active: true }
            ],
            parent: 'app-user-add',
            pageTitle: 'User Add',
            rule: 'editor',
            authRequired: true
          }
        },

        //******************************************************************** */
        /*Fonctions */
        {
          path: '/apps/fonction/fonction-list',
          name: 'app-fonction-list',
          component: () => import('@/views/apps/fonction/fonction-list/FonctionList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Fonction' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-fonction-list',
            pageTitle: 'Fonction List',
            rule: 'editor',
            authRequired: true
          }
        },

        {
          path: '/apps/fonction/fonction-add',
          name: 'app-fonction-add',
          component: () => import('@/views/apps/fonction/Fonction_add.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Nouvelle Fonction' },
              { title: 'Add', active: true }
            ],
            parent: 'app-fonction-add',
            pageTitle: 'Fonction ajout',
            rule: 'editor',
            authRequired: true
          }
        },


        //******************************************************************** */
        /*Societes */
        {
          path: '/apps/societes/societes-list',
          name: 'app-societes-list',
          component: () => import('@/views/apps/societe/societe_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'societes' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-societes-list',
            pageTitle: 'Societes Liste',
            rule: 'editor',
            authRequired: true
          }
        },

        /**
{
  path: '/apps/societes/societes-view/:societeId',
  name: 'app-societe-view',
  component: () => import('@/views/apps/societe/societe_View.vue'),
  meta: {
    breadcrumb: [
      { title: 'Home', url: '/' },
      { title: 'societes' },
      { title: 'View', active: true }
    ],
    parent: 'app-societes-view',
    pageTitle: 'Societe View',
    rule: 'editor'
  }
},
{
  path: '/apps/societes/societe-edit/:societeId',
  name: 'app-societe-edit',
  component: () => import('@/views/apps/societe/societe_edit.vue'),
  meta: {
    breadcrumb: [
      { title: 'Home', url: '/' },
      { title: 'Societe' },
      { title: 'Edit', active: true }
    ],
    parent: 'app-societe-edit',
    pageTitle: 'Societe Edit',
    rule: 'editor'
  }
},
{
  path: '/apps/societe/societe-add',
  name: 'app-societe-add',
  component: () => import('@/views/apps/societe/Societe_add.vue'),
  meta: {
    breadcrumb: [
      { title: 'Home', url: '/' },
      { title: 'Societe' },
      { title: 'Add', active: true }
    ],
    parent: 'app-societe-add',
    pageTitle: 'Societe Add',
    rule: 'editor'
  }
},
*/

        //******************************************************************** */
        /*Assurances */

        {
          path: '/apps/assurances/assurances-list',
          name: 'app-assurances-list',
          component: () => import('@/views/apps/assurance/assurance_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'assurances' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-assurances-list',
            pageTitle: 'Assurances Liste',
            rule: 'editor',
            authRequired: true
          }
        },

        //******************************************************************** */
        /*prescripteur*/

        {
          path: '/apps/prescripteur/prescripteur-list',
          name: 'app-prescripteur-list',
          component: () => import('@/views/apps/prescripteur/prescripteur_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Prescripteurs' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-prescripteur-list',
            pageTitle: 'Prescripteurs Liste',
            rule: 'editor',
            authRequired: true
          }
        },


        //******************************************************************** */
        /*Categories Articles*/
        {
          path: '/apps/categorie_article/categorie_article-list',
          name: 'app-categorie_article-list',
          component: () => import('@/views/apps/categorie_article/categorie_article_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'categorie_article' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-categorie_article-list',
            pageTitle: 'Categories Articles List',
            rule: 'editor',
            authRequired: true
          }
        },


        //******************************************************************** */
        /*Professions*/
        {
          path: '/apps/profession/profession-list',
          name: 'app-profession_article-list',
          component: () => import('@/views/apps/profession/profession_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'professions' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-profession_article-list',
            pageTitle: 'Professions List',
            rule: 'editor',
            authRequired: true
          }
        },


        //******************************************************************** */
        /*Partenaires*/
        {
          path: '/apps/partenaire/partenaire-list',
          name: 'app-profession_article-list',
          component: () => import('@/views/apps/partenaire/partenaire_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Partenaires' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-profession_article-list',
            pageTitle: 'Partenaires List',
            rule: 'editor',
            authRequired: true
          }
        },

        //******************************************************************** */
        /*Fournisseurs*/
        {
          path: '/apps/fournisseur/fournisseur-list',
          name: 'app-fournisseur_article-list',
          component: () => import('@/views/apps/fournisseur/fournisseur_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Fournisseur' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-fournisseur_article-list',
            pageTitle: 'Fournisseurs List',
            rule: 'editor',
            authRequired: true
          }
        },

        //******************************************************************** */
        /*Fournisseurs verres*/
        {
          path: '/apps/fournisseurverre/fournisseurverre-list',
          name: 'app-fournisseurverre-list',
          component: () => import('@/views/apps/fournisseurverre/fournisseurverre_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Fournisseurs verres' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-fournisseurverre-list',
            pageTitle: 'Fournisseurs verres List',
            rule: 'editor',
            authRequired: true
          }
        },

        //******************************************************************** */
        /*Marques*/
        {
          path: '/apps/marque/marques-list',
          name: 'app-marque-list',
          component: () => import('@/views/apps/marque/marque_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'marque' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-marque-list',
            pageTitle: 'Marques Liste',
            rule: 'editor',
            authRequired: true
          }
        },


        //******************************************************************** */
        /*Types*/
        {
          path: '/apps/type/type-list',
          name: 'app-type-list',
          component: () => import('@/views/apps/type/type_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Types' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-type-list',
            pageTitle: 'Types Liste',
            rule: 'editor',
            authRequired: true
          }
        },

        //******************************************************************** */
        /*Styles*/
        {
          path: '/apps/style/style-list',
          name: 'app-type-list',
          component: () => import('@/views/apps/style/style_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Styles' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-type-list',
            pageTitle: 'Styles List',
            rule: 'editor',
            authRequired: true
          }
        },

        //******************************************************************** */
        /*Pays*/
        {
          path: '/apps/pays/pays-list',
          name: 'app-pays-list',
          component: () => import('@/views/apps/pays/pays_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Pays' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-pays-list',
            pageTitle: 'Pays Liste',
            rule: 'editor',
            authRequired: true
          }
        },

        //******************************************************************** */
        /*Quartiers*/
        {
          path: '/apps/ville/ville-list',
          name: 'app-ville-list',
          component: () => import('@/views/apps/ville/ville_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Villes' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-ville-list',
            pageTitle: 'Villes Liste',
            rule: 'editor',
            authRequired: true
          }
        },

        //******************************************************************** */
        /*Quartiers*/
        {
          path: '/apps/quartier/quartier-list',
          name: 'app-quartier-list',
          component: () => import('@/views/apps/quartier/quartier_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Quartiers' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-quartier-list',
            pageTitle: 'Quartiers List',
            rule: 'editor',
            authRequired: true
          }
        },


        //******************************************************************** */
        // Gestion de stock
        //******************************************************************** */
        /*Commande fournisseur*/
        {
          path: '/apps/commandefourn/commandefourn-list',
          name: 'app-commandefourn-list',
          component: () => import('@/views/apps/commandefourn/commandefourn_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Bon de réception' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-commandefourn-list',
            pageTitle: 'Bon de réception',
            rule: 'editor',
            authRequired: true
          }
        },
        // =======
        /******************************************************************** */
        /*Utilisateur */

        {
          path: '/apps/user/user-list',
          name: 'app-user-list',
          component: () => import('@/views/apps/user/user-list/UserList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'User' },
              { title: 'List', active: true }
            ],
            parent: 'app-user-list',
            pageTitle: 'User List',
            rule: 'editor'
          }
        },
        {
          path: '/apps/user/user-view/:userId',
          name: 'app-user-view',
          component: () => import('@/views/apps/user/UserView.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'User' },
              { title: 'View', active: true }
            ],
            parent: 'app-user-view',
            pageTitle: 'User View',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/user/user-edit/:userId',
          name: 'app-user-edit',
          component: () => import('@/views/apps/user/user-edit/UserEdit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'User' },
              { title: 'Edit', active: true }
            ],
            parent: 'app-user-edit',
            pageTitle: 'User Edit',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/user/user-add',
          name: 'app-user-add',
          component: () => import('@/views/apps/user/UserAdd.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'User' },
              { title: 'Add', active: true }
            ],
            parent: 'app-user-add',
            pageTitle: 'User Add',
            rule: 'editor',
            authRequired: true
          }
        },

        //******************************************************************** */
        /*Fonctions */
        {
          path: '/apps/fonction/fonction-list',
          name: 'app-fonction-list',
          component: () => import('@/views/apps/fonction/fonction-list/FonctionList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Fonction' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-fonction-list',
            pageTitle: 'Fonction List',
            rule: 'editor',
            authRequired: true
          }
        },

        {
          path: '/apps/fonction/fonction-add',
          name: 'app-fonction-add',
          component: () => import('@/views/apps/fonction/Fonction_add.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Nouvelle Fonction' },
              { title: 'Add', active: true }
            ],
            parent: 'app-fonction-add',
            pageTitle: 'Fonction ajout',
            rule: 'editor',
            authRequired: true
          }
        },


        //******************************************************************** */
        /*Societes */
        {
          path: '/apps/societes/societes-list',
          name: 'app-societes-list',
          component: () => import('@/views/apps/societe/societe_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'societes' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-societes-list',
            pageTitle: 'Societes Liste',
            rule: 'editor',
            authRequired: true
          }
        },

        //******************************************************************** */
        /*Banques */
        {
          path: '/apps/banques/banques-list',
          name: 'app-banques-list',
          component: () => import('@/views/apps/banque/banque_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'banques' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-banques-list',
            pageTitle: 'Liste',
            rule: 'editor',
            authRequired: true
          }
        },

        //******************************************************************** */
        /*Assurances */

        {
          path: '/apps/assurances/assurances-view',
          name: 'app-assurances-view',
          component: () => import('@/views/apps/assurance/assurance_view.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'assurances' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-assurances-view',
            pageTitle: 'Assurances',
            rule: 'editor',
            authRequired: true
          }
        },
        // Add Assurance
        /* {
            path: '/apps/assurances/assurance-add',
            name: 'app-assurances-add',
            component: () => import('@/views/apps/assurance/assurance_add.vue'),
            meta: {
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'assurance add' },
                //{ title: 'List', active: true }
              ],
              parent: 'app-assurances-add',
              pageTitle: 'Ajouter Nouvelle Assurance',
              rule: 'editor'
            }
          },*/

        /*prescripteur*/

        {
          path: '/apps/prescripteur/prescripteur-list',
          name: 'app-prescripteur-list',
          component: () => import('@/views/apps/prescripteur/prescripteur_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Prescripteurs' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-prescripteur-list',
            pageTitle: 'Prescripteurs Liste',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/prescripteur/prescripteur-add',
          name: 'app-prescripteur-add',
          component: () => import('@/views/apps/prescripteur/prescripteur_add.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Prescripteur' },
              { title: 'Ajout', active: true }
            ],
            parent: 'app-prescripteur-list',
            pageTitle: 'Ajout de Prescripteur',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/prescripteur/prescripteur-view',
          name: 'app-prescripteur-view',
          component: () => import('@/views/apps/prescripteur/prescripteur_view.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Centres hospitaliers' },
              { title: 'View', active: true }
            ],
            parent: 'app-prescripteur-list',
            pageTitle: 'Informations Centre Hospitalier',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/prescripteur/prescripteur-edit',
          name: 'app-prescripteur-edit',
          component: () => import('@/views/apps/prescripteur/prescripteur_edit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Centres hospitaliers' },
              { title: 'Modif', active: true }
            ],
            parent: 'app-prescripteur-modif',
            pageTitle: 'Modification Centre Hospitalier',
            rule: 'editor',
            authRequired: true
          }
        },


        //******************************************************************** */
        /*Categories Articles*/
        {
          path: '/apps/categorie_article/categorie_article-list',
          name: 'app-categorie_article-list',
          component: () => import('@/views/apps/categorie_article/categorie_article_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'categorie_article' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-categorie_article-list',
            pageTitle: 'Categories Articles List',
            rule: 'editor',
            authRequired: true
          }
        },


        //******************************************************************** */
        /*Professions*/
        {
          path: '/apps/profession/profession-view',
          name: 'app-profession_view',
          component: () => import('@/views/apps/profession/profession_view.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'professions' },
              { title: 'view', active: true }
            ],
            parent: 'app-profession_view',
            pageTitle: 'Professions',
            rule: 'editor',
            authRequired: true
          }
        },


        //******************************************************************** */
        /*Partenaires*/
        {
          path: '/apps/partenaire/partenaire-list',
          name: 'app-profession_article-list',
          component: () => import('@/views/apps/partenaire/partenaire_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Partenaires' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-profession_article-list',
            pageTitle: 'Partenaires List',
            rule: 'editor',
            authRequired: true
          }
        },

        //******************************************************************** */
        /*Fournisseurs*/
        {
          path: '/apps/fournisseur/fournisseur-view',
          name: 'app-fournisseur_view',
          component: () => import('@/views/apps/fournisseur/fournisseur_view.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Fournisseurs', active: true}
            ],
            parent: 'app-fournisseur_view',
            rule: 'editor',
            authRequired: true
          }
        },

        {
          path: '/apps/fournisseur/edit/:fournisseurId',
          name: 'app-fournisseur-edit',
          component: () => import('@/views/apps/fournisseur/fournisseur_edit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Fournisseurs', url: '/apps/fournisseur/fournisseur-view' },
              { title: 'Edit', active: true }
            ],
            parent: 'app-fournisseur_view',
            rule: 'editor',
            authRequired: true
          }
        },

        //******************************************************************** */
        /*Fournisseurs verres*/
        {
          path: '/apps/fournisseurverre/fournisseurverre-list',
          name: 'app-fournisseurverre-list',
          component: () => import('@/views/apps/fournisseurverre/fournisseurverre_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Fournisseurs verres' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-fournisseurverre-list',
            rule: 'editor',
            authRequired: true
          }
        },

        //******************************************************************** */
        /*Marques*/
        {
          path: '/apps/marque/marque-list',
          name: 'app-marque-list',
          component: () => import('@/views/apps/marque/marque_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'marque' },
              { title: 'List', active: true }
            ],
            parent: 'app-marque-list',
            pageTitle: 'Marques Liste',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/marque/marque-add',
          name: 'app-marque-add',
          component: () => import('@/views/apps/marque/marque_add.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'marque' },
              { title: 'Ajout', active: true }
            ],
            parent: 'app-marque-list',
            pageTitle: 'Ajout de marque',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/marque/marque-view',
          name: 'app-marque-view',
          component: () => import('@/views/apps/marque/marque_view.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Centres hospitaliers' },
              { title: 'View', active: true }
            ],
            parent: 'app-marque-list',
            pageTitle: 'Informations Centre Hospitalier',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/marque/marque-edit',
          name: 'app-marque-edit',
          component: () => import('@/views/apps/marque/marque_edit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Marques' },
              { title: 'Modif', active: true }
            ],
            parent: 'app-marque-modif',
            pageTitle: 'Modification Marque',
            rule: 'editor',
            authRequired: true
          }
        },

        //******************************************************************** */
        /*Types*/
        {
          path: '/apps/type/type-list',
          name: 'app-type-list',
          component: () => import('@/views/apps/type/type_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Types' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-type-list',
            pageTitle: 'Types List',
            rule: 'editor',
            authRequired: true
          }
        },

        //******************************************************************** */
        /*Styles*/
        {
          path: '/apps/style/style-list',
          name: 'app-type-list',
          component: () => import('@/views/apps/style/style_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Styles' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-type-list',
            pageTitle: 'Styles List',
            rule: 'editor',
            authRequired: true
          }
        },

        //******************************************************************** */
        /*Pays*/
        {
          path: '/apps/pays/pays-list',
          name: 'app-pays-list',
          component: () => import('@/views/apps/pays/pays_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Pays' },
              { title: 'List', active: true }
            ],
            parent: 'app-pays-list',
            pageTitle: 'Pays Liste',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/pays/pays-add',
          name: 'app-pays-add',
          component: () => import('@/views/apps/pays/pays_add.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'pays' },
              { title: 'Ajout', active: true }
            ],
            parent: 'app-pays-list',
            pageTitle: 'Ajout de pays',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/pays/pays-view',
          name: 'app-pays-view',
          component: () => import('@/views/apps/pays/pays_view.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Pays' },
              { title: 'View', active: true }
            ],
            parent: 'app-pays-list',
            pageTitle: 'Informations Pays',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/pays/pays-edit',
          name: 'app-pays-edit',
          component: () => import('@/views/apps/pays/pays_edit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Centres hospitaliers' },
              { title: 'Modif', active: true }
            ],
            parent: 'app-pays-modif',
            pageTitle: 'Modification Centre Hospitalier',
            rule: 'editor',
            authRequired: true
          }
        },
        //******************************************************************** */
        /*Quartiers*/
        {
          path: '/apps/ville/ville-list',
          name: 'app-ville-list',
          component: () => import('@/views/apps/ville/ville_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Villes' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-ville-list',
            pageTitle: 'Villes List',
            rule: 'editor',
            authRequired: true
          }
        },

        //******************************************************************** */
        /*Quartiers*/
        {
          path: '/apps/quartier/quartier-list',
          name: 'app-quartier-list',
          component: () => import('@/views/apps/quartier/quartier_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Quartiers' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-quartier-list',
            pageTitle: 'Quartiers List',
            rule: 'editor',
            authRequired: true
          }
        },

        //******************************************************************** */
        /*Rôle*/
        {
          path: '/apps/roles',
          name: 'Roles',
          component: () => import('@/views/apps/role/role_liste.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Rôle' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-role-list',
            pageTitle: 'Rôles List',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/role/role-add',
          name: 'role_add',
          component: () => import('@/views/apps/role/role_add.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Rôle', url: '/apps/roles' },
              { title: 'Nouveau', active: true }
            ],
            parent: 'app-role-list',
            pageTitle: 'Nouveau',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/role/role-edit/:roleId',
          name: 'role_edit',
          component: () => import('@/views/apps/role/role_edit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Rôle', url: '/apps/roles' },
              { title: 'Modifier', active: true }
            ],
            parent: 'app-role-list',
            pageTitle: 'Modifier',
            rule: 'editor',
            authRequired: true
          }
        },

        //***************************************************************************/
        /** Rubriques depenses */
        {
          path: '/apps/rubrique-depense',
          name: 'rubrique_depense',
          component: () => import('@/views/apps/rubrique_depense/rubrique_depense.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Rubriques dépense', active: true }
            ],
            parent: 'rubrique-depense',
            pageTitle: 'Rubriques dépense',
            rule: 'editor',
            authRequired: true
          }
        },

        //******************************************************************** */
        // Gestion de stock
        //******************************************************************** */
        /*Commande fournisseur*/
        {
          path: '/apps/commandefourn/commandefourn-list',
          name: 'app-commandefourn-list',
          component: () => import('@/views/apps/commandefourn/commandefourn_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Bon de réception' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-commandefourn-list',
            pageTitle: 'Bon de réception',
            rule: 'editor',
            authRequired: true
          }
        },


        //******************************************************************** */
        /*Enrégistrement facture*/


        //******************************************************************** */
        /*Enrégistrement facture*/


        //******************************************************************** */
        // Exploitation'
        //******************************************************************** */

        /*Clients*/


        {
          path: '/apps/client/client-list',
          name: 'app-client-list',
          component: () => import('@/views/apps/client/client_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'clients' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-client-list',
            pageTitle: 'Clients Liste',
            rule: 'editor',
            authRequired: true
          }
        },

        //******************************************************************** */
        /*Ordonnances*/
        {
          path: '/apps/ordonnance/ordonnance-list',
          name: 'app-ordonnance-list',
          component: () => import('@/views/apps/ordonnance/ordonnance_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'ordonnances' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-ordonnance-list',
            pageTitle: 'Ordonnances List',
            rule: 'editor'
          }
        },

        //******************************************************************** */
        /*Proformas clients*/
        {
          path: '/apps/proformaclient/proformaclient-list',
          name: 'app-proformaclient-list',
          component: () => import('@/views/apps/proformaclient/proformaclient_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Proformas Clients' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-proformaclient-list',
            pageTitle: 'Proformas List',
            rule: 'editor',
            authRequired: true
          }
        },

        //******************************************************************** */
        /*Commandes clients*/
        {
          path: '/apps/commandeclient/commandeclient-list',
          name: 'app-commandeclient-list',
          component: () => import('@/views/apps/commandeclient/commandeclient_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Commandes clients' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-commandeclient-list',
            pageTitle: 'Commandes clients List',
            rule: 'editor',
            authRequired: true
          }
        },

        //******************************************************************** */
        /*Commandes comptoires*/
        {
          path: '/apps/commandecomptoir/commandecomptoir-list',
          name: 'app-commandecomptoir-list',
          component: () => import('@/views/apps/commandecomptoir/commandecomptoir_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Commandes comptoires' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-commandecomptoir-list',
            pageTitle: 'Commandes comptoires List',
            rule: 'editor',
            authRequired: true
          }
        },

        //******************************************************************** */
        // Caisse
        //******************************************************************** */

        /*Fond de caisse*/

        {
          path: '/apps/fonddecaisse/fonddecaisse-list',
          name: 'app-fonddecaisse-list',
          component: () => import('@/views/apps/fonddecaisse/fonddecaisse_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Fond de caisse' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-fonddecaisse-list',
            pageTitle: 'Fond de caisse List',
            rule: 'editor',
            authRequired: true
          }
        },

        //******************************************************************** */

        /*Appro. caisse Recettes*/

        {
          path: '/apps/appro_caisse_recettes/appro_caisse_recettes-list',
          name: 'app-appro_caisse_recettes-list',
          component: () => import('@/views/apps/appro_caisse_recettes/appro_caisse_recettes_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Appro. caisse Recettes' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-appro_caisse_recettes-list',
            pageTitle: 'Appro. caisse Recettes List',
            rule: 'editor',
            authRequired: true
          }
        },

        //******************************************************************** */

        /*Appro. caisse dépenses*/

        {
          path: '/apps/appro_depense/appro_depense-list',
          name: 'app-appro_depense-list',
          component: () => import('@/views/apps/appro_depense/appro_depense_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Appro. caisse dépenses' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-appro_depense-list',
            pageTitle: 'Appro. caisse dépenses List',
            rule: 'editor',
            authRequired: true
          }
        },

        //******************************************************************** */

        /*Réglements clients cmdes*/

        {
          path: '/apps/reglement_cmdecli/reglement_cmdecli-list',
          name: 'app-reglement_cmdecli-list',
          component: () => import('@/views/apps/reglement_cmdecli/reglement_cmdecli_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Réglements clients cmdes' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-reglement_cmdecli-list',
            pageTitle: 'Réglements clients cmdes List',
            rule: 'editor',
            authRequired: true
          }
        },

        //******************************************************************** */

        /*Réglements clients comptoire*/

        {
          path: '/apps/reglement_cmd_cptoire/reglement_cmd_cptoire-list',
          name: 'app-reglement_cmd_cptoire-list',
          component: () => import('@/views/apps/reglement_cmd_cptoire/reglement_cmd_cptoire_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Réglements comptoires' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-reglement_cmd_cptoire-list',
            pageTitle: 'Réglements comptoires List',
            rule: 'editor',
            authRequired: true
          }
        },

        //******************************************************************** */

        /*Réglements clients cmdes*/

        {
          path: '/apps/versement_banque/versement_banque-list',
          name: 'app-versement_banque-list',
          component: () => import('@/views/apps/versement_banque/versement_banque_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Versements Banque' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-versement_banque-list',
            pageTitle: 'Versements Banque List',
            rule: 'editor',
            authRequired: true
          }
        },


        //******************************************************************** */

        /*Billetages*/

        {
          path: '/apps/billetage/billetage-list',
          name: 'app-billetage-list',
          component: () => import('@/views/apps/billetage/billetage_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Billetages' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-billetage-list',
            pageTitle: 'Billetages List',
            rule: 'editor',
            authRequired: true
            //no_scroll: true // add this
          }
        },

        //******************************************************************** */

        /* Caisse Dépenses */

        {
          path: '/apps/depense/depense-list',
          name: 'app-depense-list',
          component: () => import('@/views/apps/depense/depense_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Caisse Dépenses' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-depense-list',
            pageTitle: 'Caisse Dépenses List',
            rule: 'editor',
            authRequired: true
          }
        },
        // {
        //   path: '/apps/fonction/fonction-add',
        //   name: 'app-fonction-add',
        //   component: () => import('@/views/apps/fonction/FonctionAdd.vue'),
        //   meta: {
        //     breadcrumb: [
        //       { title: 'Home', url: '/' },
        //       { title: 'Fonction' },
        //       { title: 'Add', active: true }
        //     ],
        //     pageTitle: 'Fonction Add',
        //     rule: 'editor'
        //   }
        // },

        {
          path: '/apps/client/client-list',
          name: 'app-client-list',
          component: () => import('@/views/apps/client/client_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'clients' },
              { title: 'Liste', active: true }
            ],
            parent: 'app-client-list',
            pageTitle: 'Clients Liste',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/client/client-add',
          name: 'app-client-add',
          component: () => import('@/views/apps/client/client_add.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Client', url: '/apps/client/client-list' },
              { title: 'Nouveau', active: true }
            ],
            parent: 'app-client-list',
            pageTitle: 'Nouveau',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/client/client-view',
          name: 'app-client-view',
          component: () => import('@/views/apps/client/client_view.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Client' },
              { title: 'View', active: true }
            ],
            parent: 'app-client-list',
            pageTitle: 'Dossier Client',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/client/client-edit',
          name: 'app-client-edit',
          component: () => import('@/views/apps/client/client_edit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Centres hospitaliers' },
              { title: 'Modif', active: true }
            ],
            parent: 'app-pays-modif',
            pageTitle: 'Modification Centre Hospitalier',
            rule: 'editor',
            authRequired: true
          }
        },
        //******************************************************************** */
        /*Ordonnances*/
        {
          path: '/apps/ordonnance/ordonnance-list',
          name: 'app-ordonnance-list',
          component: () => import('@/views/apps/ordonnance/ordonnance_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'ordonnances' },
              { title: 'List', active: true }
            ],
            parent: 'app-ordonnance-list',
            pageTitle: 'Ordonnances List',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/ordonnance/ordonnance-add',
          name: 'app-ordonnance-add',
          component: () => import('@/views/apps/ordonnance/ordonnance_add.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'ordonnance' },
              { title: 'Ajout', active: true }
            ],
            parent: 'app-ordonnance-list',
            pageTitle: 'Ajout de ordonnance',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/ordonnance/ordonnance-view',
          name: 'app-ordonnance-view',
          component: () => import('@/views/apps/ordonnance/ordonnance_view.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'ordonnance' },
              { title: 'View', active: true }
            ],
            parent: 'app-ordonnance-list',
            pageTitle: 'Informations ordonnance',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/ordonnance/ordonnance-edit',
          name: 'app-ordonnance-edit',
          component: () => import('@/views/apps/ordonnance/ordonnance_edit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Centres hospitaliers' },
              { title: 'Modif', active: true }
            ],
            parent: 'app-pays-modif',
            pageTitle: 'Modification Centre Hospitalier',
            rule: 'editor',
            authRequired: true
          }
        },
        //******************************************************************** */
        /*Proformas clients*/
        {
          path: '/apps/proformaclient/proformaclient-list',
          name: 'app-proformaclient-list',
          component: () => import('@/views/apps/proformaclient/proformaclient_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Proformas Clients' },
              { title: 'List', active: true }
            ],
            parent: 'app-proformaclient-list',
            pageTitle: 'Proformas List',
            rule: 'editor',
            authRequired: true
          }
        },

        //******************************************************************** */
        /*Commandes clients*/
        {
          path: '/apps/commandeclient/commandeclient-list',
          name: 'app-commandeclient-list',
          component: () => import('@/views/apps/commandeclient/commandeclient_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Commandes clients' },
              { title: 'List', active: true }
            ],
            parent: 'app-commandeclient-list',
            pageTitle: 'Commandes clients List',
            rule: 'editor',
            authRequired: true
          }
        },

        //******************************************************************** */
        /*Commandes comptoires*/
        {
          path: '/apps/commandecomptoir/commandecomptoir-list',
          name: 'app-commandecomptoir-list',
          component: () => import('@/views/apps/commandecomptoir/commandecomptoir_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Commandes comptoires' },
              { title: 'List', active: true }
            ],
            parent: 'app-commandecomptoir-list',
            pageTitle: 'Commandes comptoires List',
            rule: 'editor',
            authRequired: true
          }
        },

        //******************************************************************** */
        // Caisse
        //******************************************************************** */

        /*Fond de caisse*/

        {
          path: '/apps/fonddecaisse/fonddecaisse-list',
          name: 'app-fonddecaisse-list',
          component: () => import('@/views/apps/fonddecaisse/fonddecaisse_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Fond de caisse' },
              { title: 'List', active: true }
            ],
            parent: 'app-fonddecaisse-list',
            pageTitle: 'Fond de caisse List',
            rule: 'editor',
            authRequired: true
          }
        },

        //******************************************************************** */

        /*Appro. caisse Recettes*/

        {
          path: '/apps/appro_caisse_recettes/appro_caisse_recettes-list',
          name: 'app-appro_caisse_recettes-list',
          component: () => import('@/views/apps/appro_caisse_recettes/appro_caisse_recettes_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Appro. caisse Recettes' },
              { title: 'List', active: true }
            ],
            parent: 'app-appro_caisse_recettes-list',
            pageTitle: 'Appro. caisse Recettes List',
            rule: 'editor',
            authRequired: true
          }
        },

        //******************************************************************** */

        /*Appro. caisse dépenses*/

        {
          path: '/apps/appro_depense/appro_depense-view',
          name: 'app-appro_depense-view',
          component: () => import('@/views/apps/appro_depense/appro_depense_view.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Appro. caisse dépenses' },
              { title: 'View', active: true }
            ],
            parent: 'app-appro_depense-view',
            pageTitle: 'Appro. caisse dépenses View',
            rule: 'editor',
            authRequired: true
          }
        },

        //******************************************************************** */

        /*Réglements clients cmdes*/

        {
          path: '/apps/reglement_cmdecli/reglement_cmdecli-list',
          name: 'app-reglement_cmdecli-list',
          component: () => import('@/views/apps/reglement_cmdecli/reglement_cmdecli_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Réglements clients cmdes' },
              { title: 'List', active: true }
            ],
            parent: 'app-reglement_cmdecli-list',
            pageTitle: 'Réglements clients cmdes List',
            rule: 'editor',
            authRequired: true
          }
        },

        //******************************************************************** */

        /*Réglements clients comptoire*/

        {
          path: '/apps/reglement_cmd_cptoire/reglement_cmd_cptoire-list',
          name: 'app-reglement_cmd_cptoire-list',
          component: () => import('@/views/apps/reglement_cmd_cptoire/reglement_cmd_cptoire_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Réglements comptoires' },
              { title: 'List', active: true }
            ],
            parent: 'app-reglement_cmd_cptoire-list',
            pageTitle: 'Réglements comptoires List',
            rule: 'editor',
            authRequired: true
          }
        },

        //******************************************************************** */

        /*Réglements clients cmdes*/

        {
          path: '/apps/versement_banque/versement_banque-view',
          name: 'app-versement_banque-list',
          component: () => import('@/views/apps/versement_banque/versement_banque_view.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Versements Banque' },
              { title: 'View', active: true }
            ],
            parent: 'app-versement_banque-view',
            pageTitle: 'Versements Banque View',
            rule: 'editor',
            authRequired: true
          }
        },


        //******************************************************************** */

        /*Billetages*/

        {
          path: '/apps/billetage/billetage-list',
          name: 'app-billetage-list',
          component: () => import('@/views/apps/billetage/billetage_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Billetages' },
              { title: 'List', active: true }
            ],
            parent: 'app-billetage-list',
            pageTitle: 'Billetages List',
            rule: 'editor',
            authRequired: true
            //no_scroll: true // add this
          }
        },
        {
          path: '/apps/billetage/billetage-add',
          name: 'app-billetage-add',
          component: () => import('@/views/apps/billetage/billetage_add.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'billetage' },
              { title: 'Ajout', active: true }
            ],
            parent: 'app-billetage-list',
            pageTitle: 'Ajout de billetage',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/billetage/billetage-view',
          name: 'app-billetage-view',
          component: () => import('@/views/apps/billetage/billetage_view.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'billetage' },
              { title: 'View', active: true }
            ],
            parent: 'app-billetage-list',
            pageTitle: 'Informations billetage',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/billetage/billetage-edit',
          name: 'app-billetage-edit',
          component: () => import('@/views/apps/billetage/billetage_edit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Billetage' },
              { title: 'Modif', active: true }
            ],
            parent: 'app-pays-modif',
            pageTitle: 'Modification Billetage',
            rule: 'editor',
            authRequired: true
          }
        },
        //******************************************************************** */

        /* Caisse Dépenses */

        {
          path: '/apps/depense/depense-list',
          name: 'app-depense-list',
          component: () => import('@/views/apps/depense/depense_list.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Caisse Dépenses' },
              { title: 'List', active: true }
            ],
            parent: 'app-depense-list',
            pageTitle: 'Caisse Dépenses List',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/depense/depense-add',
          name: 'app-depense-add',
          component: () => import('@/views/apps/depense/depense_add.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'depense' },
              { title: 'Ajout', active: true }
            ],
            parent: 'app-depense-list',
            pageTitle: 'Ajout de depense',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/depense/depense-view',
          name: 'app-depense-view',
          component: () => import('@/views/apps/depense/depense_view.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'depense' },
              { title: 'View', active: true }
            ],
            parent: 'app-depense-list',
            pageTitle: 'Informations depense',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/depense/depense-edit',
          name: 'app-depense-edit',
          component: () => import('@/views/apps/depense/depense_edit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'depense' },
              { title: 'Modif', active: true }
            ],
            parent: 'app-pays-modif',
            pageTitle: 'Modification depense',
            rule: 'editor',
            authRequired: true
          }
        },

        // =============================================================================
        // FACTURATION
        // =============================================================================

        //******************************************************************** */

        /* Traitement facture */

        {
          path: '/facturation/vente',
          name: 'Ventes agence',
          component: () => import('@/views/apps/facturation/traitement_facture.vue'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/dashboard'},
              {title: 'ventes', active: true}
            ],
            parent: 'facturation',
            pageTitle: 'Ventes',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/model-facture',
          name: 'Facturation',
          component: () => import('@/views/apps/facturation/modele_facture.vue'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/dashboard'},
              {title: 'edition facture', active: true}
            ],
            parent: 'facturation',
            pageTitle: 'Facturation',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/facture-a-deposer',
          name: 'Factures Assurance',
          component: () => import('@/views/apps/facturation/facture_depot.vue'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/dashboard'},
              {title: 'factures', active: true}
            ],
            parent: 'facturation',
            pageTitle: 'Factures assurance',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/reglement-fact',
          name: 'Creance Assurance',
          component: () => import('@/views/apps/facturation/regl_facture_assurance.vue'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/dashboard'},
              {title: 'factures', active: true}
            ],
            parent: 'facturation',
            pageTitle: 'Creances assurance',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/traitement-facture/detail/:clientId/:RefCmmd',
          name: 'traitement-facture-detail',
          component: () => import('@/views/apps/facturation/commande_a_traiter_detail.vue'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/dashboard'},
              {title: 'Traitement commande', url: '/facturation/vente'},
              {title: 'Détail', active: true}
            ],
            parent: 'facturation',
            pageTitle: 'Détail',
            rule: 'editor',
            authRequired: true
          }
        },


        // =============================================================================
        // SMS MARKETING
        // =============================================================================

        //******************************************************************** */

        /* Campagne sms */

        {
          path: '/apps/sms-marketing/compte',
          name: 'compte',
          component: () => import('@/views/apps/sms_marketing/compte.vue'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/dashboard'},
              {title: 'Compte sms', active: true}
            ],
            parent: 'campagne-sms',
            pageTitle: 'Compte sms',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/sms-marketing/repertoire',
          name: 'repertoire',
          component: () => import('@/views/apps/sms_marketing/repertoire.vue'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/dashboard'},
              {title: 'Répertoire', active: true}
            ],
            parent: 'campagne-sms',
            pageTitle: 'Répertoire',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/sms-marketing/campagne-sms',
          name: 'campagne-sms',
          component: () => import('@/views/apps/sms_marketing/campagne_sms.vue'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/dashboard'},
              {title: 'campagne sms', active: true}
            ],
            parent: 'campagne-sms',
            pageTitle: 'Campagnes sms',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/sms-marketing/create-diffusion',
          name: 'create-diffusion',
          component: () => import('@/views/apps/sms_marketing/create_diffusion.vue'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/dashboard'},
              {title: 'campagnes', url: '/apps/sms-marketing/campagne-sms'},
              {title: 'creer une diffusion', active: true}
            ],
            parent: 'campagne-sms',
            pageTitle: 'Creer une Diffusion',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/sms-marketing/edit-diffusion/:idDiffusion',
          name: 'edit-diffusion',
          component: () => import('@/views/apps/sms_marketing/edit_diffusion.vue'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/dashboard'},
              {title: 'campagnes', url: '/apps/sms-marketing/campagne-sms'},
              {title: 'modifier une diffusion', active: true}
            ],
            parent: 'campagne-sms',
            pageTitle: 'Modifier une diffusion',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/sms-marketing/anniv-client',
          name: 'anniv-client',
          component: () => import('@/views/apps/sms_marketing/anniv_client.vue'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/dashboard'},
              {title: 'Anniversaire client', active: true}
            ],
            parent: 'campagne-sms',
            pageTitle: 'Anniversaire client',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/sms-marketing/anniv-commande',
          name: 'anniv-commande',
          component: () => import('@/views/apps/sms_marketing/anniv_commande.vue'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/dashboard'},
              {title: 'Anniversaire commande', active: true}
            ],
            parent: 'campagne-sms',
            pageTitle: 'Anniversaire de commande',
            rule: 'editor',
            authRequired: true
          }
        },

        // =============================================================================
        // EDITION & STAT
        // =============================================================================

        {
          path: '/apps/edition-stat/br',
          name: 'BR',
          component: () => import('@/views/apps/etats/br/br.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Etat', active: true }
            ],
            parent: 'app-br',
            pageTitle: 'BR',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/edition-stat/stock-article',
          name: 'Stock-article',
          component: () => import('@/views/apps/etats/stock/stock_article.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Etat', active: true }
            ],
            parent: 'app-stock-article',
            pageTitle: 'Stock article',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/edition-stat/article-sortie',
          name: 'Article-sortie',
          component: () => import('@/views/apps/etats/stock/article_sortie.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Etat', active: true }
            ],
            parent: 'app-article-sortie',
            pageTitle: 'Article sortie',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/edition-stat/liste-proforma',
          name: 'liste-proforma',
          component: () => import('@/views/apps/etats/proforma/proforma.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Etat', active: true }
            ],
            parent: 'app-proforma',
            pageTitle: 'Proforma',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/edition-stat/liste-commande',
          name: 'liste-commande',
          component: () => import('@/views/apps/etats/commande/liste_commande.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Etat', active: true }
            ],
            parent: 'app-commande',
            pageTitle: 'Liste commande',
            rule: 'editor',
            authRequired: true
          }
        },

        {
          path: '/apps/edition-stat/suivi-regl',
          name: 'suivi-regl',
          component: () => import('@/views/apps/etats/reglement/suivi_reglement.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Etat', active: true }
            ],
            parent: 'app-reglement_cmdecli-list',
            pageTitle: 'Etat suivi règlement',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/edition-stat/suivi-regl',
          name: 'suivi-regl',
          component: () => import('@/views/apps/etats/reglement/suivi_reglement.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Etat', active: true }
            ],
            parent: 'app-reglement_cmdecli-list',
            pageTitle: 'Etat suivi règlement',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/edition-stat/suivi-cmmd',
          name: 'suivi-commande',
          component: () => import('@/views/apps/etats/commande/suivi_commande.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Etat', active: true }
            ],
            parent: 'app-cmdecli-list',
            pageTitle: 'Suivi commande',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/edition-stat/facturation-traiter',
          name: 'facturation-traiter',
          component: () => import('@/views/apps/etats/facturation/facturation_traiter.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Etat', active: true }
            ],
            parent: 'app-cmdecli-list',
            pageTitle: 'Facture Traitée',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/edition-stat/caisse-recette',
          name: 'Recette',
          component: () => import('@/views/apps/etats/caisse/caisse.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Etat', active: true }
            ],
            parent: 'app-caisse',
            pageTitle: 'Caisse Recette',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/edition-stat/caisse-depense',
          name: 'Depense',
          component: () => import('@/views/apps/etats/depense/depense.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Etat', active: true }
            ],
            parent: 'app-caisse',
            pageTitle: 'Caisse Depense',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/edition-stat/ca',
          name: 'Ca',
          component: () => import('@/views/apps/etats/ca/ca.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'ca', active: true }
            ],
            parent: 'app-ca',
            pageTitle: 'Chiffre d\'affaires',
            rule: 'editor',
            authRequired: true
          }
        },

        {
          path: '/apps/edition-stat/statistiques',
          name: 'Statistiques',
          component: () => import('@/views/apps/etats/statistique/stat.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Statistiques', active: true }
            ],
            parent: 'app-stat',
            pageTitle: 'Statistiques',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/apps/calendar',
          name: 'calendar-simple-calendar',
          component: () => import('@/views/apps/calendar/SimpleCalendar.vue'),
          meta: {
            parent: 'app-stat',
            rule: 'editor',
            no_scroll: true,
            authRequired: true
          }
        },
        {
          path: '/apps/configurations',
          name: 'app-configuration',
          component: () => import('@/views/apps/configs/appConfig.vue'),
          meta: {
            parent: 'app-config',
            rule: 'editor',
            authRequired: true
          }
        },
        // >>>>>>> main
        // =============================================================================
        // UI ELEMENTS
        // =============================================================================
        // {
        //   path: '/ui-elements/data-list/list-view',
        //   name: 'data-list-list-view',
        //   component: () => import('@/views/ui-elements/data-list/list-view/DataListListView.vue'),
        //   meta: {
        //     breadcrumb: [
        //       { title: 'Home', url: '/' },
        //       { title: 'Data List'},
        //       { title: 'List View', active: true }
        //     ],
        //     pageTitle: 'List View',
        //     rule: 'editor'
        //   }
        // },
        // {
        //   path: '/ui-elements/data-list/thumb-view',
        //   name: 'data-list-thumb-view',
        //   component: () => import('@/views/ui-elements/data-list/thumb-view/DataListThumbView.vue'),
        //   meta: {
        //     breadcrumb: [
        //       { title: 'Home', url: '/' },
        //       { title: 'Data List'},
        //       { title: 'Thumb View', active: true }
        //     ],
        //     pageTitle: 'Thumb View',
        //     rule: 'editor'
        //   }
        // },
        // {
        //   path: '/ui-elements/grid/vuesax',
        //   name: 'grid-vuesax',
        //   component: () => import('@/views/ui-elements/grid/vuesax/GridVuesax.vue'),
        //   meta: {
        //     breadcrumb: [
        //       { title: 'Home', url: '/' },
        //       { title: 'Grid'},
        //       { title: 'Vuesax', active: true }
        //     ],
        //     pageTitle: 'Grid',
        //     rule: 'editor'
        //   }
        // },
        // {
        //   path: '/ui-elements/grid/tailwind',
        //   name: 'grid-tailwind',
        //   component: () => import('@/views/ui-elements/grid/tailwind/GridTailwind.vue'),
        //   meta: {
        //     breadcrumb: [
        //       { title: 'Home', url: '/' },
        //       { title: 'Grid'},
        //       { title: 'Tailwind', active: true }
        //     ],
        //     pageTitle: 'Tailwind Grid',
        //     rule: 'editor'
        //   }
        // },
        // {
        //   path: '/ui-elements/colors',
        //   name: 'colors',
        //   component: () => import('./views/ui-elements/colors/Colors.vue'),
        //   meta: {
        //     breadcrumb: [
        //       { title: 'Home', url: '/' },
        //       { title: 'Colors', active: true }
        //     ],
        //     pageTitle: 'Colors',
        //     rule: 'editor'
        //   }
        // },
        // {
        //   path: '/ui-elements/card/basic',
        //   name: 'basic-cards',
        //   component: () => import('./views/ui-elements/card/CardBasic.vue'),
        //   meta: {
        //     breadcrumb: [
        //       { title: 'Home', url: '/' },
        //       { title: 'Card' },
        //       { title: 'Basic Cards', active: true }
        //     ],
        //     pageTitle: 'Basic Cards',
        //     rule: 'editor'
        //   }
        // },
        {
          path: '/ui-elements/card/statistics',
          name: 'statistics-cards',
          component: () => import('./views/ui-elements/card/CardStatistics.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Card' },
              { title: 'Statistics Cards', active: true }
            ],
            pageTitle: 'Statistics Card',
            rule: 'editor'
          }
        },
        {
          path: '/ui-elements/card/analytics',
          name: 'analytics-cards',
          component: () => import('./views/ui-elements/card/CardAnalytics.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Card' },
              { title: 'Analytics Card', active: true }
            ],
            pageTitle: 'Analytics Card',
            rule: 'editor'
          }
        },
        // {
        //   path: '/ui-elements/card/card-actions',
        //   name: 'card-actions',
        //   component: () => import('./views/ui-elements/card/CardActions.vue'),
        //   meta: {
        //     breadcrumb: [
        //       { title: 'Home', url: '/' },
        //       { title: 'Card' },
        //       { title: 'Card Actions', active: true }
        //     ],
        //     pageTitle: 'Card Actions',
        //     rule: 'editor'
        //   }
        // },
        // {
        //   path: '/ui-elements/card/card-colors',
        //   name: 'card-colors',
        //   component: () => import('./views/ui-elements/card/CardColors.vue'),
        //   meta: {
        //     breadcrumb: [
        //       { title: 'Home', url: '/' },
        //       { title: 'Card' },
        //       { title: 'Card Colors', active: true }
        //     ],
        //     pageTitle: 'Card Colors',
        //     rule: 'editor'
        //   }
        // },
        {
          path: '/ui-elements/table',
          name: 'table',
          component: () => import('./views/ui-elements/table/Table.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Table', active: true }
            ],
            pageTitle: 'Table',
            rule: 'editor'
          }
        },
        // {
        //   path: '/ui-elements/ag-grid-table',
        //   name: 'ag-grid-table',
        //   component: () => import('./views/ui-elements/ag-grid-table/AgGridTable.vue'),
        //   meta: {
        //     breadcrumb: [
        //       { title: 'Home', url: '/' },
        //       { title: 'agGrid Table', active: true }
        //     ],
        //     pageTitle: 'agGrid Table',
        //     rule: 'editor'
        //   }
        // },

        // =============================================================================
        // COMPONENT ROUTES
        // =============================================================================
        // {
        //   path: '/components/alert',
        //   name: 'component-alert',
        //   component: () => import('@/views/components/vuesax/alert/Alert.vue'),
        //   meta: {
        //     breadcrumb: [
        //       { title: 'Home', url: '/' },
        //       { title: 'Components' },
        //       { title: 'Alert', active: true }
        //     ],
        //     pageTitle: 'Alert',
        //     rule: 'editor'
        //   }
        // },
        // {
        //   path: '/components/avatar',
        //   name: 'component-avatar',
        //   component: () => import('@/views/components/vuesax/avatar/Avatar.vue'),
        //   meta: {
        //     breadcrumb: [
        //       { title: 'Home', url: '/' },
        //       { title: 'Components' },
        //       { title: 'Avatar', active: true }
        //     ],
        //     pageTitle: 'Avatar',
        //     rule: 'editor'
        //   }
        // },
        // {
        //   path: '/components/breadcrumb',
        //   name: 'component-breadcrumb',
        //   component: () => import('@/views/components/vuesax/breadcrumb/Breadcrumb.vue'),
        //   meta: {
        //     breadcrumb: [
        //       { title: 'Home', url: '/' },
        //       { title: 'Components' },
        //       { title: 'Breadcrumb', active: true }
        //     ],
        //     pageTitle: 'Breadcrumb',
        //     rule: 'editor'
        //   }
        // },
        // {
        //   path: '/components/button',
        //   name: 'component-button',
        //   component: () => import('@/views/components/vuesax/button/Button.vue'),
        //   meta: {
        //     breadcrumb: [
        //       { title: 'Home', url: '/' },
        //       { title: 'Components' },
        //       { title: 'Button', active: true }
        //     ],
        //     pageTitle: 'Button',
        //     rule: 'editor'
        //   }
        // },
        // {
        //   path: '/components/button-group',
        //   name: 'component-button-group',
        //   component: () => import('@/views/components/vuesax/button-group/ButtonGroup.vue'),
        //   meta: {
        //     breadcrumb: [
        //       { title: 'Home', url: '/' },
        //       { title: 'Components' },
        //       { title: 'Button Group', active: true }
        //     ],
        //     pageTitle: 'Button Group',
        //     rule: 'editor'
        //   }
        // },
        // {
        //   path: '/components/chip',
        //   name: 'component-chip',
        //   component: () => import('@/views/components/vuesax/chip/Chip.vue'),
        //   meta: {
        //     breadcrumb: [
        //       { title: 'Home', url: '/' },
        //       { title: 'Components' },
        //       { title: 'Chip', active: true }
        //     ],
        //     pageTitle: 'Chip',
        //     rule: 'editor'
        //   }
        // },
        // {
        //   path: '/components/collapse',
        //   name: 'component-collapse',
        //   component: () => import('@/views/components/vuesax/collapse/Collapse.vue'),
        //   meta: {
        //     breadcrumb: [
        //       { title: 'Home', url: '/' },
        //       { title: 'Components' },
        //       { title: 'Collapse', active: true }
        //     ],
        //     pageTitle: 'Collapse',
        //     rule: 'editor'
        //   }
        // },
        // {
        //   path: '/components/dialogs',
        //   name: 'component-dialog',
        //   component: () => import('@/views/components/vuesax/dialogs/Dialogs.vue'),
        //   meta: {
        //     breadcrumb: [
        //       { title: 'Home', url: '/' },
        //       { title: 'Components' },
        //       { title: 'Dialogs', active: true }
        //     ],
        //     pageTitle: 'Dialogs',
        //     rule: 'editor'
        //   }
        // },
        // {
        //   path: '/components/divider',
        //   name: 'component-divider',
        //   component: () => import('@/views/components/vuesax/divider/Divider.vue'),
        //   meta: {
        //     breadcrumb: [
        //       { title: 'Home', url: '/' },
        //       { title: 'Components' },
        //       { title: 'Divider', active: true }
        //     ],
        //     pageTitle: 'Divider',
        //     rule: 'editor'
        //   }
        // },
        // {
        //   path: '/components/dropdown',
        //   name: 'component-drop-down',
        //   component: () => import('@/views/components/vuesax/dropdown/Dropdown.vue'),
        //   meta: {
        //     breadcrumb: [
        //       { title: 'Home', url: '/' },
        //       { title: 'Components' },
        //       { title: 'Dropdown', active: true }
        //     ],
        //     pageTitle: 'Dropdown',
        //     rule: 'editor'
        //   }
        // },
        // {
        //   path: '/components/list',
        //   name: 'component-list',
        //   component: () => import('@/views/components/vuesax/list/List.vue'),
        //   meta: {
        //     breadcrumb: [
        //       { title: 'Home', url: '/' },
        //       { title: 'Components' },
        //       { title: 'List', active: true }
        //     ],
        //     pageTitle: 'List',
        //     rule: 'editor'
        //   }
        // },
        // {
        //   path: '/components/loading',
        //   name: 'component-loading',
        //   component: () => import('@/views/components/vuesax/loading/Loading.vue'),
        //   meta: {
        //     breadcrumb: [
        //       { title: 'Home', url: '/' },
        //       { title: 'Components' },
        //       { title: 'Loading', active: true }
        //     ],
        //     pageTitle: 'Loading',
        //     rule: 'editor'
        //   }
        // },
        // {
        //   path: '/components/navbar',
        //   name: 'component-navbar',
        //   component: () => import('@/views/components/vuesax/navbar/Navbar.vue'),
        //   meta: {
        //     breadcrumb: [
        //       { title: 'Home', url: '/' },
        //       { title: 'Components' },
        //       { title: 'Navbar', active: true }
        //     ],
        //     pageTitle: 'Navbar',
        //     rule: 'editor'
        //   }
        // },
        // {
        //   path: '/components/notifications',
        //   name: 'component-notifications',
        //   component: () => import('@/views/components/vuesax/notifications/Notifications.vue'),
        //   meta: {
        //     breadcrumb: [
        //       { title: 'Home', url: '/' },
        //       { title: 'Components' },
        //       { title: 'Notifications', active: true }
        //     ],
        //     pageTitle: 'Notifications',
        //     rule: 'editor'
        //   }
        // },
        // {
        //   path: '/components/pagination',
        //   name: 'component-pagination',
        //   component: () => import('@/views/components/vuesax/pagination/Pagination.vue'),
        //   meta: {
        //     breadcrumb: [
        //       { title: 'Home', url: '/' },
        //       { title: 'Components' },
        //       { title: 'Pagination', active: true }
        //     ],
        //     pageTitle: 'Pagination',
        //     rule: 'editor'
        //   }
        // },
        // {
        //   path: '/components/popup',
        //   name: 'component-popup',
        //   component: () => import('@/views/components/vuesax/popup/Popup.vue'),
        //   meta: {
        //     breadcrumb: [
        //       { title: 'Home', url: '/' },
        //       { title: 'Components' },
        //       { title: 'Popup', active: true }
        //     ],
        //     pageTitle: 'Popup',
        //     rule: 'editor'
        //   }
        // },
        // {
        //   path: '/components/progress',
        //   name: 'component-progress',
        //   component: () => import('@/views/components/vuesax/progress/Progress.vue'),
        //   meta: {
        //     breadcrumb: [
        //       { title: 'Home', url: '/' },
        //       { title: 'Components' },
        //       { title: 'Progress', active: true }
        //     ],
        //     pageTitle: 'Progress',
        //     rule: 'editor'
        //   }
        // },
        // {
        //   path: '/components/sidebar',
        //   name: 'component-sidebar',
        //   component: () => import('@/views/components/vuesax/sidebar/Sidebar.vue'),
        //   meta: {
        //     breadcrumb: [
        //       { title: 'Home', url: '/' },
        //       { title: 'Components' },
        //       { title: 'Sidebar', active: true }
        //     ],
        //     pageTitle: 'Sidebar',
        //     rule: 'editor'
        //   }
        // },
        // {
        //   path: '/components/slider',
        //   name: 'component-slider',
        //   component: () => import('@/views/components/vuesax/slider/Slider.vue'),
        //   meta: {
        //     breadcrumb: [
        //       { title: 'Home', url: '/' },
        //       { title: 'Components' },
        //       { title: 'Slider', active: true }
        //     ],
        //     pageTitle: 'Slider',
        //     rule: 'editor'
        //   }
        // },
        // {
        //   path: '/components/tabs',
        //   name: 'component-tabs',
        //   component: () => import('@/views/components/vuesax/tabs/Tabs.vue'),
        //   meta: {
        //     breadcrumb: [
        //       { title: 'Home', url: '/' },
        //       { title: 'Components' },
        //       { title: 'Tabs', active: true }
        //     ],
        //     pageTitle: 'Tabs',
        //     rule: 'editor'
        //   }
        // },
        // {
        //   path: '/components/tooltip',
        //   name: 'component-tooltip',
        //   component: () => import('@/views/components/vuesax/tooltip/Tooltip.vue'),
        //   meta: {
        //     breadcrumb: [
        //       { title: 'Home', url: '/' },
        //       { title: 'Components' },
        //       { title: 'Tooltip', active: true }
        //     ],
        //     pageTitle: 'Tooltip',
        //     rule: 'editor'
        //   }
        // },
        // {
        //   path: '/components/upload',
        //   name: 'component-upload',
        //   component: () => import('@/views/components/vuesax/upload/Upload.vue'),
        //   meta: {
        //     breadcrumb: [
        //       { title: 'Home', url: '/' },
        //       { title: 'Components' },
        //       { title: 'Upload', active: true }
        //     ],
        //     pageTitle: 'Upload',
        //     rule: 'editor'
        //   }
        // },


        // =============================================================================
        // FORMS
        // =============================================================================
        // =============================================================================
        // FORM ELEMENTS
        // =============================================================================
        // {
        //   path: '/forms/form-elements/select',
        //   name: 'form-element-select',
        //   component: () => import('./views/forms/form-elements/select/Select.vue'),
        //   meta: {
        //     breadcrumb: [
        //       { title: 'Home', url: '/' },
        //       { title: 'Form Elements' },
        //       { title: 'Select', active: true }
        //     ],
        //     pageTitle: 'Select',
        //     rule: 'editor'
        //   }
        // },
        // {
        //   path: '/forms/form-elements/switch',
        //   name: 'form-element-switch',
        //   component: () => import('./views/forms/form-elements/switch/Switch.vue'),
        //   meta: {
        //     breadcrumb: [
        //       { title: 'Home', url: '/' },
        //       { title: 'Form Elements' },
        //       { title: 'Switch', active: true }
        //     ],
        //     pageTitle: 'Switch',
        //     rule: 'editor'
        //   }
        // },
        // {
        //   path: '/forms/form-elements/checkbox',
        //   name: 'form-element-checkbox',
        //   component: () => import('./views/forms/form-elements/checkbox/Checkbox.vue'),
        //   meta: {
        //     breadcrumb: [
        //       { title: 'Home', url: '/' },
        //       { title: 'Form Elements' },
        //       { title: 'Checkbox', active: true }
        //     ],
        //     pageTitle: 'Checkbox',
        //     rule: 'editor'
        //   }
        // },
        // {
        //   path: '/forms/form-elements/radio',
        //   name: 'form-element-radio',
        //   component: () => import('./views/forms/form-elements/radio/Radio.vue'),
        //   meta: {
        //     breadcrumb: [
        //       { title: 'Home', url: '/' },
        //       { title: 'Form Elements' },
        //       { title: 'Radio', active: true }
        //     ],
        //     pageTitle: 'Radio',
        //     rule: 'editor'
        //   }
        // },
        // {
        //   path: '/forms/form-elements/input',
        //   name: 'form-element-input',
        //   component: () => import('./views/forms/form-elements/input/Input.vue'),
        //   meta: {
        //     breadcrumb: [
        //       { title: 'Home', url: '/' },
        //       { title: 'Form Elements' },
        //       { title: 'Input', active: true }
        //     ],
        //     pageTitle: 'Input',
        //     rule: 'editor'
        //   }
        // },
        // {
        //   path: '/forms/form-elements/number-input',
        //   name: 'form-element-number-input',
        //   component: () => import('./views/forms/form-elements/number-input/NumberInput.vue'),
        //   meta: {
        //     breadcrumb: [
        //       { title: 'Home', url: '/' },
        //       { title: 'Form Elements' },
        //       { title: 'Number Input', active: true }
        //     ],
        //     pageTitle: 'Number Input',
        //     rule: 'editor'
        //   }
        // },
        // {
        //   path: '/forms/form-elements/textarea',
        //   name: 'form-element-textarea',
        //   component: () => import('./views/forms/form-elements/textarea/Textarea.vue'),
        //   meta: {
        //     breadcrumb: [
        //       { title: 'Home', url: '/' },
        //       { title: 'Form Elements' },
        //       { title: 'Textarea', active: true }
        //     ],
        //     pageTitle: 'Textarea',
        //     rule: 'editor'
        //   }
        // },
        // -------------------------------------------------------------------------------------------------------------------------------------------
        // {
        //   path: '/forms/form-layouts',
        //   name: 'forms-form-layouts',
        //   component: () => import('@/views/forms/FormLayouts.vue'),
        //   meta: {
        //     breadcrumb: [
        //       { title: 'Home', url: '/' },
        //       { title: 'Forms' },
        //       { title: 'Form Layouts', active: true }
        //     ],
        //     pageTitle: 'Form Layouts',
        //     rule: 'editor'
        //   }
        // },
        // {
        //   path: '/forms/form-wizard',
        //   name: 'extra-component-form-wizard',
        //   component: () => import('@/views/forms/form-wizard/FormWizard.vue'),
        //   meta: {
        //     breadcrumb: [
        //       { title: 'Home', url: '/' },
        //       { title: 'Extra Components' },
        //       { title: 'Form Wizard', active: true }
        //     ],
        //     pageTitle: 'Form Wizard',
        //     rule: 'editor'
        //   }
        // },
        {
          path: '/forms/form-validation',
          name: 'extra-component-form-validation',
          component: () => import('@/views/forms/form-validation/FormValidation.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Extra Components' },
              { title: 'Form Validation', active: true }
            ],
            pageTitle: 'Form Validation',
            rule: 'editor'
          }
        },
        // {
        //   path: '/forms/form-input-group',
        //   name: 'extra-component-form-input-group',
        //   component: () => import('@/views/forms/form-input-group/FormInputGroup.vue'),
        //   meta: {
        //     breadcrumb: [
        //       { title: 'Home', url: '/' },
        //       { title: 'Extra Components' },
        //       { title: 'Form Input Group', active: true }
        //     ],
        //     pageTitle: 'Form Input Group',
        //     rule: 'editor'
        //   }
        // },

        // =============================================================================
        // Pages Routes
        // =============================================================================
        {
          path: '/pages/profile',
          name: 'page-profile',
          component: () => import('@/views/pages/Profile.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Pages' },
              { title: 'Profile', active: true }
            ],
            pageTitle: 'Profile',
            rule: 'editor'
          }
        },
        {
          path: '/pages/user-settings',
          name: 'page-user-settings',
          component: () => import('@/views/pages/user-settings/UserSettings.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Pages' },
              { title: 'User Settings', active: true }
            ],
            pageTitle: 'Settings',
            rule: 'editor'
          }
        },
        // {
        //   path: '/pages/faq',
        //   name: 'page-faq',
        //   component: () => import('@/views/pages/Faq.vue'),
        //   meta: {
        //     breadcrumb: [
        //       { title: 'Home', url: '/' },
        //       { title: 'Pages' },
        //       { title: 'FAQ', active: true }
        //     ],
        //     pageTitle: 'FAQ',
        //     rule: 'editor'
        //   }
        // },
        // {
        //   path: '/pages/knowledge-base',
        //   name: 'page-knowledge-base',
        //   component: () => import('@/views/pages/KnowledgeBase.vue'),
        //   meta: {
        //     breadcrumb: [
        //       { title: 'Home', url: '/' },
        //       { title: 'Pages' },
        //       { title: 'KnowledgeBase', active: true }
        //     ],
        //     pageTitle: 'KnowledgeBase',
        //     rule: 'editor'
        //   }
        // },
        // {
        //   path: '/pages/knowledge-base/category',
        //   name: 'page-knowledge-base-category',
        //   component: () => import('@/views/pages/KnowledgeBaseCategory.vue'),
        //   meta: {
        //     breadcrumb: [
        //       { title: 'Home', url: '/' },
        //       { title: 'Pages' },
        //       { title: 'KnowledgeBase', url: '/pages/knowledge-base' },
        //       { title: 'Category', active: true }
        //     ],
        //     parent: 'page-knowledge-base',
        //     rule: 'editor'
        //   }
        // },
        // {
        //   path: '/pages/knowledge-base/category/question',
        //   name: 'page-knowledge-base-category-question',
        //   component: () => import('@/views/pages/KnowledgeBaseCategoryQuestion.vue'),
        //   meta: {
        //     breadcrumb: [
        //       { title: 'Home', url: '/' },
        //       { title: 'Pages' },
        //       { title: 'KnowledgeBase', url: '/pages/knowledge-base' },
        //       { title: 'Category', url: '/pages/knowledge-base/category' },
        //       { title: 'Question', active: true }
        //     ],
        //     parent: 'page-knowledge-base',
        //     rule: 'editor'
        //   }
        // },
        // {
        //   path: '/pages/search',
        //   name: 'page-search',
        //   component: () => import('@/views/pages/Search.vue'),
        //   meta: {
        //     breadcrumb: [
        //       { title: 'Home', url: '/' },
        //       { title: 'Pages' },
        //       { title: 'Search', active: true }
        //     ],
        //     pageTitle: 'Search',
        //     rule: 'editor'
        //   }
        // },
        // {
        //   path: '/pages/invoice',
        //   name: 'page-invoice',
        //   component: () => import('@/views/pages/Invoice.vue'),
        //   meta: {
        //     breadcrumb: [
        //       { title: 'Home', url: '/' },
        //       { title: 'Pages' },
        //       { title: 'Invoice', active: true }
        //     ],
        //     pageTitle: 'Invoice',
        //     rule: 'editor'
        //   }
        // },

        // =============================================================================
        // CHARTS & MAPS
        // =============================================================================
        {
          path: '/charts-and-maps/charts/apex-charts',
          name: 'extra-component-charts-apex-charts',
          component: () => import('@/views/charts-and-maps/charts/apex-charts/ApexCharts.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Charts & Maps' },
              { title: 'Apex Charts', active: true }
            ],
            pageTitle: 'Apex Charts',
            rule: 'editor'
          }
        },
        {
          path: '/charts-and-maps/charts/chartjs',
          name: 'extra-component-charts-chartjs',
          component: () => import('@/views/charts-and-maps/charts/chartjs/Chartjs.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Charts & Maps' },
              { title: 'chartjs', active: true }
            ],
            pageTitle: 'chartjs',
            rule: 'editor'
          }
        },
        {
          path: '/charts-and-maps/charts/echarts',
          name: 'extra-component-charts-echarts',
          component: () => import('@/views/charts-and-maps/charts/echarts/Echarts.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Charts & Maps' },
              { title: 'echarts', active: true }
            ],
            pageTitle: 'echarts',
            rule: 'editor'
          }
        },
        {
          path: '/charts-and-maps/maps/google-map',
          name: 'extra-component-maps-google-map',
          component: () => import('@/views/charts-and-maps/maps/google-map/GoogleMap.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Charts & Maps' },
              { title: 'Google Map', active: true }
            ],
            pageTitle: 'Google Map',
            rule: 'editor'
          }
        },


        // =============================================================================
        // EXTENSIONS
        // =============================================================================
        {
          path: '/extensions/select',
          name: 'extra-component-select',
          component: () => import('@/views/components/extra-components/select/Select.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Extra Components' },
              { title: 'Select', active: true }
            ],
            pageTitle: 'Select',
            rule: 'editor'
          }
        },
        {
          path: '/extensions/quill-editor',
          name: 'extra-component-quill-editor',
          component: () => import('@/views/components/extra-components/quill-editor/QuillEditor.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Extra Components' },
              { title: 'Quill Editor', active: true }
            ],
            pageTitle: 'Quill Editor',
            rule: 'editor'
          }
        },
        {
          path: '/extensions/drag-and-drop',
          name: 'extra-component-drag-and-drop',
          component: () => import('@/views/components/extra-components/drag-and-drop/DragAndDrop.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Extra Components' },
              { title: 'Drag & Drop', active: true }
            ],
            pageTitle: 'Drag & Drop',
            rule: 'editor'
          }
        },
        {
          path: '/extensions/datepicker',
          name: 'extra-component-datepicker',
          component: () => import('@/views/components/extra-components/datepicker/Datepicker.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Extra Components' },
              { title: 'Datepicker', active: true }
            ],
            pageTitle: 'Datepicker',
            rule: 'editor'
          }
        },
        {
          path: '/extensions/datetime-picker',
          name: 'extra-component-datetime-picker',
          component: () => import('@/views/components/extra-components/datetime-picker/DatetimePicker.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Extra Components' },
              { title: 'Datetime Picker', active: true }
            ],
            pageTitle: 'Datetime Picker',
            rule: 'editor'
          }
        },
        {
          path: '/extensions/access-control',
          name: 'extra-component-access-control',
          component: () => import('@/views/components/extra-components/access-control/AccessControl.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Extensions' },
              { title: 'Access Control', active: true }
            ],
            pageTitle: 'Access Control',
            rule: 'editor'
          }
        },
        {
          path: '/extensions/i18n',
          name: 'extra-component-i18n',
          component: () => import('@/views/components/extra-components/I18n.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Extensions' },
              { title: 'I18n', active: true }
            ],
            pageTitle: 'I18n',
            rule: 'editor'
          }
        },
        {
          path: '/extensions/carousel',
          name: 'extra-component-carousel',
          component: () => import('@/views/components/extra-components/carousel/Carousel.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Extensions' },
              { title: 'Carousel', active: true }
            ],
            pageTitle: 'Carousel',
            rule: 'editor'
          }
        },
        {
          path: '/extensions/clipboard',
          name: 'extra-component-clipboard',
          component: () => import('@/views/components/extra-components/clipboard/Clipboard.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Extensions' },
              { title: 'Clipboard', active: true }
            ],
            pageTitle: 'Clipboard',
            rule: 'editor'
          }
        },
        {
          path: '/extensions/context-menu',
          name: 'extra-component-context-menu',
          component: () => import('@/views/components/extra-components/context-menu/ContextMenu.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Extensions' },
              { title: 'Context Menu', active: true }
            ],
            pageTitle: 'Context Menu',
            rule: 'editor'
          }
        },
        {
          path: '/extensions/star-ratings',
          name: 'extra-component-star-ratings',
          component: () => import('@/views/components/extra-components/star-ratings/StarRatings.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Extensions' },
              { title: 'Star Ratings', active: true }
            ],
            pageTitle: 'Star Ratings',
            rule: 'editor'
          }
        },
        {
          path: '/extensions/autocomplete',
          name: 'extra-component-autocomplete',
          component: () => import('@/views/components/extra-components/autocomplete/Autocomplete.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Extensions' },
              { title: 'Autocomplete', active: true }
            ],
            pageTitle: 'Autocomplete',
            rule: 'editor'
          }
        },
        {
          path: '/extensions/tree',
          name: 'extra-component-tree',
          component: () => import('@/views/components/extra-components/tree/Tree.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Extensions' },
              { title: 'Tree', active: true }
            ],
            pageTitle: 'Tree',
            rule: 'editor'
          }
        },
        {
          path: '/import-export/import',
          name: 'import-excel',
          component: () => import('@/views/components/extra-components/import-export/Import.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Extensions' },
              { title: 'Import/Export' },
              { title: 'Import', active: true }
            ],
            pageTitle: 'Import Excel',
            rule: 'editor'
          }
        },
        {
          path: '/import-export/export',
          name: 'export-excel',
          component: () => import('@/views/components/extra-components/import-export/Export.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Extensions' },
              { title: 'Import/Export' },
              { title: 'Export', active: true }
            ],
            pageTitle: 'Export Excel',
            rule: 'editor'
          }
        },
        {
          path: '/import-export/export-selected',
          name: 'export-excel-selected',
          component: () => import('@/views/components/extra-components/import-export/ExportSelected.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Extensions' },
              { title: 'Import/Export' },
              { title: 'Export Selected', active: true }
            ],
            pageTitle: 'Export Excel',
            rule: 'editor'
          }
        }
      ]
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: '',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: '/callback',
          name: 'auth-callback',
          component: () => import('@/views/Callback.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/login',
          name: 'page-login',
          component: () => import('@/views/pages/login/Login.vue'),
          meta: {
            rule: 'editor'
          }
        },
        // {
        //   path: '/pages/register',
        //   name: 'page-register',
        //   component: () => import('@/views/pages/register/Register.vue'),
        //   meta: {
        //     rule: 'editor'
        //   }
        // },
        // {
        //   path: '/reset-password',
        //   name: 'reset-pasword',
        //   component: () => import('@/views/pages/ForgotPassword.vue'),
        //   meta: {
        //     rule: 'editor'
        //   }
        // },
        // {
        //   path: '/pages/reset-password',
        //   name: 'page-reset-password',
        //   component: () => import('@/views/pages/ResetPassword.vue'),
        //   meta: {
        //     rule: 'editor'
        //   }
        // },
        // {
        //   path: '/pages/lock-screen',
        //   name: 'page-lock-screen',
        //   component: () => import('@/views/pages/LockScreen.vue'),
        //   meta: {
        //     rule: 'editor'
        //   }
        // },
        // {
        //   path: '/pages/comingsoon',
        //   name: 'page-coming-soon',
        //   component: () => import('@/views/pages/ComingSoon.vue'),
        //   meta: {
        //     rule: 'editor'
        //   }
        // },
        {
          path: '/error-404',
          name: 'page-error-404',
          component: () => import('@/views/pages/Error404.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/error-500',
          name: 'page-error-500',
          component: () => import('@/views/pages/Error500.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/not-authorized',
          name: 'page-not-authorized',
          component: () => import('@/views/pages/NotAuthorized.vue'),
          meta: {
            rule: 'editor'
          }
        }
        // {
        //   path: '/pages/maintenance',
        //   name: 'page-maintenance',
        //   component: () => import('@/views/pages/Maintenance.vue'),
        //   meta: {
        //     rule: 'editor'
        //   }
        // }
      ]
    },
    // Redirect to 404 page, if no match found
    {
      path: '*',
      redirect: '/error-404'
    }
  ]
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.authRequired)) {
    const isAuthenticated = await auth.checkValidToken()

    console.log(isAuthenticated)
    if (isAuthenticated.data === 'token valide') {
      next()
      return
    }
    next('/login')
  } else {
    next()
  }
  // if (
  //   to.path === '/login' ||
  //     to.path === '/forgot-password' ||
  //     to.path === '/error-404' ||
  //     to.path === '/error-500' ||
  //     to.path === '/callback' ||
  //     to.path === '/pages/comingsoon'
  // ) {
  //   return next()
  // }
  // If auth required, check login. If login fails redirect to login page
  //console.log('authRequired:', to.meta.authRequired)
  // if (to.meta.authRequired) {
  // }
  // const isAuthenticated = await auth.checkValidToken()
  // console.log(isAuthenticated.data)
  // if (to.path !== '/login' && isAuthenticated.data === 'token expiré') {
  //   router.push({ path: '/login', query: { to: to.path } })
  // } else if (to.path === '/login' && isAuthenticated.data === 'token valide') next(false)
  // return next()
  // Specify the current path as the customState parameter, meaning it
  // will be returned to the application after auth
  // auth.login({ target: to.path });

})

export default router
