/*=========================================================================================
  File Name: moduleAtelierAction.js
  Description: mutation of module atelier
  ----------------------------------------------------------------------------------------
  Author: Eudoxie Djetouan
  Author email: eudoxiedjetouan@gmail.com
==========================================================================================*/


export default {

  SET_ATELIERS (state, ventes) {
    state.ventes = ventes
  },

  ADD_ATELIER (state, ventes) {
    state.ventes.unshift(ventes)
  },

  UPDATE_ATELIER (state, vente) {
    const venteIndex = state.ventes.findIndex((m) => m._id === vente._id)
    Object.assign(state.ventes[venteIndex], vente)
  },

  REMOVE_ATELIER (state, itemId) {
    const venteIndex = state.ventes.findIndex((u) => u._id === itemId)
    state.ventes.splice(venteIndex, 1)
  }
}
