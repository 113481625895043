/*=========================================================================================
  File Name: moduleSocieteMutations.js
  Description: Societe Module Mutations
  ----------------------------------------------------------------------------------------
==========================================================================================*/


export default {
  SET_SOCIETES (state, societes) {
    state.societes = societes
  },
  ADD_SOCIETE (state, societe) {
    state.societes.unshift(societe)
  },
  UPDATE_SOCIETE (state, societe) {
    const societeIndex = state.societes.findIndex((m) => m._id === societe._id)
    console.log(societeIndex)
    Object.assign(state.societes[societeIndex], societe)
  },
  REMOVE_RECORD (state, itemId) {
    const societeIndex = state.societes.findIndex((u) => u._id === itemId)
    state.societes.splice(societeIndex, 1)
  }
}
