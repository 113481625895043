/*=========================================================================================
  File Name: moduleStockMutations.js
  Description: Stock Module Mutations
  ----------------------------------------------------------------------------------------
==========================================================================================*/


export default {
  SET_NOM_COMMERCIAL_VERRE (state, data) {
    state.nomVerres = data
  },
  ADD_NOM_COMMERCIAL_VERRE (state, data) {
    state.nomVerres.unshift(data)
  },
  UPDATE_NOM_COMMERCIAL_VERRE (state, data) {
    const index = state.nomVerres.findIndex((v) => v._id === data._id)
    Object.assign(state.nomVerres[index], data)
  },
  DELETE_NOM_COMMERCIAL_VERRE (state, id) {
    const index = state.nomVerres.findIndex((v) => v._id === id)
    state.nomVerres.splice(index, 1)
  },
  DELETE_ALL_NOM_COMMERCIAL_VERRE (state) {
    state.nomVerres = []
  },
  SET_STOCK_VERRES_PROG (state, data) {
    state.verresProg = data
  },
  ADD_STOCK_VERRE_PROG (state, data) {
    state.verresProg.unshift(data)
  },
  UPDATE_STOCK_VERRE_PROG (state, data) {
    const index = state.verresProg.findIndex((v) => v._id === data._id)
    Object.assign(state.verresProg[index], data)
  },
  DELETE_STOCK_VERRE_PROG (state, id) {
    const index = state.verresProg.findIndex((v) => v._id === id)
    state.verresProg.splice(index, 1)
  },
  ALL_DELETE_STOCK_VERRE_PROG (state) {
    state.verresProg = []
  },
  SET_STOCK_VERRES_UNIFOCO (state, data) {
    state.verresUnifoco = data
  },
  ADD_STOCK_VERRES_UNIFOCO (state, data) {
    state.verresUnifoco.unshift(data)
  },
  UPDATE_STOCK_VERRES_UNIFOCO (state, data) {
    const index = state.verresUnifoco.findIndex((v) => v._id === data._id)
    Object.assign(state.verresUnifoco[index], data)
  },
  DETELE_STOCK_VERRES_UNIFOCO (state, id) {
    const index = state.verresUnifoco.findIndex((v) => v._id === id)
    state.verresUnifoco.splice(index, 1)
  },
  DETELE_ALL_STOCK_VERRES_UNIFOCO (state) {
    state.verresUnifoco = []
  }
}
