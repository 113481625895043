/*=========================================================================================
  M.Djezou
  File Name: moduleMarqueActions.js
  Description: Marque Module Actions
  ----------------------------------------------------------------------------------------
==========================================================================================*/

import axios from '@/axios.js'

export default {
  getMarques ({commit}) {
    return new Promise((resolve, reject) => {
      axios.get('/api/marques')
        .then((response) => {
          commit('SET_MARQUES', response.data)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  createMarque ({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/marque/create', {payload})
        .then((response) => {
          commit('ADD_MARQUE', response.data)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  updateMarque ({commit}, payload) {
    const {id} = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/marque/update/${id}`, {payload})
        .then((response) => {
          commit('UPDATE_MARQUE', response.data)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  dropMarque ({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/marque/drop/${id}`)
        .then((response) => {
          commit('REMOVE_RECORD', id)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}

