

export default {
  getFournisseurById:  state => id  => {
    return state.fournisseurs.find((f) => f._id === id)
  },
  getFournisseurName: state => id => {
    const fourn = state.fournisseurs.find((f) => f._id === id)
    return fourn ? fourn.Nom : ''
  }
}
