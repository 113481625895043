/*=========================================================================================
  File Name: moduleSocieteActions.js
  Description: Societe Module Actions
  ----------------------------------------------------------------------------------------
==========================================================================================*/

import axios from '@/axios.js'

export default {
  addSociete ({ commit }, payload) {

    return new Promise((resolve, reject) => {
      axios.post('/api/societe/create', { payload })
        .then((response) => {
          commit('ADD_SOCIETE', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateSociete ({commit}, payload) {

    const { id } = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/societe/${id}`, { payload })
        .then((response) => {
          commit('UPDATE_SOCIETE', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getSocietes ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/societes')
        .then((response) => {
          commit('SET_SOCIETES', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  removeSociete ({ commit }, societeId) {

    return new Promise((resolve, reject) => {
      axios.delete(`/api/societe/${societeId}`)
        .then((response) => {
          commit('REMOVE_RECORD', societeId)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
