/*=========================================================================================
  File Name: modulePartenaireActions.js
  Description: Partenaire Module Actions
  ----------------------------------------------------------------------------------------
==========================================================================================*/

import axios from '@/axios.js'

export default {

  createPartenaire ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/partenaire/create', {payload})
        .then((response) => {
          commit('ADD_PARTENAIRE', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updatePartenaire ({commit}, payload) {
    const {id} = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/partenaire/update/${id}`, {payload})
        .then((response) => {
          commit('UPDATE_PARTENAIRE', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getPartenaires ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/partenaires')
        .then((response) => {
          commit('SET_PARTENAIRES', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  getPartenaire (context, partenaireId) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/partenaire/${partenaireId}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  removePartenaire ({ commit }, partenaireId) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/partenaire/drop/${partenaireId}`)
        .then((response) => {
          commit('REMOVE_RECORD', partenaireId)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
