/*=========================================================================================
  File Name: moduleVersementBanqState.js
  Description: state of module Versement banque
  ----------------------------------------------------------------------------------------
  Author: Eudoxie Djetouan
  Author email: eudoxiedjetouan@gmail.com
==========================================================================================*/

export default {
  versements: []
}
  