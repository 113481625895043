const calculateTotalEncaissements = (agenceData) => {
  if (!agenceData) {
    return 0
  }

  const encaissements = agenceData.encaissements || []
  return encaissements.reduce((acc, operation) => acc + (operation.montant ? +operation.montant : 0), 0)
}
export default {
  CaisseIsInit: state => {
    const init_array = state.operationsToday ? state.operationsToday.find((o) => o.typeOperation === 'Initialisation') : false
    return !!init_array
  },
  getFondDeCaisse: state => {
    return state.operationsToday ? (state.operationsToday.find((o) => o.typeOperation === 'Initialisation') ? state.operationsToday.find((o) => o.typeOperation === 'Initialisation').montant : 0) : 0
  },
  TotalFondCaisse: state => {
    const fonds = state.operations.length > 0 ? state.operations.filter((o) => o.typeOperation === 'Initialisation') : null

    return fonds ? fonds.reduce((acc, fond) => {
      return acc + +fond.montant
    }, 0) : 0
  },
  TotalEncaisse: state => {
    const reg_cmd_client = state.operations
      .filter(o => o.typeOperation === 'Règlement cmde cli' && o.modepaiement === 'Espèce')
      .reduce((acc, reg) => acc + (reg.montant || 0), 0)
  
    const reg_cpt = state.operations
      .filter(o => o.typeOperation === 'Règlement cpt')
      .reduce((acc, reg) => acc + (reg.montant || 0), 0)
  
    const appro = state.operations
      .filter(o => o.typeOperation === 'Approvisionnement')
      .reduce((acc, reg) => acc + (reg.montant || 0), 0)

    const consultation = state.operations
      .filter(o => o.typeOperation === 'Consultation')
      .reduce((acc, consultation) => acc + (consultation.montant || 0), 0)
      
    return reg_cmd_client + reg_cpt + appro + consultation
  },
  TotalMontantDecaisse: state => {
    const versementBanque = state.operations.filter(o => o.typeOperation === 'Versement Banque')
    const ApproCaisseDepense = state.operations.filter(o => o.typeOperation === 'Approvisionnement Caisse Depense')
  
    const montantVersementBanque = versementBanque.reduce((acc, vers) => acc + (vers.montant || 0), 0)
    const montantApproCaisseDepense = ApproCaisseDepense.reduce((acc, vers) => acc + (vers.montant || 0), 0)
  
    return montantVersementBanque + montantApproCaisseDepense
  },
  FondCaisse: state => {
    const init_array = state.operationsToday ? state.operationsToday.filter(o => o.typeOperation === 'Initialisation') : []
    const init = init_array.reduce((acc, reg) => acc + (reg.montant ? reg.montant : 0), 0)
    return init
  },
  EncaisseSelectDate: state => {
    const operationsToday = state.operationsToday
    const typesOperations = ['Règlement cmde cli', 'Règlement cpt', 'Approvisionnement', 'Consultation']
  
    const totalEncaisse = typesOperations.reduce((acc, operationType) => {
      const filteredOperations = operationsToday.filter(o => o.typeOperation === operationType && o.modepaiement === 'Espèce')
      const totalAmount = filteredOperations.reduce((amountAcc, operation) => amountAcc + (operation.montant || 0), 0)
      return acc + totalAmount
    }, 0)
  
    return totalEncaisse
  },
  MontantCheqRecu: state => {
    const operationsToday = state.operationsToday
    const getMontantTotalCheque = (typeOperation) => {
      const filteredOperations = operationsToday.filter(o => o.typeOperation === typeOperation && o.modepaiement === 'Chèque')
      return filteredOperations.reduce((acc, operation) => acc + (operation.montant || 0), 0)
    }
    const reg_cmd_client = getMontantTotalCheque('Règlement cmde cli')
    const reg_cpt = getMontantTotalCheque('Règlement cpt')
    return reg_cmd_client + reg_cpt
  },  
  MontantDecaisse: state => {
    const operationsToday = state.operationsToday
    const getMontantTotal = (typeOperation) => {
      const filteredOperations = operationsToday.filter(o => o.typeOperation === typeOperation)
      return filteredOperations.reduce((acc, operation) => acc + (operation.montant || 0), 0)
    }
    const montantVersementBanque = getMontantTotal('Versement Banque')
    const montantApproCaisseDepense = getMontantTotal('Approvisionnement Caisse Depense')
    return montantVersementBanque + montantApproCaisseDepense
  },

  MontantCheqEmis: state => {
    const operationsToday = state.operationsToday
    const versement_cheq_array = operationsToday.filter(o => o.typeOperation === 'Versement' && o.modepaiement === 'Chèque')
    const montant = versement_cheq_array.reduce((acc, vers) => acc + (vers.montant || 0), 0)
    return montant
  },

  // Utilisez la fonction réutilisable pour encaissementToDay et encaissementPrevDay
  encaissementToDay: state => id => {
    const agenceData = state.encaissements_toDay_by_agence ? state.encaissements_toDay_by_agence.find(a => a.officine === id) : null
    return calculateTotalEncaissements(agenceData)
  },
  encaissementPrevDay: state => id => {
    const agenceData = state.encaissements_prev_day_by_agence ? state.encaissements_prev_day_by_agence.find(a => a.officine === id) : null
    return calculateTotalEncaissements(agenceData)
  }
}
