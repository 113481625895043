/*=========================================================================================
  M.Djezou
  File Name: moduleOfficineState.js
  Description: Officine Module State
  ----------------------------------------------------------------------------------------
==========================================================================================*/

export default {
  operationsToday: [],
  operations: [],
  encaissements_toDay_by_agence: [],
  encaissements_prev_day_by_agence: []
}
