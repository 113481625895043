/*=========================================================================================
  File Name: moduleEntetePiedpage.js
  Description: EntetePiedpage Module
  ----------------------------------------------------------------------------------------
==========================================================================================*/


import state from './moduleEntetePiedpageState'
import mutations from './moduleEntetePiedpageMutations'
import actions from './moduleEntetePiedpageeActions'
import getters from './moduleEntetePiedpageGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

