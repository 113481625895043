/*=========================================================================================
  File Name: modulePartenaire.js
  Description: Partenaire Module
  ----------------------------------------------------------------------------------------
==========================================================================================*/


import state from './modulePartenaireState.js'
import mutations from './modulePartenaireMutations.js'
import actions from './modulePartenaireActions.js'
import getters from './modulePartenaireGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

