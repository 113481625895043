/*=========================================================================================
  File Name: moduleFacturationState.js
  Description: Facturation Module State
  ----------------------------------------------------------------------------------------
==========================================================================================*/

export default {
  facture_assurances: [],
  commandes_non_traiter: [],
  factures_assurance_a_deposer: [],
  factures_assurance_deposer: [],
  factures_assurance_modele: []
}
