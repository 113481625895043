/*=========================================================================================
  M.Djezou
  File Name: moduleOfficineState.js
  Description: Officine Module State
  ----------------------------------------------------------------------------------------
==========================================================================================*/

export default {
  pays: [],
  villes: [],
  quartiers: []
}
