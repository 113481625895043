/*=========================================================================================
  M.Djezou
  File Name: moduleOfficine.js
  Description: Officine Module
  ----------------------------------------------------------------------------------------

==========================================================================================*/


import state from './moduleOperationState.js'
import mutations from './moduleOperationMutations.js'
import actions from './moduleOperationActions.js'
import getters from './moduleOperationGetters.js'

export default {

  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

