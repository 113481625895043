import axios from '@/axios.js'

export default {
  getAllSuspectByUserId ({ commit }, id_user) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/suspects/user/${id_user}`)
        .then((response) => {
          commit('SET_SUSPECT', response.data.suspects)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getSuspectById (context, id) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/suspect/${id}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  convertiSuspect (context, payload) {
    return new Promise((resolve, reject) => {
      axios.put('/api/suspect/converti', { payload })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  createSuspect ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/suspect/create', { payload })
        .then((response) => {
          commit('ADD_SUSPECT', response.data.suspect)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateSuspect ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.put('/api/suspect/update', { payload })
        .then((response) => {
          commit('UPDATE_SUSPECT', response.data.suspect)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateSuspectStatus ({ commit }, payload) {
    const { suspects, status_suspect } = payload
    suspects.forEach(suspect => {
      const _payload = {
        id: suspect._id,
        status_suspect
      }
      return new Promise((resolve, reject) => {
        axios.put('/api/suspect/update/status', { _payload })
          .then((response) => {
            commit('UPDATE_SUSPECT', response.data.suspect)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    })
  },
  validateSuspectStatus (context, payload) {
    return new Promise((resolve, reject) => {
      axios.put('/api/suspect/validate/status', { payload })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  softdeleteSuspect ({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.put(`/api/suspect/softdelete/${id}`)
        .then((response) => {
          commit('DELETE_SUSPECT', id)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}