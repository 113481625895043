/*=========================================================================================
  M.Djezou
  File Name: moduleRoleState.js
  Description: Role Module State
  ----------------------------------------------------------------------------------------
==========================================================================================*/

export default {
  roles: []
}
