/*=========================================================================================
  File Name: moduleUserGetters.js
  Description: User Module Getters
  ----------------------------------------------------------------------------------------
==========================================================================================*/


export default {
  getUserById: state => id => {
    return state.users.length > 0 ? state.users.find((user) => user._id === id) : {}
  }
}
