/*=========================================================================================
  File Name: moduleStyleActions.js
  Description: Style Module Actions
  ----------------------------------------------------------------------------------------
==========================================================================================*/

import axios from '@/axios.js'

export default {

  createStyle ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/style/create', {payload})
        .then((response) => {
          commit('ADD_STYLE', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateStyle ({commit}, payload) {
    const {id} = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/style/update/${id}`, {payload})
        .then((response) => {
          commit('UPDATE_STYLE', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getStyles ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/styles')
        .then((response) => {
          commit('SET_STYLES', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  getStyle (context, styleId) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/style/${styleId}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  removeStyle ({ commit }, styleId) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/style/drop/${styleId}`)
        .then((response) => {
          commit('REMOVE_RECORD', styleId)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
