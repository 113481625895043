/*=========================================================================================
// <<<<<<< M.Djezou
  File Name: moduleOfficine.js
  Description: Officine Module
  ----------------------------------------------------------------------------------------
// =======
  File Name: moduleFournisseur.js
  Description: module Fournisseur
  ----------------------------------------------------------------------------------------
  Author: Eudoxie Djetouan
  Author email: eudoxiedjetouan@gmail.com
// >>>>>>> main
==========================================================================================*/


import state from './moduleFournisseurState.js'
import mutations from './moduleFournisseurMutations.js'
import actions from './moduleFournisseurActions.js'
import getters from './moduleFournisseurGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

