/*=========================================================================================
  File Name: moduleSmsMarketingGetter.js
  Description: Getter of module SmsMarketing
  ----------------------------------------------------------------------------------------
==========================================================================================*/

export default {
  getPrescripteurById: state => id => {
    return state.prescripteurs.length > 0 ? (state.prescripteurs.find((p) => p._id === id) ? state.prescripteurs.find((p) => p._id === id) : null) : null
  },
  getPrecripteurName: state => id => {
    const prescripteur = state.prescripteurs.find((p) => p._id === id)
    return prescripteur ? `${prescripteur.NomPresc ? prescripteur.NomPresc : ''} ${prescripteur.PrenomPresc ? prescripteur.PrenomPresc : ''}` : {}
  }
}
