/*=========================================================================================
  File Name: moduleAssuranceAction.js
  Description: mutation of module assurance
  ----------------------------------------------------------------------------------------
  Author: Eudoxie Djetouan
  Author email: eudoxiedjetouan@gmail.com
==========================================================================================*/


export default {

  // store content all appro despense caisse
  SET_APPRODEPENSE (state, approDepenses) {
    state.approDepens = approDepenses
  }
}