/*=========================================================================================
  File Name: moduleCommandeFournMutations.js
  Description: CommandeFourn Module Mutations
  ----------------------------------------------------------------------------------------
==========================================================================================*/

export default {
  SET_COMMANDES_FOURN (state, data) {
    state.pager = data.pager
    state.pageOfItems = data.pageOfItems
  },
  SET_COMMANDES_FOURN_TRANS (state, data) {
    state.commande_fourns_transferer = data
  },
  /* SET_TRANSFERER_COMMANDES_FOURN (state, data) {
    state.pager = data.pager
    state.pageOfItems = data.pageOfItems
  }, */
  ADD_COMMANDE_FOURN (state, commande_fourns) {
    state.pageOfItems.unshift(commande_fourns)
  },
  UPDATE_COMMANDE_FOURN (state, id) {
    const commandeIndex = state.pageOfItems ? state.pageOfItems.findIndex((m) => m._id === id) : state.commande_fourns_transferer.findIndex((m) => m._id === id)
    state.pageOfItems ? Object.assign(state.pageOfItems[commandeIndex], id) : Object.assign(state.commande_fourns_transferer[commandeIndex], id)
  },
  DELETE_COMMANDE_FOURN (state, itemId) {
    const commandeIndex = state.pageOfItems.findIndex((u) => u._id === itemId)
    state.pageOfItems.splice(commandeIndex, 1)
  }
}
