/*=========================================================================================
  File Name: moduleStyleGetters.js
  Description: Style Module Getters
  ----------------------------------------------------------------------------------------
==========================================================================================*/


export default {
  getStyleById: state => idStyle => {
    return state.styles.find((s) => s._id === idStyle)
  }
}
