

export default {
  SET_CATEGORIE_ARTICLE (state, categories) {
    state.categorie_articles = categories
  },
  ADD_CATEGORIE_ARTICLE (state, categorie) {
    state.categorie_articles.unshift(categorie)
  },
  UPDATE_CATEGORIE_ARTICLE (state, categorie) {
    const categorieIndex = state.categorie_articles.findIndex((c) => c._id === categorie._id)
    Object.assign(state.categorie_articles[categorieIndex], categorie)
  },
  DELETE_CATEGORIE_ARTICLE (state, id) {
    const categorieIndex = state.categorie_articles.findIndex((c) => c._id === id)
    state.categorie_articles.splice(categorieIndex, 1)
  }
}
