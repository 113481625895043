/*=========================================================================================
  File Name: moduleCalendarGetters.js
  Description: Facturation Module Getters
  ----------------------------------------------------------------------------------------
==========================================================================================*/


export default {
  getModeleFactureByAssurance: state => assuranceId => {
    return state.factures_assurance_modele.length > 0 ? state.factures_assurance_modele.find((f) => f.IdAssurance === assuranceId) : null
  },
  removeLineOnFactureAssurance: state => id => {
    const index = state.facture_assurances.length > 0 ? state.facture_assurances.findIndex((f) => f._id === id) : null
    return state.facture_assurances.splice(index, 1)
  }
}
