
export default {
  getClientById: state => id => {
    return state.clients.length > 0 ? (state.clients.find((c) => c._id === id) ? state.clients.find((c) => c._id === id) : null) : null
  },
  getClientName: state => id => {
    const client = state.clients.length > 0 ? (state.clients.find((c) => c._id === id) ? state.clients.find((c) => c._id === id) : null) : null
    return client ? `${client.Nom ? client.Nom : ''} ${client.Prenoms ? client.Prenoms : ''}` : {}
  },
  nbreToDay: state => id => {
    const agence = state.commandes_today_by_agence ? state.commandes_today_by_agence.find((a) => a.officine === id) : null
    return agence ? agence.commandes.length : 0
  },
  nbreHier: state => id => {
    const agence = state.commandes_hier_by_agence ? state.commandes_hier_by_agence.find((a) => a.officine === id) : null
    return agence ? agence.commandes.length : 0
  },
  nbreCurrentWeek: state => id => {
    const agence = state.commandes_current_week_by_agence ? state.commandes_current_week_by_agence.find((a) => a.officine === id) : null
    return agence ? agence.commandes.length : 0
  },
  nbrePrevWeek: state => id => {
    const agence = state.commandes_prev_week_by_agence ? state.commandes_prev_week_by_agence.find((a) => a.officine === id) : null
    return agence ? agence.commandes.length : 0
  },
  nbreCurrentMonth: state => id => {
    const agence = state.commandes_current_month_by_agence ? state.commandes_current_month_by_agence.find((a) => a.officine === id) : null
    return agence ? agence.commandes.length : 0
  },
  nbrePrevMonth: state => id => {
    const agence = state.commandes_prev_month_by_agence ? state.commandes_prev_month_by_agence.find((a) => a.officine === id) : null
    return agence ? agence.commandes.length : 0
  },
  nbreCurrentYear: state => id => {
    const agence = state.commandes_current_year_by_agence ? state.commandes_current_year_by_agence.find((a) => a.officine === id) : null
    return agence ? agence.commandes.length : 0
  },
  nbrePrevYear: state => id => {
    const agence = state.commandes_prev_year_by_agence ? state.commandes_prev_year_by_agence.find((a) => a.officine === id) : null
    return agence ? agence.commandes.length : 0
  },
  venteToDayTTC: state => id => {
    const agence = state.commandes_today_by_agence ? state.commandes_today_by_agence.find((a) => a.officine === id) : null
    const commandes = agence ? (agence.commandes ? agence.commandes.reduce((acc, commande) => {
      return acc + ((((commande.PrixMont1 ? +commande.PrixMont1 : 0) + (commande.PrixVerG1 ? +commande.PrixVerG1 : 0) + (commande.PrixVerD1 ? commande.PrixVerD1 : 0)) + ((commande.PrixMont2 ? commande.PrixMont2 : 0) + (commande.PrixVerG2 ? commande.PrixVerG2 : 0) + (commande.PrixVerD2 ? commande.PrixVerD2 : 0)) + ((commande.PrixMont3 ? commande.PrixMont3 : 0) + (commande.PrixVerG3 ? commande.PrixVerG3 : 0) + (commande.PrixVerD3 ? commande.PrixVerD3 : 0))) - (commande.RemiseCom ? commande.RemiseCom : 0))
    }, 0) : 0) : 0

    return commandes
  },
  venteHierTTC: state => id => {
    const agence = state.commandes_hier_by_agence ? state.commandes_hier_by_agence.find((a) => a.officine === id) : null
    const commandes = agence ? (agence.commandes ? agence.commandes.reduce((acc, commande) => {
      return acc + ((((commande.PrixMont1 ? +commande.PrixMont1 : 0) + (commande.PrixVerG1 ? +commande.PrixVerG1 : 0) + (commande.PrixVerD1 ? commande.PrixVerD1 : 0)) + ((commande.PrixMont2 ? commande.PrixMont2 : 0) + (commande.PrixVerG2 ? commande.PrixVerG2 : 0) + (commande.PrixVerD2 ? commande.PrixVerD2 : 0)) + ((commande.PrixMont3 ? commande.PrixMont3 : 0) + (commande.PrixVerG3 ? commande.PrixVerG3 : 0) + (commande.PrixVerD3 ? commande.PrixVerD3 : 0))) - (commande.RemiseCom ? commande.RemiseCom : 0))
    }, 0) : 0) : 0

    return commandes
  },
  venteCurrentWeekTTC: state => id => {
    const agence = state.commandes_current_week_by_agence ? state.commandes_current_week_by_agence.find((a) => a.officine === id) : null
    const commandes = agence ? (agence.commandes ? agence.commandes.reduce((acc, commande) => {
      return acc + ((((commande.PrixMont1 ? +commande.PrixMont1 : 0) + (commande.PrixVerG1 ? +commande.PrixVerG1 : 0) + (commande.PrixVerD1 ? commande.PrixVerD1 : 0)) + ((commande.PrixMont2 ? commande.PrixMont2 : 0) + (commande.PrixVerG2 ? commande.PrixVerG2 : 0) + (commande.PrixVerD2 ? commande.PrixVerD2 : 0)) + ((commande.PrixMont3 ? commande.PrixMont3 : 0) + (commande.PrixVerG3 ? commande.PrixVerG3 : 0) + (commande.PrixVerD3 ? commande.PrixVerD3 : 0))) - (commande.RemiseCom ? commande.RemiseCom : 0))
    }, 0) : 0) : 0

    return commandes
  },
  ventePrevWeekTTC: state => id => {
    const agence = state.commandes_prev_week_by_agence ? state.commandes_prev_week_by_agence.find((a) => a.officine === id) : null
    const commandes = agence ? (agence.commandes ? agence.commandes.reduce((acc, commande) => {
      return acc + ((((commande.PrixMont1 ? +commande.PrixMont1 : 0) + (commande.PrixVerG1 ? +commande.PrixVerG1 : 0) + (commande.PrixVerD1 ? commande.PrixVerD1 : 0)) + ((commande.PrixMont2 ? commande.PrixMont2 : 0) + (commande.PrixVerG2 ? commande.PrixVerG2 : 0) + (commande.PrixVerD2 ? commande.PrixVerD2 : 0)) + ((commande.PrixMont3 ? commande.PrixMont3 : 0) + (commande.PrixVerG3 ? commande.PrixVerG3 : 0) + (commande.PrixVerD3 ? commande.PrixVerD3 : 0))) - (commande.RemiseCom ? commande.RemiseCom : 0))
    }, 0) : 0) : 0

    return commandes
  },
  venteCurrentMonthTTC: state => id => {
    const agence = state.commandes_current_month_by_agence ? state.commandes_current_month_by_agence.find((a) => a.officine === id) : null
    const commandes = agence ? (agence.commandes ? agence.commandes.reduce((acc, commande) => {
      return acc + ((((commande.PrixMont1 ? +commande.PrixMont1 : 0) + (commande.PrixVerG1 ? +commande.PrixVerG1 : 0) + (commande.PrixVerD1 ? commande.PrixVerD1 : 0)) + ((commande.PrixMont2 ? commande.PrixMont2 : 0) + (commande.PrixVerG2 ? commande.PrixVerG2 : 0) + (commande.PrixVerD2 ? commande.PrixVerD2 : 0)) + ((commande.PrixMont3 ? commande.PrixMont3 : 0) + (commande.PrixVerG3 ? commande.PrixVerG3 : 0) + (commande.PrixVerD3 ? commande.PrixVerD3 : 0))) - (commande.RemiseCom ? commande.RemiseCom : 0))
    }, 0) : 0) : 0

    return commandes
  },
  ventePrevMonthTTC: state => id => {
    const agence = state.commandes_prev_month_by_agence ? state.commandes_prev_month_by_agence.find((a) => a.officine === id) : null
    const commandes = agence ? (agence.commandes ? agence.commandes.reduce((acc, commande) => {
      return acc + ((((commande.PrixMont1 ? +commande.PrixMont1 : 0) + (commande.PrixVerG1 ? +commande.PrixVerG1 : 0) + (commande.PrixVerD1 ? commande.PrixVerD1 : 0)) + ((commande.PrixMont2 ? commande.PrixMont2 : 0) + (commande.PrixVerG2 ? commande.PrixVerG2 : 0) + (commande.PrixVerD2 ? commande.PrixVerD2 : 0)) + ((commande.PrixMont3 ? commande.PrixMont3 : 0) + (commande.PrixVerG3 ? commande.PrixVerG3 : 0) + (commande.PrixVerD3 ? commande.PrixVerD3 : 0))) - (commande.RemiseCom ? commande.RemiseCom : 0))
    }, 0) : 0) : 0

    return commandes
  },
  venteCurrentYearTTC: state => id => {
    const agence = state.commandes_current_year_by_agence ? state.commandes_current_year_by_agence.find((a) => a.officine === id) : null
    const commandes = agence ? (agence.commandes ? agence.commandes.reduce((acc, commande) => {
      return acc + ((((commande.PrixMont1 ? +commande.PrixMont1 : 0) + (commande.PrixVerG1 ? +commande.PrixVerG1 : 0) + (commande.PrixVerD1 ? commande.PrixVerD1 : 0)) + ((commande.PrixMont2 ? commande.PrixMont2 : 0) + (commande.PrixVerG2 ? commande.PrixVerG2 : 0) + (commande.PrixVerD2 ? commande.PrixVerD2 : 0)) + ((commande.PrixMont3 ? commande.PrixMont3 : 0) + (commande.PrixVerG3 ? commande.PrixVerG3 : 0) + (commande.PrixVerD3 ? commande.PrixVerD3 : 0))) - (commande.RemiseCom ? commande.RemiseCom : 0))
    }, 0) : 0) : 0

    return commandes
  },
  ventePrevYearTTC: state => id => {
    const agence = state.commandes_prev_year_by_agence ? state.commandes_prev_year_by_agence.find((a) => a.officine === id) : null
    const commandes = agence ? (agence.commandes ? agence.commandes.reduce((acc, commande) => {
      return acc + ((((commande.PrixMont1 ? +commande.PrixMont1 : 0) + (commande.PrixVerG1 ? +commande.PrixVerG1 : 0) + (commande.PrixVerD1 ? commande.PrixVerD1 : 0)) + ((commande.PrixMont2 ? commande.PrixMont2 : 0) + (commande.PrixVerG2 ? commande.PrixVerG2 : 0) + (commande.PrixVerD2 ? commande.PrixVerD2 : 0)) + ((commande.PrixMont3 ? commande.PrixMont3 : 0) + (commande.PrixVerG3 ? commande.PrixVerG3 : 0) + (commande.PrixVerD3 ? commande.PrixVerD3 : 0))) - (commande.RemiseCom ? commande.RemiseCom : 0))
    }, 0) : 0) : 0

    return commandes
  }
}
