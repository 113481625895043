/*=========================================================================================
  File Name: moduleConfigsMutations.js
  Description: Configs Module Mutations
  ----------------------------------------------------------------------------------------
==========================================================================================*/

export default {
  SET_FERIE (state, feries) {
    state.feries = feries
  },
  ADD_FERIE (state, ferie) {
    state.feries.unshift(ferie)
  },
  UPDATE_FERIE (state, ferie) {
    const ferieIndex = state.feries.findIndex((f) => f._id === ferie._id)
    Object.assign(state.feries[ferieIndex], ferie)
  },
  DELETE_FERIE (state, id) {
    const ferieIndex = state.feries.findIndex((f) => f._id === id)
    state.feries.splice(ferieIndex, 1)
  },
  SET_ALERTE_CAISSE (state, data) {
    state.alertCaisse = data
  },
  SET_TYPE_SMS (state, data) {
    state.typeSms = data
  },
  ADD_TYPE_SMS (state, data) {
    state.typeSms.unshift(data)
  },
  UPDATE_TYPE_SMS (state, data) {
    const index = state.typeSms.findIndex((t) => t._id === data._id)
    Object.assign(state.typeSms[index], data)
  },
  DELETE_TYPE_SMS (state, id) {
    const index = state.typeSms.findIndex((t) => t._id === id)
    state.typeSms.splice(index, 1)
  },
  SET_SMS_CONFIG (state, data) {
    state.smsConfig = data
  },
  SET_GARANTIE (state, data) {
    state.garantie = data
  }
}
