import state from './moduleStatistiqueState.js'
import mutations from './moduleStatistiqueMutations.js'
import actions from './moduleStatistiqueActions.js'
import getters from './moduleStatistiqueGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}