/*=========================================================================================
  Nom du fichier: moduleTypeMutations.js
  Description: Type Module Mutations
  ----------------------------------------------------------------------------------------
  Author: Djezou Eric Martial
==========================================================================================*/


export default {
  SET_TYPES (state, types) {
    state.types = types
  },
  ADD_TYPE (state, type) {
    state.types.unshift(type)
  },
  UPDATE_TYPE (state, type) {
    const typeIndex = state.types.findIndex((m) => m._id === type._id)
    Object.assign(state.types[typeIndex], type)
  },
  REMOVE_RECORD (state, itemId) {
    const typeIndex = state.types.findIndex((u) => u._id === itemId)
    state.types.splice(typeIndex, 1)
  }
}
