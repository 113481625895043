/*=========================================================================================
  File Name: moduleFacturationActions.js
  Description: Facturation Module Actions
  ----------------------------------------------------------------------------------------
==========================================================================================*/

import axios from '@/axios.js'

export default {

  getCommandeNonTraiter ({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/commande-non-traiter/${id}`)
        .then((response) => {
          commit('SET_COMMANDE_NON_TRAITER', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  traitementFacture ({commit}, payload) {
    const {indexVente } = payload
    return new Promise((resolve, reject) => {
      axios.post('/api/facturation-assurance/traitement', {payload})
        .then((response) => {
          commit('VALIDE_TRAITEMENT_VENTE', indexVente)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  createFactureAssuranceModel ({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/facturation/facture-assurance-model', {payload})
        .then((response) => {
          commit('ADD_FACTURE_ASSURANCE_MODEL', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getFactureAssuranceModel ({commit}) {
    return new Promise((resolve, reject) => {
      axios.get('/api/facturation/facture-assurance-model')
        .then((response) => {
          commit('SET_FACTURE_ASSURANCE_MODEL', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  search ({commit}, payload) {
    const {dateDebut, dateFin, IdAssurance} = payload
    return new Promise((resolve, reject) => {
      axios.get(`/api/facturation/search/${dateDebut}/${dateFin}/${IdAssurance}`)
        .then((response) => {
          commit('SET_FACTURATION', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  SearchFactureByAssuranceAndSociete ({commit}, payload) {
    const {dateDebut, dateFin, IdAssurance, IdSociete} = payload
    return new Promise((resolve, reject) => {
      axios.get(`/api/facturation/search/${dateDebut}/${dateFin}/${IdAssurance}/${IdSociete}`)
        .then((response) => {
          commit('SET_FACTURATION', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  findVenteNonTraiterByAssurance ({commit}, payload) {
    const {dateDebut, dateFin, idOfficine, assurance} = payload
    return new Promise((resolve, reject) => {
      axios.get(`/api/facturation/vente-non-traiter/${dateDebut}/${dateFin}/${idOfficine}/${assurance}`)
        .then((response) => {
          commit('SET_COMMANDE_NON_TRAITER', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  SaveFactureAssurance ({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/facturation/save-facture-assurance', {payload})
        .then((response) => {
          commit('RESET_FACTURATION')
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  removeVenteAgence ({commit}, payload) {
    const { id } = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/facturation/remove-facture/${id}`)
        .then((response) => {
          commit('REMOVE_VENTE_ASSURANCE', id)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getFactureAssuranceADepose ({commit}) {
    return new Promise((resolve, reject) => {
      axios.get('/api/facturation/facture-assurance-a-depose')
        .then((response) => {
          commit('SET_FACTURE_A_DEPOSER', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  DepotFactureAssurance ({commit}, payload) {
    const {id} = payload
    return new Promise((resolve, reject) => {
      axios.put('/api/facturation/depot-facture-assurance', {payload})
        .then((response) => {
          commit('DELETE_FACTURE_A_DEPOSER', id)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  factureAssurancePdfGenerator ({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/facturation/assurance/pdf-generate', {payload}, {responseType: 'stream'})
        .then((response) => {
          commit('UPDATE_FACTURE_INFO', response.data)
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  factureAssuranceClientDetailPdfGenerator ({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/facturation/assurance/detail/pdf-generate', {payload}, {responseType: 'stream'})
        .then((response) => {
          commit('UPDATE_FACTURE_INFO', response.data.data)
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  getFactureAssuranceDepose ({commit}) {
    return new Promise((resolve, reject) => {
      axios.get('/api/facturation/facture-assurance-depose')
        .then((response) => {
          commit('SET_FACTURE_DEPOSER', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  archiveFactureAssurance ({commit}, payload) {
    const {id} = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/facturation/assurance/archive/${id}`)
        .then((response) => {
          if (response.data.message === 'archive impossible') {
            resolve(response)
          } else {
            commit('ARCHIVE_FACTURE_DEPOSER', id)
            resolve(response)
          }

        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  soldeIndividualFactureAssurance ({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.put('/api/facturation/facture-assurance/reglement/byfacture-client', {payload})
        .then((response) => {
          commit('UPDATE_FACTURE_DEPOSER', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  soldeAllFactureAssurance ({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.put('/api/facturation/facture-assurance/reglement/allfacture-client', {payload})
        .then((response) => {
          console.log('data =>', response.data)
          commit('UPDATE_FACTURE_DEPOSER', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

}
