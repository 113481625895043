import axios from '@/axios.js'

export default {
  getContactByUserId ({ commit }, id_user) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/contact/user/${id_user}`)
        .then((response) => {
          commit('SET_CONTACT', response.data.contacts)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  searchContact (context, searchValue) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/search-contacts/${searchValue}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getContactById (context, id) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/contact/${id}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getContactBySlug (context, slug) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/contact/slug/${slug}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  createContact ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/contact/create', { payload })
        .then((response) => {
          commit('ADD_CONTACT', response.data.contact)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateSuspect ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.put('/api/contact/update', { payload })
        .then((response) => {
          commit('UPDATE_CONTACT', response.data.contact)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  softdeleteSuspect ({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.put(`/api/contact/softdelete/${id}`)
        .then((response) => {
          commit('DELETE_CONTACT', id)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}