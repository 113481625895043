/*=========================================================================================
  File Name: moduleCommandeComptoireState.js
  Description: CommandeComptoire Module State
  ----------------------------------------------------------------------------------------
==========================================================================================*/

export default {
  commande_comptoires: [],
  commandes_comptoire_jour: [],
  commandes_comptoire_jour_precedent: [],
  commandes_comptoire_today_by_agence: [],
  commandes_comptoire_toDay: [],
  commandes_comptoire_hier_by_agence: [],
  commandes_comptoire_hier: [],
  commandes_comptoire_current_week_by_agence: [],
  commandes_comptoire_current_week: [],
  commandes_comptoire_prev_week_by_agence: [],
  commandes_comptoire_prev_Week: [],
  commandes_comptoire_current_month_by_agence: [],
  commandes_comptoire_current_month: [],
  commandes_comptoire_prev_month_by_agence: [],
  commandes_comptoire_prev_month: [],
  commandes_comptoire_current_year_by_agence: [],
  commandes_comptoire_current_year: [],
  commandes_comptoire_prev_year_by_agence: [],
  commandes_comptoire_prev_year: []
}
