/*=========================================================================================
  File Name: moduleStockState.js
  Description: Stock Module State
  ----------------------------------------------------------------------------------------
==========================================================================================*/

export default {
  articles: [],
  articles_sortie: [],
  articles_transferer: []
}
