/*=========================================================================================
  File Name: moduleVersementBangAction.js
  Description: action of module Versement Banque
  ----------------------------------------------------------------------------------------
  Author: Eudoxie Djetouan
  Author email: eudoxiedjetouan@gmail.com
==========================================================================================*/

import axios from '@/axios.js'

export default {

// get all Assurance
  fetchVersementBanq ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/versements/caisse')
        .then((response) => {
          commit('SET_VERSEMENT_BANQUE', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchVersementBanqByAgence ({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/versements/caisse/${id}`)
        .then((response) => {
          commit('SET_VERSEMENT_BANQUE', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  versement ({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/operation/versement', {payload})
        .then((response) => {
          commit('ADD_VERSEMENT', response.data.versement)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  validedVersement ({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.put(`/api/operation/valided-versement/${id}`)
        .then((response) => {
          commit('UPDATE_VERSEMENT', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  ArchiveVersement ({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.put(`/api/operation/archive-versement/${id}`)
        .then((response) => {
          commit('REMOVE_VERSEMENT', id)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }


}
