/*=========================================================================================
  M.Djezou
  File Name: moduleRubriqueDepense.js
  Description: Rubrique Depense Module
  ----------------------------------------------------------------------------------------

==========================================================================================*/


import state from './moduleRubriqueDepenseState.js'
import mutations from './moduleRubriqueDepenseMutations.js'
import actions from './moduleRubriqueDepenseActions.js'
import getters from './moduleRubriqueDepenseGetters.js'

export default {

  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

