/*=========================================================================================
  File Name: moduleTypeGetters.js
  Description: Type Module Getters
  ----------------------------------------------------------------------------------------
==========================================================================================*/


export default {
  getTypeById:  state => id  => {
    return state.types.find((f) => f._id === id)
  }
}
