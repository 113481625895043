/*=========================================================================================
  File Name: moduleCentreHospitalierActions.js
  Description: Centre Hospitaliers Module Actions
  ----------------------------------------------------------------------------------------
==========================================================================================*/

import axios from '@/axios.js'

export default {

  addCentreHospitalier ({ commit }, payload) {

    return new Promise((resolve, reject) => {
      axios.post('/api/add/centre-hospitalier', { payload })
        .then((response) => {
          commit('ADD_CENTRE_HOSPITALIER', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateCentreHospitalier ({commit}, payload) {
    const { id } = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/centre-hospitalier/update/${id}`, { payload })
        .then((response) => {
          commit('UPDATE_CENTRE_HOSPITALIER', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getCentreHospitalier ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/centre-hospitaliers')
        .then((response) => {
          commit('SET_CENTRE_HOSPITALIERS', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  getCentreHospitalierById ({ context }, id) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/centre-hospitalier/${id}`)
        .then((response) => {
          console.log(context) 
          resolve(response) 
        })
        .catch((error) => { reject(error) })
    })
  },

  removeCentreHospitalier ({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/centre-hospitalier/${id}`)
        .then((response) => {
          commit('REMOVE_RECORD', id)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
