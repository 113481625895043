import axios from '@/axios.js'

export default {
  getOpportunitesByUser ({ commit }, id_user) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/opportunites-user/${id_user}`)
        .then((response) => {
          commit('SET_OPPORTUNITE', response.data.opportunites)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getOpportuniteById (context, id) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/opportunite/${id}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getOpportunitesByProprietaire (context, id) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/opportunites-pro/${id}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getOpportuniteBySlug (context, slug) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/opportunite/slug/${slug}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  createOpportunite ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/opportunite/create', { payload })
        .then((response) => {
          commit('ADD_OPPORTUNITE', response.data.opportunite)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateOpportunite ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.put('/api/opportunite/update', { payload })
        .then((response) => {
          commit('UPDATE_OPPORTUNITE', response.data.opportunite)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // updateSuspectStatus ({ commit }, payload) {
  //   const { suspects, status_suspect } = payload
  //   suspects.forEach(suspect => {
  //     const _payload = {
  //       id: suspect._id,
  //       status_suspect
  //     }
  //     return new Promise((resolve, reject) => {
  //       axios.put('/api/suspect/update/status', { _payload })
  //         .then((response) => {
  //           commit('UPDATE_SUSPECT', response.data.suspect)
  //           resolve(response)
  //         })
  //         .catch((error) => {
  //           reject(error)
  //         })
  //     })
  //   })
  // },
  MarquerOpportuniteEnCour (context, payload) {
    return new Promise((resolve, reject) => {
      axios.put('/api/opportunite/marquer', { payload })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  CloseEtapeOpportunite (context, payload) {
    return new Promise((resolve, reject) => {
      axios.put('/api/opportunite/close-etape', { payload })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  softdeleteOpportunite ({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.put(`/api/opportunite/softdelete/${id}`)
        .then((response) => {
          commit('DELETE_OPPORTUNITE', id)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}