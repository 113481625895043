export default {
  nombre_client: [],
  nombre_genre_client: [],
  civilites: [
    {
      text: 'Prof',
      value: 'Prof'
    },
    {
      text: 'Dr',
      value: 'Dr'
    },
    {
      text: 'M',
      value: 'M'
    },
    {
      text: 'Mme',
      value: 'Mme'
    },
    {
      text: 'Mlle',
      value: 'Mlle'
    },
    {
      text: 'Enft',
      value: 'Enft'
    }
  ]
}