/*=========================================================================================
  File Name: modulePaysMutations.js
  Description: Pays Module Mutations
  ----------------------------------------------------------------------------------------
==========================================================================================*/

export default {
  SET_PAYS (state, pays) {
    state.pays = pays
  },
  ADD_PAYS (state, pays) {
    state.pays.unshift(pays)
  },
  UPDATE_PAYS (state, pays) {
    const paysIndex = state.pays.findIndex((p) => p._id === pays._id)
    Object.assign(state.pays[paysIndex], pays)
  },
  REMOVE_RECORD (state, itemId) {
    const paysIndex = state.pays.findIndex((p) => p._id === itemId)
    state.pays.splice(paysIndex, 1)
  },
  SET_VILLES (state, villes) {
    state.villes = villes
  },
  ADD_VILLE (state, ville) {
    state.villes.unshift(ville)
  },
  UPDATE_VILLE (state, ville) {
    const villeIndex = state.villes.findIndex((v) => v._id === ville._id)
    Object.assign(state.villes[villeIndex], ville)
  },
  REMOVE_VILLE (state, itemId) {
    const villeIndex = state.villes.findIndex((v) => v._id === itemId)
    state.villes.splice(villeIndex, 1)
  },
  SET_QUARTIERS (state, quartiers) {
    state.quartiers = quartiers
  },
  ADD_QUARTIER (state, quartier) {
    state.quartiers.unshift(quartier)
  },
  UPDATE_QUARTIER (state, quartier) {
    const quartierIndex = state.quartiers.findIndex((v) => v._id === quartier._id)
    Object.assign(state.quartiers[quartierIndex], quartier)
  },
  REMOVE_QUARTIER (state, itemId) {
    const quartierIndex = state.quartiers.findIndex((v) => v._id === itemId)
    state.quartiers.splice(quartierIndex, 1)
  }
}
