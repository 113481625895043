/*=========================================================================================
  M.Djezou
  File Name: moduleOperationMutations.js
  Description: Operation Module Mutations
  ----------------------------------------------------------------------------------------
==========================================================================================*/

export default {
  SET_BILLETAGE (state, billetage) {
    state.billetage = billetage
  }
}
