/*=========================================================================================
 M.Djezou
  File Name: moduleInventaireMutations.js
  Description: Inventaire Module Mutations
  ----------------------------------------------------------------------------------------
==========================================================================================*/

export default {
  SET_INVENTAIRES (state, inventaires) {
    state.inventaires = inventaires
  },
  ADD_INVENTAIRE (state, inventaire) {
    state.inventaires.unshift(inventaire)
  },
  UPDATE_INVENTAIRE (state, inventaire) {
    const inventaireIndex = state.inventaires.findIndex((i) => i._id === inventaire._id)
    Object.assign(state.inventaires[inventaireIndex], inventaire)
  },
  REMOVE_RECORD (state, itemId) {
    const inventaireIndex = state.inventaires.findIndex((i) => i._id === itemId)
    state.inventaires.splice(inventaireIndex, 1)
  }
}

