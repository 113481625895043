/*=========================================================================================
  File Name: moduleStyle.js
  Description: Style Module
  ----------------------------------------------------------------------------------------
==========================================================================================*/


import state from './moduleStyleState.js'
import mutations from './moduleStyleMutations.js'
import actions from './moduleStyleActions.js'
import getters from './moduleStyleGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

