import axios from '@/axios.js'

export default {
  addType ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/type/create', { payload })
        .then((response) => {
          commit('ADD_TYPE', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateType ({commit}, payload) {
    const {id} = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/type/update/${id}`, { payload })
        .then((response) => {
          commit('UPDATE_TYPE', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getTypes ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/types')
        .then((response) => {
          commit('SET_TYPES', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  getType (context, typeId) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/type/${typeId}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },


  removeType ({ commit }, typeId) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/type/drop/${typeId}`)
        .then((response) => {
          commit('REMOVE_RECORD', typeId)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
