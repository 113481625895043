/*=========================================================================================
  File Name: moduleDepenseActions.js
  Description: Depense Module Actions
  ----------------------------------------------------------------------------------------
==========================================================================================*/

import axios from '@/axios.js'

export default {
  makeDepense ({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/make-depense', {payload})
        .then((response) => {
          commit('ADD_DEPENSE', response.data)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  approCaisseDepnse ({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/appro-caisse-depense', {payload})
        .then((response) => {
          commit('ADD_APPRO_CAISSE_DEPNSE', response.data)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getDepenseOnSelectDate ({commit}, payload) {
    const {dateOp, Officine} = payload
    return new Promise((resolve, reject) => {
      axios.get(`/api/depense/${Officine}/${dateOp}`)
        .then((response) => {
          commit('SET_DEPENSE', response.data)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getDepenseToDayForDash ({commit}, payload) {
    const {dateOp, Officine} = payload
    return new Promise((resolve, reject) => {
      axios.get(`/api/depense/${Officine}/${dateOp}`)
        .then((response) => {
          const data = {
            OfficineId: Officine,
            Depenses: response.data
          }
          commit('ADD_DEPENSE_TODAY_BY_AGENCE', data)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getDepensePrevDayForDash ({commit}, payload) {
    const {dateOp, Officine} = payload
    return new Promise((resolve, reject) => {
      axios.get(`/api/depense/${Officine}/${dateOp}`)
        .then((response) => {
          const data = {
            OfficineId: Officine,
            Depenses: response.data
          }
          commit('ADD_DEPENSE_PREVDAY_BY_AGENCE', data)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getApproOnSelectDate ({commit}, payload) {
    const {dateOp, Officine} = payload
    return new Promise((resolve, reject) => {
      axios.get(`/api/appro-caisse-depense/${Officine}/${dateOp}`)
        .then((response) => {
          commit('SET_APPRO_CAISSE_DEPENSE', response.data)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  initialisationCaisseDepenseAvecClotureCaisse (context, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/init-caisse-depense', {payload})
        .then((response) => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  initialisationCaisseDepense (context, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/init-caisse-depense-direct', {payload})
        .then((response) => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

}
