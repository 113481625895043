/*=========================================================================================
  File Name: moduleCommandeFournState.js
  Description: CommandeFourn Module State
  ----------------------------------------------------------------------------------------
==========================================================================================*/

export default {
  commande_fourns: [],
  pager: null,
  pageOfItems: null,
  commande_fourns_transferer: []
}
