/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
  SET_STYLES (state, styles) {
    state.styles = styles
  },
  ADD_STYLE (state, style) {
    state.styles.unshift(style)
  },
  UPDATE_STYLE (state, style) {
    const styleIndex = state.styles.findIndex((m) => m._id === style._id)
    Object.assign(state.styles[styleIndex], style)
  },
  REMOVE_RECORD (state, itemId) {
    const styleIndex = state.styles.findIndex((u) => u._id === itemId)
    state.styles.splice(styleIndex, 1)
  }
}
