import axios from '@/axios.js'

export default {

  getFournisseursVerres ({commit}) {
    return new Promise((resolve, reject) => {
      axios.get('/api/fournisseurs-verres')
        .then((resp) => {
          commit('SET_FOURNISSEUR_VERRE', resp.data)
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  addFournisseurVerre ({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/fournisseur-verre/create', {payload})
        .then((resp) => {
          commit('ADD_FOURNISSEUR_VERRE', resp.data)
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  updateFournisseurVerre ({commit}, payload) {
    const {id} = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/fournisseur-verre/update/${id}`, {payload})
        .then((resp) => {
          commit('UPDATE_FOURNISSEUR_VERRE', resp.data)
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  RemoveFournisseurVerre ({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/fournisseur-verre/remove/${id}`)
        .then((resp) => {
          commit('REMOVE_FOURNISSEUR_VERRE', id)
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  getCatalogueVerre ({commit}) {
    return new Promise((resolve, reject) => {
      axios.get('/api/catalogue-verre')
        .then((resp) => {
          commit('SET_CATALOGUE_VERRES', resp.data)
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  addCatalogueVerre ({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/catalogue-verre/create', {payload})
        .then((resp) => {
          commit('ADD_CATALOGUE_VERRE', resp.data)
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  updateCalalogueVerre ({commit}, payload) {
    const {id} = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/catalogue-verre/update/${id}`, {payload})
        .then((resp) => {
          commit('UPDATE_CATALOGUE_VERRE', resp.data)
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  removeCatalogueVerre ({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/catalogue-verre/remove/${id}`)
        .then((resp) => {
          commit('REMOVE_CATALOGUE_VERRE', id)
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  filterGammeVerre ({commit}, filterObj) {
    //console.log(filterObj)
    commit('UPDATE_GAMME_VERRE_FILTER', filterObj)
  },

  AddGammeDeVerre ({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/gamme-verre/create', {payload})
        .then((resp) => {
          commit('UPDATE_GAMME_VERRE_FILTER', resp.data)
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  getGammeVerres ({commit}) {
    return new Promise((resolve, reject) => {
      axios.get('/api/gammes-verres')
        .then((resp) => {
          commit('SET_GAMME_VERRE', resp.data)
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updateGammeVerre ({commit}, payload) {
    const {id} = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/gamme-verre/update/${id}`, {payload})
        .then((resp) => {
          commit('UPDATE_GAMME_VERRE', resp.data)
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  removeGammeVerre ({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/gamme-verre/remove/${id}`)
        .then((resp) => {
          commit('REMOVE_GAMME_VERRE', id)
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  AddTypeDeVerre ({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/type-verre/create', {payload})
        .then((resp) => {
          commit('ADD_TYPE_VERRE', resp.data)
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })

    })
  },
  getTypeDeVerres ({commit}) {
    return new Promise((resolve, reject) => {
      axios.get('/api/type-verres')
        .then((resp) => {
          commit('SET_TYPE_VERRE', resp.data)
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updateTypeDeVerre ({commit}, payload) {
    const {id} = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/type-verre/update/${id}`, {payload})
        .then((resp) => {
          commit('UPDATE_TYPE_VERRE', resp.data)
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  removeTypeDeVerre ({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/type-verre/remove/${id}`)
        .then((resp) => {
          commit('REMOVE_TYPE_VERRE', id)
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  AddMatiereVerre ({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/matiere-verre/create', {payload})
        .then((resp) => {
          commit('ADD_MATIERE_VERRE', resp.data)
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getMatieresVerre ({commit}) {
    return new Promise((resolve, reject) => {
      axios.get('/api/matieres-verres')
        .then((resp) => {
          commit('SET_MATIERE_VERRE', resp.data)
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updateMatiereVerre ({commit}, payload) {
    const {id} = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/matiere-verre/update/${id}`, {payload})
        .then((resp) => {
          commit('UPDATE_MATIERE_VERRE', resp.data)
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  removeMatiereVerre ({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/matiere-verre/remove/${id}`)
        .then((resp) => {
          commit('REMOVE_TYPE_VERRE', id)
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  AddIndiceVerre ({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/indice-verre/create', {payload})
        .then((resp) => {
          commit('ADD_INDICE_VERRE', resp.data)
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getIndiceVerre ({commit}) {
    return new Promise((resolve, reject) => {
      axios.get('/api/indices-verres')
        .then((resp) => {
          commit('SET_INDICES_VERRE', resp.data)
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updateIndiceVerre ({commit}, payload) {
    const {id} = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/indice-verre/update/${id}`, {payload})
        .then((resp) => {
          commit('UPDATE_INDICE_VERRE', resp.data)
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  removeIndiceVerre ({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/indice-verre/remove/${id}`)
        .then((resp) => {
          commit('REMOVE_INDICE_VERRE', resp.data)
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  filterVersionVerre ({commit}, filterObj) {
    //console.log(filterObj)
    commit('UPDATE_VERSION_FILTER', filterObj)
  },

  AddVersionVerre ({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/version-verre/create', {payload})
        .then((resp) => {
          commit('ADD_VERSION', resp.data)
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getVersionVerre ({commit}) {
    return new Promise((resolve, reject) => {
      axios.get('/api/version-verres')
        .then((resp) => {
          commit('SET_VERSION', resp.data)
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updateVersionVerre ({commit}, payload) {
    const {id} = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/version-verre/update/${id}`, {payload})
        .then((resp) => {
          commit('UPDATE_VERSION', resp.data)
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  removeVersionVerre ({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/version-verre/remove/${id}`)
        .then((resp) => {
          commit('REMOVE_VERSION', resp.data)
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  AddTeinte ({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/teinte-verre/create', {payload})
        .then((resp) => {
          commit('ADD_TEINTE', resp.data)
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getTeinte ({commit}) {
    return new Promise((resolve, reject) => {
      axios.get('/api/teinte-verres')
        .then((resp) => {
          commit('SET_TEINTE', resp.data)
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updateTeinte ({commit}, payload) {
    const {id} = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/teinte-verre/update/${id}`, {payload})
        .then((resp) => {
          commit('UPDATE_TEINTE', resp.data)
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  removeTeinte ({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/teinte-verre/remove/${id}`)
        .then((resp) => {
          commit('REMOVE_TEINTE', id)
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  filterTraitementVerre ({commit}, filterObj) {
    //console.log(filterObj)
    commit('UPDATE_TRAITEMENT_FILTER', filterObj)
  },

  AddTraitement ({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/traitement-verre/create', {payload})
        .then((resp) => {
          commit('ADD_TRAITEMENT', resp.data)
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  getTraitement ({commit}) {
    return new Promise((resolve, reject) => {
      axios.get('/api/traitement-verre')
        .then((resp) => {
          commit('SET_TRAITEMENT', resp.data)
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  updateTraitement ({commit}, payload) {
    const {id} = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/traitement-verre/update/${id}`, {payload})
        .then((resp) => {
          commit('UPDATE_TRAITEMENT', resp.data)
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  removeTraitement ({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/traitement-verre/remove/${id}`)
        .then((resp) => {
          commit('REMOVE_TRAITEMENT', resp.data)
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  AddColoration ({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/coloration-verre/create', {payload})
        .then((resp) => {
          commit('ADD_COLORATION', resp.data)
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  filterColorationVerre ({commit}, filterObj) {
    //console.log(filterObj)
    commit('UPDATE_COLORATION_FILTER', filterObj)
  },

  getColorations ({commit}) {
    return new Promise((resolve, reject) => {
      axios.get('/api/coloration-verre')
        .then((resp) => {
          commit('SET_COLORATION', resp.data)
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  updateColoration ({commit}, payload) {
    const {id} = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/coloration-verre/update/${id}`, {payload})
        .then((resp) => {
          commit('UPDATE_COLORATION', resp.data)
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  removeColoration ({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/coloration-verre/remove/${id}`)
        .then((resp) => {
          commit('REMOVE_COLORATION', resp.data)
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  filterFabricationVerre ({commit}, filterObj) {
    //console.log(filterObj)
    commit('UPDATE_FABRICATION_FILTER', filterObj)
  },

  AddFabrication ({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/fabrication-verre/create', {payload})
        .then((resp) => {
          commit('ADD_FABRICATION', resp.data)
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  getFabrications ({commit}) {
    return new Promise((resolve, reject) => {
      axios.get('/api/fabrication-verre')
        .then((resp) => {
          commit('SET_FABRICATION', resp.data)
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  updateFabrication ({commit}, payload) {
    const {id} = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/fabrication-verre/update/${id}`, {payload})
        .then((resp) => {
          commit('UPDATE_FABRICATION', resp.data)
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  removeFabrication ({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/fabrication-verre/remove/${id}`)
        .then((resp) => {
          commit('REMOVE_FABRICATION', resp.data)
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  AddModeleVerre ({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/modele-verre/create', {payload})
        .then((resp) => {
          commit('ADD_MODELE_VERRE', resp.data)
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getModeleVerre ({commit}) {
    return new Promise((resolve, reject) => {
      axios.get('/api/modeles-verres')
        .then((resp) => {
          commit('SET_MODELE_VERRE', resp.data)
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updateModeleVerre ({commit}, payload) {
    const {id} = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/modele-verre/update/${id}`, {payload})
        .then((resp) => {
          commit('UPDATE_MODELE_VERRE', resp.data)
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  removeModeleVerre ({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/modele-verre/remove/${id}`)
        .then((resp) => {
          commit('REMOVE_MODELE_VERRE', resp.data)
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  SaveMatricePrix ({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/matrice-prix/create', {payload})
        .then((resp) => {
          commit('SET_MATRICE_PRIX', resp.data)
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getMatricePrix ({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/matrice-prix/${id}`)
        .then((resp) => {
          commit('SET_MATRICE_PRIX', resp.data)
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getMatricePrixAll ({commit}) {
    return new Promise((resolve, reject) => {
      axios.get('/api/matrice-prix/all')
        .then((resp) => {
          commit('SET_MATRICE_PRIX', resp.data)
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getMatricePrixBySphInfSphSupAndCylInfCylSup ({commit}, payload) {
    const {sph, cyl} = payload
    return new Promise((resolve, reject) => {
      axios.get(`/api/matrice-prix/prescription/${sph}/${cyl}`)
        .then((resp) => {
          commit('SET_MATRICE_PRIX', resp.data)
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updateMatricePrix ({commit}, payload) {
    const {id} = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/matrice-prix/update/${id}`, {payload})
        .then((resp) => {
          commit('SET_MATRICE_PRIX', resp.data)
          resolve(resp)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}
