/*=========================================================================================
  File Name: moduleConsultationMutations.js
  Description: Consultation Module Mutations
  ----------------------------------------------------------------------------------------
==========================================================================================*/

export default {
  SET_CONSULTATION (state, consultations) {
    state.consultations = consultations
  },
  ADD_CONSULTATION (state, consultation) {
    state.consultations.unshift(consultation)
  },
  ARCHIVE_CONSULTATION (state, id) {
    const consultationIndex = state.consultations.findIndex((d) => d._id === id)
    state.consultations.splice(consultationIndex, 1)
  }
}
