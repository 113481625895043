/*=========================================================================================
  Nom du fichier: moduleOpportuniteMutations.js
  Description: Opportunite Module Mutations
  ----------------------------------------------------------------------------------------
  Author: Djezou Eric Martial
==========================================================================================*/

export default {
  SET_OPPORTUNITE (state, data) {
    state.opportunites = data
  },
  ADD_OPPORTUNITE (state, data) {
    state.opportunites.unshift(data)
  },
  UPDATE_OPPORTUNITE (state, data) {
    const index = state.opportunites.length > 0 ? state.opportunites.findIndex((s) => s._id === data._id) : null

    if (index === 0 || index !== null) {
      Object.assign(state.opportunites[index], data)
    }
  },
  DELETE_OPPORTUNITE (state, id) {
    const index = state.opportunites.length > 0 ? state.opportunites.findIndex((s) => s._id === id) : null
    state.opportunites.splice(index, 1)
  }
}