/*=========================================================================================
  File Name: moduleVersementBanq.js
  Description: module versement banque
  ----------------------------------------------------------------------------------------
  Author: Eudoxie Djetouan
  Author email: eudoxiedjetouan@gmail.com
==========================================================================================*/


import state from './moduleVersementBanqState.js'
import mutations from './moduleVersementBanqMutations.js'
import actions from './moduleVersementBanqActions.js'
import getters from './moduleVersementBanqGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

