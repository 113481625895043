/*=========================================================================================
  File Name: moduleApproDepense.js
  Description: module assurance
  ----------------------------------------------------------------------------------------
  Author: Eudoxie Djetouan
  Author email: eudoxiedjetouan@gmail.com
==========================================================================================*/


import state from './moduleApproDepenseState.js'
import mutations from './moduleApproDepenseMutations.js'
import actions from './moduleApproDepenseActions.js'
import getters from './moduleApproDepenseGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

