/*=========================================================================================
  M.Djezou
  File Name: moduleOfficine.js
  Description: Officine Module
  ----------------------------------------------------------------------------------------

==========================================================================================*/


import state from './moduleRoleState.js'
import mutations from './moduleRoleMutations.js'
import actions from './moduleRoleActions.js'
import getters from './moduleRoleGetters.js'

export default {

  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

