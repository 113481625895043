/*=========================================================================================
  File Name: moduleApproDepenseState.js
  Description: state of module Approvisionnement depense
  ----------------------------------------------------------------------------------------
  Author: Eudoxie Djetouan
  Author email: eudoxiedjetouan@gmail.com
==========================================================================================*/

export default {
  approDenses: []
}
  