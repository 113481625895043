/*=========================================================================================
  File Name: store.js
  Description: Vuex store
  ----------------------------------------------------------------------------------------
==========================================================================================*/


import Vue from 'vue'
import Vuex from 'vuex'

import state from './state'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

Vue.use(Vuex)

import moduleCalendar from './calendar/moduleCalendar.js'
import moduleAssurance from './assurance/moduleAssurance.js'
import moduleAuth from './auth/moduleAuth.js'
import moduleUserManagement from './user-management/moduleUserManagement.js'
import moduleFonction from './fonction/moduleFonction.js'
import modulePersonnel from './personnel/modulePersonnel.js'
import moduleOfficine from './officine/moduleOfficine.js'
import moduleCommandeFourn from './commandeFournisseur/moduleCommandeFourn.js'
import moduleFournisseur from './fournisseur/moduleFournisseur.js'
import moduleCategorieArticle from './categorieArticle/moduleCategorieArticle.js'
import moduleSociete from './societe/moduleSociete.js'
import moduleBanque from './banque/moduleBanque.js'
import moduleType from './type/moduleType.js'
import moduleStyle from './style/moduleStyle.js'
import moduleFonddecaisse from './fonddecaisse/moduleFonddecaisse.js'
import moduleAppro_caisse_recette from './appro_caisse_recette/moduleAppro_caisse_recette.js'
import moduleProfession from './profession/moduleProfession.js'
import moduleVersementBanq from './versementBanque/moduleVersementBanq.js'
import moduleApproDepense from './approDense/moduleApproDepense.js'
import modulePays from './pays/modulePays.js'
import moduleCentreHospitalier from './centre-hospitalier/moduleCentreHospitalier.js'
import modulePrescripteur from './prescripteur/modulePrescripteur.js'
import moduleMarquie from './marque/moduleMarque.js'
import modulePartenaire from './partenaire/modulePartenaire.js'
import moduleClient from './client/moduleClient.js'
import moduleProforma from './proforma/moduleProforma.js'
import moduleCommandeComptoire from './commandeComptoire/moduleCommandeComptoire.js'
import moduleStock from './stock/moduleStock.js'
import modeuleOperation from './operation/moduleOperation.js'
import moduleRole from './role/moduleRole.js'
import moduleFacturation from './facturation/moduleFacturation.js'
import moduleVerre from './verre/moduleVerre'
import moduleEtat from './etat/moduleEtat.js'
import moduleBillage from './billetage/moduleBilletage.js'
import moduleDepense from './depense/moduleDepense.js'
import moduleEntetePiedpage from './entete_piedpage/moduleEntetePiedpage.js'
import moduleBillageDepense from './billetage_depense/moduleBilletageDepense.js'
import moduleInventaire from './inventaire/moduleInventaire.js'
import moduleConsultation from './consultation/moduleConsultation.js'
import moduleSmsMarketing from './sms-marketing/moduleSmsMarketing.js'
import moduleConfigs from './configs/moduleConfigs.js'
import moduleCommandeVerre from './commande_verre/moduleCommandeVerre.js'
import moduleAtelier from './atelier/moduleAtelier.js'
import moduleStockVerre from './stock-verre/moduleStockVerre.js'
import moduleRubriqueDepense from './rubrique_depense/moduleRubriqueDepense.js'
import moduleSuspect from './suspect/moduleSuspect.js'
import moduleCompte from './compte/moduleCompte.js'
import moduleContact from './contact/moduleContact.js'
import moduleOpportunite from './opportunite/moduleOpportunite.js'
import moduleStatistique from './statistique/moduleStatistique.js'


export default new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  modules: {
    calendar: moduleCalendar,
    userManagement: moduleUserManagement,
    fonction: moduleFonction,
    personnel: modulePersonnel,
    auth: moduleAuth,
    societe: moduleSociete,
    banque: moduleBanque,
    categorie_article: moduleCategorieArticle,
    type: moduleType,
    style: moduleStyle,
    fonddecaisse: moduleFonddecaisse,
    appro_caisse_recette: moduleAppro_caisse_recette,
    profession: moduleProfession,
    fournisseur: moduleFournisseur,
    versementBanque : moduleVersementBanq,
    approDepense : moduleApproDepense,
    assurance: moduleAssurance,
    officine: moduleOfficine,
    commande_fourn: moduleCommandeFourn,
    pays: modulePays,
    centre_hospitalier: moduleCentreHospitalier,
    prescripteur: modulePrescripteur,
    marque: moduleMarquie,
    partenaire: modulePartenaire,
    client: moduleClient,
    proforma: moduleProforma,
    commande_comptoire: moduleCommandeComptoire,
    stock: moduleStock,
    operation: modeuleOperation,
    role: moduleRole,
    facturation: moduleFacturation,
    verre: moduleVerre,
    etat: moduleEtat,
    billetage: moduleBillage,
    depense: moduleDepense,
    entete_piedpage: moduleEntetePiedpage,
    billetage_depense: moduleBillageDepense,
    inventaire: moduleInventaire,
    consultation: moduleConsultation,
    sms_marketing: moduleSmsMarketing,
    configs: moduleConfigs,
    commande_verre: moduleCommandeVerre,
    atelier: moduleAtelier,
    stockVerre: moduleStockVerre,
    rubrique_depense: moduleRubriqueDepense,
    suspect: moduleSuspect,
    compte: moduleCompte,
    contact: moduleContact,
    opportunite: moduleOpportunite,
    statistique: moduleStatistique

  },
  strict: process.env.NODE_ENV !== 'production'
})
