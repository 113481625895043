/*=========================================================================================
  M.Djezou
  File Name: moduleInventaireState.js
  Description: Inventaire Module State
  ----------------------------------------------------------------------------------------
==========================================================================================*/

export default {
  inventaires: []
}

