/*=========================================================================================
  M.Djezou
  File Name: moduleBilletageDepense.js
  Description: BilletageDepense Module
  ----------------------------------------------------------------------------------------

==========================================================================================*/


import state from './moduleBilletageDepenseState.js'
import mutations from './moduleBilletageDepenseMutations.js'
import actions from './moduleBilletageDepenseActions.js'
import getters from './moduleBilletageDepenseGetters.js'

export default {

  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

