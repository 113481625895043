/*=========================================================================================
  File Name: moduleCalendarState.js
  Description: Calendar Module State
==========================================================================================*/

export default {

  // Calendar Labels
  eventLabels: [],

  // Simple Calendar State
  events: []
}
