/*=========================================================================================
  File Name: moduleSociete.js
  Description: Societe Module
  ----------------------------------------------------------------------------------------
==========================================================================================*/


import state from './moduleStockVerreState.js'
import mutations from './moduleStockVerreMutations.js'
import actions from './moduleStockVerreActions.js'
import getters from './moduleStockVerreGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

