
import axios from '@/axios.js'

export default {

// get all Client
  getClientsGroup ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/client/groupe')
        .then((response) => {
          commit('SET_CLIENTS', response.data.clients)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  getClientsOfficine ({ commit }, officineId) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/client/${officineId}`)
        .then((response) => {
          commit('SET_CLIENTS', response.data.clients)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  getClientsWithBirthdayToday ({ commit }, payload) {
    const {idOfficine, selectDate} = payload
    return new Promise((resolve, reject) => {
      axios.get(`/api/client/birthday-today/${idOfficine}/${selectDate}`)
        .then((response) => {
          //console.log(response.data)
          commit('SET_CLIENTS_BIRTHDAY', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  getCommandeBirthdayToday ({ commit }, payload) {
    const {idOfficine, selectDate} = payload
    return new Promise((resolve, reject) => {
      axios.get(`/api/client/commande-birthday/${idOfficine}/${selectDate}`)
        .then((response) => {
          //console.log(response.data)
          commit('SET_COMMANDES_BIRTHDAY', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  getClientById (context, clientId) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/client/single/${clientId}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  getClientName (context, clientId) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/client/name/${clientId}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  addClient ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/client/create', { payload })
        .then((response) => {
          console.log(response.data.data)
          commit('ADD_CLIENT', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  updateClient ({commit}, payload) {
    const {id} = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/client/update/${id}`, { payload })
        .then((response) => {
          commit('UPDATE_CLIENT', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },


  removeClient ({ commit }, clientId) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/client/drop/${clientId}`)
        .then((response) => {
          commit('REMOVE_CLIENT', clientId)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  getOrdonnances ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/ordonnances')
        .then((response) => {
          commit('SET_ORDONNANCES', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  getOrdonnanceById (context, ordonnanceId) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/ordonnance/${ordonnanceId}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  addOrdonnance ({ commit }, payload) {
    const { id } = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/ordonnance/client/add/${id}`, { payload })
        .then((response) => {
          /* console.log(response.data) */
          commit('ADD_ORDONNANCE', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  updateOrdonnance ({commit}, payload) {
    const {id} = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/ordonnance/client/update/${id}`, { payload })
        .then((response) => {
          commit('UPDATE_ORDONNANCE', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },


  removeOrdonnance ({ commit }, payload) {
    const {clientId, refOrd } = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/ordonnance/client/drop/${clientId}/${refOrd}`)
        .then((response) => {
          commit('REMOVE_ORDONNANCE', payload)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  getCommandeClients ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/commandes/clients/groupe/active')
        .then((response) => {
          commit('SET_COMMANDES', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  getCommandeClientById (context, commandeId) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/commande-client/${commandeId}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  addCommandeClient (context, payload) {
    const {id} = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/commande/client/add/${id}`, { payload })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  updateCommandeClient (context, payload) {
    const {id} = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/commande/client/update/${id}`, { payload })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  deleteCommandeClient ({ commit }, payload) {
    const {clientId, RefCmmd} = payload
    console.log('clientId:', clientId)
    return new Promise((resolve, reject) => {
      axios.put(`/api/commande/client/drop/${clientId}/${RefCmmd}`, {payload})
        .then((response) => {
          if (response.data !== 'Impossible de supprimer cette vente') commit('UPDATE_CLIENT', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  removeCommandeClient ({ commit }, commandeId) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/commande-client/${commandeId}`)
        .then((response) => {
          commit('REMOVE_COMMANDE', commandeId)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  valideCommandeFournVerre ({commit}, payload) {
    const {idClient} = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/valide-commande-verre/${idClient}`, {payload})
        .then((response) => {
          commit('UPDATE_COMMANDE', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  valideLivraisonCommandeFournVerre ({commit}, payload) {
    const {id} = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/valide-livraison-commande-verre/${id}`, {payload})
        .then((response) => {
          commit('UPDATE_COMMANDE', response.data)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  valideMontage (context, payload) {
    const {id} = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/valide-montage/${id}`, {payload})
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  verificationMontage (context, payload) {
    const {id} = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/verification-montage/${id}`, {payload})
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  validationLivraisonClient ({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.put('/api/validation-livraison-vente-client', {payload})
        .then((response) => {
          commit('UPDATE_CLIENT', response.data)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  addPriseEnCharge ({commit}, payload) {
    const {id} = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/prise-encharge/add/${id}`, {payload})
        .then((response) => {
          /* console.log(response) */
          commit('UPDATE_CLIENT', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  updatePriseEnCharge ({commit}, payload) {
    const {id} = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/prise-encharge/update/${id}`, {payload})
        .then((response) => {
          /* console.log(response) */
          commit('UPDATE_CLIENT', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  deletePriseEnChargeClient ({commit}, payload) {
    const {id} = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/prise-encharge/drop/${id}`, {payload})
        .then((response) => {
          /* console.log(response) */
          if (!response.data.message) commit('UPDATE_CLIENT', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  reglementCommandeClient (context, payload) {
    const {clientId} = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/caisse/reglement-commande/${clientId}`, {payload})
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  getCommandeByDate (context, payload) {
    const {dashDate, officine} = payload
    return new Promise((resolve, reject) => {
      axios.get(`/api/commande-jour/${dashDate}/${officine}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  getCommandeByDateForDash ({commit}, payload) {
    const {dashDate, officine} = payload
    return new Promise((resolve, reject) => {
      axios.get(`/api/commande-jour/${dashDate}/${officine}`)
        .then((response) => {
          const data = {
            OfficineId: officine,
            respData: response.data,
            dateDashBoard: dashDate
          }
          commit('ADD_COMMANDE_TODAY_BY_AGENCE', data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  getCommandeByDatePrecedent ({commit}, payload) {
    const {Date, officine} = payload
    return new Promise((resolve, reject) => {
      axios.get(`/api/commande-jour/${Date}/${officine}`)
        .then((response) => {
          const data = {
            OfficineId: officine,
            respData: response.data,
            dateDashBoard: Date
          }
          commit('ADD_COMMANDE_PREVDAY_BY_AGENCE', data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  getCommandeByCurrentWeek ({commit}, payload) {
    console.log(payload)
    const {fromDate, toDate, officine} = payload
    return new Promise((resolve, reject) => {
      axios.get(`/api/commande-periode/${fromDate}/${toDate}/${officine}`)
        .then((response) => {
          const data = {
            OfficineId: officine,
            respData: response.data,
            from: fromDate,
            to: toDate
          }
          commit('ADD_COMMANDE_CURRENTWEEK_BY_AGENCE', data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  getCommandeByPrevWeek ({commit}, payload) {
    console.log(payload)
    const {fromDate, toDate, officine} = payload
    return new Promise((resolve, reject) => {
      axios.get(`/api/commande-periode/${fromDate}/${toDate}/${officine}`)
        .then((response) => {
          const data = {
            OfficineId: officine,
            respData: response.data,
            from: fromDate,
            to: toDate
          }
          commit('ADD_COMMANDE_PREVWEEK_BY_AGENCE', data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  getCommandeByCurrentMonth ({commit}, payload) {
    console.log(payload)
    const {fromDate, toDate, officine} = payload
    return new Promise((resolve, reject) => {
      axios.get(`/api/commande-periode/${fromDate}/${toDate}/${officine}`)
        .then((response) => {
          const data = {
            OfficineId: officine,
            respData: response.data,
            from: fromDate,
            to: toDate
          }
          commit('ADD_COMMANDE_CURRENTMONTH_BY_AGENCE', data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  getCommandeByPrevtMonth ({commit}, payload) {
    console.log(payload)
    const {fromDate, toDate, officine} = payload
    return new Promise((resolve, reject) => {
      axios.get(`/api/commande-periode/${fromDate}/${toDate}/${officine}`)
        .then((response) => {
          const data = {
            OfficineId: officine,
            respData: response.data,
            from: fromDate,
            to: toDate
          }
          commit('ADD_COMMANDE_PREVMONTH_BY_AGENCE', data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  getCommandeByCurrentYear ({commit}, payload) {
    console.log(payload)
    const {fromDate, toDate, officine} = payload
    return new Promise((resolve, reject) => {
      axios.get(`/api/commande-periode/${fromDate}/${toDate}/${officine}`)
        .then((response) => {
          const data = {
            OfficineId: officine,
            respData: response.data,
            from: fromDate,
            to: toDate
          }
          commit('ADD_COMMANDE_CURRENTYEAR_BY_AGENCE', data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  getCommandeByPrevtYear ({commit}, payload) {
    console.log(payload)
    const {fromDate, toDate, officine} = payload
    return new Promise((resolve, reject) => {
      axios.get(`/api/commande-periode/${fromDate}/${toDate}/${officine}`)
        .then((response) => {
          const data = {
            OfficineId: officine,
            respData: response.data,
            from: fromDate,
            to: toDate
          }
          commit('ADD_COMMANDE_PREVYEAR_BY_AGENCE', data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  getCommandeByPeriode (context, payload) {
    console.log(payload)
    const {fromDate, toDate, officine} = payload
    return new Promise((resolve, reject) => {
      axios.get(`/api/commande-periode/${fromDate}/${toDate}/${officine}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }


}
