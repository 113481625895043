/*=========================================================================================
 M.Djezou
  File Name: moduleOfficineMutations.js
  Description: Officine Module Mutations
  ----------------------------------------------------------------------------------------
==========================================================================================*/

export default {
  SET_FOURNISSEURS (state, fournisseurs) {
    state.fournisseurs = fournisseurs
  },
  ADD_FOURNISSEUR (state, fournisseur) {
    state.fournisseurs.unshift(fournisseur)
  },
  UPDATE_FOURNISSEUR (state, fournisseur) {
    const fournisseurIndex = state.fournisseurs.findIndex((o) => o._id === fournisseur._id)
    Object.assign(state.fournisseurs[fournisseurIndex], fournisseur)
  },
  REMOVE_RECORD (state, itemId) {
    const fournisseurIndex = state.fournisseurs.findIndex((o) => o._id === itemId)
    state.fournisseurs.splice(fournisseurIndex, 1)
  }
}

