export default {
  modeleVerreName: state => Id_Modele_Verre => {
    const result = state.modeles_verre.length > 0 ? state.modeles_verre.find((m) => m._id === Id_Modele_Verre) : null
    return result !== null ? result.Libelle_Model : ''
  },
  matricePrix: state => Id_Modele_Verre => Sph => Cyl => {
    /* Mettre les puissance en cyl + */
    /* les puissance sont en cyl negative si cyl < 0 */
    if (parseFloat(Cyl) < 0) {
      const newSph = parseFloat(Sph) + parseFloat(Cyl)
      const newCyl = -(parseFloat(Cyl))
      const result = state.matrice_prix.length > 0 ? state.matrice_prix.filter((matrice) => {
        return matrice.IdModelVer === Id_Modele_Verre && matrice.SphInf <= newSph && matrice.SphSup >= newSph && matrice.CylInf <= newCyl && matrice.CylSup >= newCyl
      }) : []
      return result
    } else {
      const result = state.matrice_prix.length > 0 ? state.matrice_prix.filter((matrice) => {
        return matrice.IdModelVer === Id_Modele_Verre && matrice.SphInf <= Sph && matrice.SphSup >= Sph && matrice.CylInf <= Cyl && matrice.CylSup >= Cyl
      }) : []
      return result
    }
  },
  verreModelFilterAllParams: state => Id_Foyer => Id_Matiere => Id_Indice => Id_FourVerre => {
    const result = state.modeles_verre.length > 0 ? state.modeles_verre.filter((modele) => {
      return modele.IdFoyer === Id_Foyer && modele.IdMatiere === Id_Matiere && modele.IdIndice === Id_Indice && modele.IdFourVerr === Id_FourVerre

    }) : []
    return result
  },
  verreModelFilterFourVerreAndFoyer: state => Id_Foyer => Id_FourVerre => {
    const result = state.modeles_verre.length > 0 ? state.modeles_verre.filter((modele) => {
      return modele.IdFoyer === Id_Foyer && modele.IdFourVerr === Id_FourVerre

    }) : []
    return result
  },
  verreModelFilterFourVerreAndFoyerAndMatiere: state => Id_Foyer => Id_FourVerre => Id_Matiere => {
    const result = state.modeles_verre.length > 0 ? state.modeles_verre.filter((modele) => {
      return modele.IdFoyer === Id_Foyer && modele.IdMatiere === Id_Matiere && modele.IdFourVerr === Id_FourVerre

    }) : []
    return result
  },
  verreModelFilterFourVerreAndFoyerAndIndice: state => Id_Foyer => Id_FourVerre => Id_Indice => {
    const result = state.modeles_verre.length > 0 ? state.modeles_verre.filter((modele) => {
      return modele.IdFoyer === Id_Foyer && modele.IdIndice === Id_Indice && modele.IdFourVerr === Id_FourVerre

    }) : []
    return result
  },
  verreModelFilterFourVerreAndMatiereAndIndice: state => Id_Matiere => Id_FourVerre => Id_Indice => {
    const result = state.modeles_verre.length > 0 ? state.modeles_verre.filter((modele) => {
      return modele.IdMatiere === Id_Matiere && modele.IdIndice === Id_Indice && modele.IdFourVerr === Id_FourVerre

    }) : []
    return result
  },
  verreModelFilterFoyerAndMatiereAndIndice: state => Id_Foyer => Id_Matiere => Id_Indice => {

    const result = state.modeles_verre.length > 0 ? state.modeles_verre.filter((modele) => {
      return modele.IdFoyer === Id_Foyer && modele.IdMatiere === Id_Matiere && modele.IdIndice === Id_Indice

    }) : []
    return result
  },
  verreModelFilterFoyerAndMatiere: state => Id_Foyer => Id_Matiere => {
    const result = state.modeles_verre.length > 0 ? state.modeles_verre.filter((modele) => {
      return modele.IdFoyer === Id_Foyer && modele.IdMatiere === Id_Matiere

    }) : []
    return result
  },
  verreModelFilterFourVerre: state => Id_FourVerre => {
    return state.modeles_verre.length > 0 ? state.modeles_verre.filter((modele) => modele.IdFourVerr === Id_FourVerre) : []
  },
  verreModelFilterFoyer: state => Id_Foyer => {
    return state.modeles_verre.length > 0 ? state.modeles_verre.filter((modele) => modele.IdFoyer === Id_Foyer) : []
  },
  verreModelFilterMatiere: state => Id_Matiere => {
    return state.modeles_verre.length > 0 ? state.modeles_verre.filter((modele) => modele.IdMatiere === Id_Matiere) : []
  },
  getFournisseurVerreById: state => id => {
    return state.fournisseurs_verres.find((f) => f._id === id)
  },
  getModeleVerreById: state => id => {
    return state.modeles_verre.find((m) => m._id === id)
  },
  showFournisseurName: state => id => {
    const fourn = state.fournisseurs_verres.length > 0 ? state.fournisseurs_verres.find((f) => f._id === id) : null
    return fourn ? fourn.Fournisseur : ''
  },
  showVersionName: state => id => {
    return state.versions.find((v) => v._id === id) ? state.versions.find((v) => v._id === id).Version : null
  },
  showGammeName: state => id => {
    return state.gammes_verres.find((g) => g._id === id) ? state.gammes_verres.find((g) => g._id === id).Libelle_GamVer : null
  },
  showMatiereName: state => id => {
    return state.matieres_verres.find((m) => m._id === id) ? state.matieres_verres.find((m) => m._id === id).Libelle_Matiere : null
  },
  showFoyerName: state => id => {
    return state.types_verre.find((t) => t._id === id) ? state.types_verre.find((t) => t._id === id).Libelle_Foyer : null
  },
  showIndiceName: state => id => {
    return state.indices.find((i) => i._id === id) ? state.indices.find((i) => i._id === id).indice : null
  },
  getTraitementByGam: state => idModele => {
    const modele = state.modeles_verre.length > 0 ? state.modeles_verre.find((m) => m._id === idModele) : null
    const traitements = modele !== null ? (state.traitements.length > 0 ? state.traitements.filter((t) => t.IdGam === modele.IdGam) : []) : []
    return traitements
  },
  getOptionByGammeIdAndBase: state => payload => {
    const versions = state.versions.filter((v) => {
      if (v.IdGam === payload.gamme && v.IdIndice === payload.indice) return true
    })
    const version = versions.length > 0 ? versions.find((v) => v.Prix_Base === true) : null
    return version
  },
  filterOptionByIdGamme: state => id => {
    const versions = state.versions.length > 0 ? state.versions.filter((v) => v.IdGam === id) : null
    return versions
  },
  filterFabricationByIdFour: state => id => {
    const fabrications = state.fabrications.length > 0 ? state.fabrications.filter((f) => f.IdFour === id) : null
    return fabrications
  },
  filterTraitementByIdFour: state => id => {
    const traitements = state.traitements.length > 0 ? state.traitements.filter((t) => t.IdFour === id) : null
    return traitements
  },
  filterColorationByIdFourn: state => id => {
    const colorations = state.colorations.length > 0 ? state.colorations.filter((c) => c.IdFour === id) : null
    return colorations
  },


  getOptionById: state => id => {
    return state.versions.length > 0 ? state.versions.find(v => v._id === id) : null
  },

  getTraitementById: state => id => {
    return state.traitements.length > 0 ? state.traitements.find(t => t._id === id) : null
  },

  getFabricationById: state => id => {
    return state.fabrications.length > 0 ? state.fabrications.find(f => f._id === id) : null
  },

  getColorationById: state => id => {
    return state.colorations.length > 0 ? state.colorations.find(c => c._id === id) : null
  },

  gammeverres: state => {
    switch (state.gammeFilterObj.filterCount) {
    //no filters passed
    case 0:
    case null:
    case undefined:
      return state.gammes_verres
    case 1:
      return state.gammes_verres.filter(gamme => {
        for (const key in state.gammeFilterObj) {
          if (gamme[key]) {
            if (gamme[key].includes(state.gammeFilterObj[key])) return true
          }
          
        }
        return false
      })
    case 2:
      return state.gammes_verres.filter(gamme => {
        for (const key in state.gammeFilterObj) {
          if (gamme[key]) {
            if (!gamme[key].includes(state.gammeFilterObj[key])) return false
          }
          
        }
        return true
      })
    
    default:
      return state.gammes_verres
    }
  },

  options: state => {
    switch (state.optionFilterObj.filterCount) {
    case 0:
    case null:
    case undefined:
      return state.versions
    case 1:
      return state.versions.filter(version => {
        for (const key in state.optionFilterObj) {
          if (version[key]) {
            if (version[key].includes(state.optionFilterObj[key])) return true
          }
        }
        return false
      })
    case 2:
    case 3:
      return state.versions.filter(version => {
        for (const key in state.optionFilterObj) {
          if (version[key]) {
            if (!version[key].includes(state.optionFilterObj[key])) return false
          }
        }
        return true
      })
    
    default:
      return state.versions
    }
  },

  traitements: state => {
    switch (state.TraitemetFilterObj.filterCount) {
    case 0:
    case null:
    case undefined:
      return state.traitements
    case 1:
      return state.traitements.filter(traitement => {
        for (const key in state.TraitemetFilterObj) {
          if (traitement[key]) {
            if (traitement[key].includes(state.TraitemetFilterObj[key])) return true
          }
        }
        return false
      })
    case 2:
    case 3:
      return state.traitements.filter(traitement => {
        for (const key in state.TraitemetFilterObj) {
          if (traitement[key]) {
            if (!traitement[key].includes(state.TraitemetFilterObj[key])) return false
          }
        }
        return true
      })
      
    default:
      return state.traitements
    }
  },
  fabrications: state => {
    switch (state.fabricationFilterObj.filterCount) {
    case 0:
    case null:
    case undefined:
      return state.fabrications
    case 1:
      return state.fabrications.filter(fabrication => {
        for (const key in state.fabricationFilterObj) {
          if (fabrication[key]) {
            if (fabrication[key].includes(state.fabricationFilterObj[key])) return true
          }
        }
        return false
      })
    case 2:
    case 3:
      return state.fabrications.filter(fabrication => {
        for (const key in state.fabricationFilterObj) {
          if (fabrication[key]) {
            if (!fabrication[key].includes(state.fabricationFilterObj[key])) return false
          }
        }
        return true
      })
      
    default:
      return state.fabrications
    }
  },
  colorations: state => {
    switch (state.colorationFilterObj.filterCount) {
    case 0:
    case null:
    case undefined:
      return state.colorations
    case 1:
      return state.colorations.filter(coloration => {
        for (const key in state.colorationFilterObj) {
          if (coloration[key]) {
            if (coloration[key].includes(state.colorationFilterObj[key])) return true
          }
        }
        return false
      })
    case 2:
    case 3:
      return state.colorations.filter(coloration => {
        for (const key in state.colorationFilterObj) {
          if (coloration[key]) {
            if (!coloration[key].includes(state.colorationFilterObj[key])) return false
          }
        }
        return true
      })
      
    default:
      return state.colorations
    }
  }
}
