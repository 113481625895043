/*=========================================================================================
  File Name: moduleStockVerreActions.js
  Description: Stock Verre Module Actions
  ----------------------------------------------------------------------------------------
==========================================================================================*/

import axios from '@/axios.js'

export default {
  getNomCommercialVerres ({commit}) {
    return new Promise((resolve, reject) => {
      axios.get('/api/stock-verre/nom-commercial-verres')
        .then((response) => {
          commit('SET_NOM_COMMERCIAL_VERRE', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  createNomCommercialVerre ({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/stock-verre/nom-commercial-verre', {payload})
        .then((response) => {
          commit('ADD_NOM_COMMERCIAL_VERRE', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  updateNomCommercialVerre ({commit}, payload) {
    const {id} = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/stock-verre/nom-commercial-verre/${id}`, {payload})
        .then((response) => {
          commit('UPDATE_NOM_COMMERCIAL_VERRE', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  deleteNomCommercialVerre ({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.put(`/api/stock-verre/nom-commercial-verre/softdelete/${id}`)
        .then((response) => {
          commit('DELETE_NOM_COMMERCIAL_VERRE', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  hardRemoveNomCommercialVerre ({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/stock-verre/nom-commercial-verre/${id}`)
        .then((response) => {
          commit('DELETE_NOM_COMMERCIAL_VERRE', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  removeAllNomCommercialVerre ({commit}) {
    return new Promise((resolve, reject) => {
      axios.delete('/api/stock-verre/nom-commercial-verre/delete-all')
        .then((response) => {
          commit('DELETE_ALL_NOM_COMMERCIAL_VERRE')
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  getStockVerreProgs ({commit}) {
    return new Promise((resolve, reject) => {
      axios.get('/api/stock-verre/progressif')
        .then((response) => {
          commit('SET_STOCK_VERRES_PROG', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  createStockVerreProgs ({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/stock-verre/progressif', {payload})
        .then((response) => {
          commit('ADD_STOCK_VERRE_PROG', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateStockVerreProgs ({commit}, payload) {
    const {id} = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/stock-verre/progressif/${id}`, {payload})
        .then((response) => {
          commit('UPDATE_STOCK_VERRE_PROG', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deleteStockVerreProgs ({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/stock-verre/progressif/${id}`)
        .then((response) => {
          commit('DELETE_STOCK_VERRE_PROG', id)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deleteAllStockVerreProgs ({commit}) {
    return new Promise((resolve, reject) => {
      axios.delete('/api/stock-verre/progressif/all')
        .then((response) => {
          commit('ALL_DELETE_STOCK_VERRE_PROG')
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getStockVerreUnifoco ({commit}) {
    return new Promise((resolve, reject) => {
      axios.get('/api/stock-verre/unifoco')
        .then((response) => {
          commit('SET_STOCK_VERRES_UNIFOCO', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  createStockVerreUnifoco ({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/stock-verre/unifoco', {payload})
        .then((response) => {
          commit('ADD_STOCK_VERRES_UNIFOCO', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateStockVerreUnifoco ({commit}, payload) {
    const {id} = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/stock-verre/unifoco/${id}`, {payload})
        .then((response) => {
          commit('UPDATE_STOCK_VERRES_UNIFOCO', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deleteStockVerreUnifoco ({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/stock-verre/unifoco/${id}`)
        .then((response) => {
          commit('DETELE_STOCK_VERRES_UNIFOCO', id)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deleteAllStockVerreUnifoco ({commit}) {
    return new Promise((resolve, reject) => {
      axios.delete('/api/stock-verre/unifoco/delete-all')
        .then((response) => {
          commit('DETELE_ALL_STOCK_VERRES_UNIFOCO')
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
