/*=========================================================================================
  File Name: moduleAssurance.js
  Description: module assurance
  ----------------------------------------------------------------------------------------
  Author: Eudoxie Djetouan
  Author email: eudoxiedjetouan@gmail.com
==========================================================================================*/


import state from './moduleAssuranceState.js'
import mutations from './moduleAssuranceMutations.js'
import actions from './moduleAssuranceActions.js'
import getters from './moduleAssuranceGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

