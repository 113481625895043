/*=========================================================================================
  M.Djezou
  File Name: moduleRoleMutations.js
  Description: Role Module Mutations
  ----------------------------------------------------------------------------------------
==========================================================================================*/

export default {
  SET_ROLES (state, roles) {
    state.roles = roles
  },
  ADD_ROLE (state, role) {
    state.roles.unshift(role)
  },
  UPDATE_ROLE (state, role) {
    const roleIndex = state.roles.findIndex((r) => r._id === role._id)
    Object.assign(state.roles[roleIndex], role)
  },
  DELETE_ROLE (state, id) {
    const roleIndex = state.roles.findIndex((r) => r._id === id)
    state.roles.splice(roleIndex, 1)
  }
}
