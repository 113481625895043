
import axios from '@/axios.js'

export default {

  getVenteForAtelier ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/ventes-non-monter/atelier')
        .then((response) => {
          commit('SET_ATELIERS', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }

}
