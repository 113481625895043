/*=========================================================================================
  File Name: moduleAtelier.js
  Description: module atelier
  ----------------------------------------------------------------------------------------
  Author: Eudoxie Djetouan
  Author email: eudoxiedjetouan@gmail.com
==========================================================================================*/


import state from './moduleAtelierState.js'
import mutations from './moduleAtelierMutations.js'
import actions from './moduleAtelierActions.js'
import getters from './moduleAtelierGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

