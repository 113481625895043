/*=========================================================================================
  File Name: moduleCommandeFournActions.js
  Description: CommandeFourn Module Actions
  ----------------------------------------------------------------------------------------
==========================================================================================*/

import axios from '@/axios.js'

export default {
  reglementCommandeComptoire ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/commande/comptoire', {payload})
        .then((response) => {
          commit('ADD_COMMANDE_COMPTOIRE', response.data)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  createCommandeComptoire ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/commande/comptoire/create', {payload})
        .then((response) => {
          commit('ADD_COMMANDE_COMPTOIRE', response.data)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getCommandesComptoire ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/commandes/comptoires/groupe')
        .then((response) => {
          commit('SET_COMMANDES_COMPTOIRE', response.data)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getCommandeComptoireById (context, id) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/commande/comptoire/${id}`)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updateCommandeComptoire (context, payload) {
    const { id } = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/commande/comptoire/${id}`, {payload})
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  deleteCommandeComptoire ({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/commande/comptoire/drop/${id}`)
        .then((response) => {
          commit('DELETE_COMMANDE_COMPTOIRE', id)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getCommandeComptoireByDateForDash ({commit}, payload) {
    const {dashDate, officine} = payload
    return new Promise((resolve, reject) => {
      axios.get(`/api/commande-comptoire-jour/${dashDate}/${officine}`)
        .then((response) => {
          const data = {
            OfficineId: officine,
            respData: response.data,
            dateDashBoard: dashDate
          }
          commit('ADD_COMMANDE_COMPTOIRE_TODAY_BY_AGENCE', data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  getCommandeComptoireByDatePrecedent ({commit}, payload) {
    const {Date, officine} = payload
    return new Promise((resolve, reject) => {
      axios.get(`/api/commande-comptoire-jour/${Date}/${officine}`)
        .then((response) => {
          const data = {
            OfficineId: officine,
            respData: response.data,
            dateDashBoard: Date
          }
          commit('ADD_COMMANDE_COMPTOIRE_PREVDAY_BY_AGENCE', data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  getCommandeComptoireByCurrentWeek ({commit}, payload) {
    const {fromDate, toDate, officine} = payload
    return new Promise((resolve, reject) => {
      axios.get(`/api/commande-comptoire-periode/${fromDate}/${toDate}/${officine}`)
        .then((response) => {
          const data = {
            OfficineId: officine,
            respData: response.data,
            from: fromDate,
            to: toDate
          }
          commit('ADD_COMMANDE_COMPTOIRE_CURRENTWEEK_BY_AGENCE', data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  getCommandeComptoireByPrevWeek ({commit}, payload) {
    const {fromDate, toDate, officine} = payload
    return new Promise((resolve, reject) => {
      axios.get(`/api/commande-comptoire-periode/${fromDate}/${toDate}/${officine}`)
        .then((response) => {
          const data = {
            OfficineId: officine,
            respData: response.data,
            from: fromDate,
            to: toDate
          }
          commit('ADD_COMMANDE_COMPTOIRE_PREVWEEK_BY_AGENCE', data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  getCommandeComptoireByCurrentMonth ({commit}, payload) {
    const {fromDate, toDate, officine} = payload
    return new Promise((resolve, reject) => {
      axios.get(`/api/commande-comptoire-periode/${fromDate}/${toDate}/${officine}`)
        .then((response) => {
          const data = {
            OfficineId: officine,
            respData: response.data,
            from: fromDate,
            to: toDate
          }
          commit('ADD_COMMANDE_COMPTOIRE_CURRENTMONTH_BY_AGENCE', data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  getCommandeComptoireByPrevtMonth ({commit}, payload) {
    const {fromDate, toDate, officine} = payload
    return new Promise((resolve, reject) => {
      axios.get(`/api/commande-comptoire-periode/${fromDate}/${toDate}/${officine}`)
        .then((response) => {
          const data = {
            OfficineId: officine,
            respData: response.data,
            from: fromDate,
            to: toDate
          }
          commit('ADD_COMMANDE_COMPTOIRE_PREVMONTH_BY_AGENCE', data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  getCommandeComptoireByCurrentYear ({commit}, payload) {
    const {fromDate, toDate, officine} = payload
    return new Promise((resolve, reject) => {
      axios.get(`/api/commande-comptoire-periode/${fromDate}/${toDate}/${officine}`)
        .then((response) => {
          const data = {
            OfficineId: officine,
            respData: response.data,
            from: fromDate,
            to: toDate
          }
          commit('ADD_COMMANDE_COMPTOIRE_CURRENTYEAR_BY_AGENCE', data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  getCommandeComptoireByPrevtYear ({commit}, payload) {
    const {fromDate, toDate, officine} = payload
    return new Promise((resolve, reject) => {
      axios.get(`/api/commande-comptoire-periode/${fromDate}/${toDate}/${officine}`)
        .then((response) => {
          const data = {
            OfficineId: officine,
            respData: response.data,
            from: fromDate,
            to: toDate
          }
          commit('ADD_COMMANDE_COMPTOIRE_PREVYEAR_BY_AGENCE', data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  getCommandeComptoireByPeriode (context, payload) {
    const {fromDate, toDate, officine} = payload
    return new Promise((resolve, reject) => {
      axios.get(`/api/commande-comptoire-periode/${fromDate}/${toDate}/${officine}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }


}
