export default {

  SET_FOURNISSEUR_VERRE (state, fournisseurs) {
    state.fournisseurs_verres = fournisseurs
  },

  ADD_FOURNISSEUR_VERRE (state, fournisseur) {
    state.fournisseurs_verres.unshift(fournisseur)
  },
  UPDATE_FOURNISSEUR_VERRE (state, fournisseur) {
    const index = state.fournisseurs_verres.findIndex((f) => f._id === fournisseur._id)
    Object.assign(state.fournisseurs_verres[index], fournisseur)
  },

  REMOVE_FOURNISSEUR_VERRE (state, id) {
    const index = state.fournisseurs_verres.findIndex((f) => f._id === id)
    state.fournisseurs_verres.splice(index, 1)
  },

  SET_CATALOGUE_VERRES (state, verres) {
    state.catalogue_verres = verres
  },

  ADD_CATALOGUE_VERRE (state, verre) {
    state.catalogue_verres.unshift(verre)
  },
  UPDATE_CATALOGUE_VERRE (state, verre) {
    const index = state.catalogue_verres.findIndex((c) => c._id === verre._id)
    Object.assign(state.catalogue_verres[index], verre)
  },
  REMOVE_CATALOGUE_VERRE (state, id) {
    const index = state.catalogue_verres.findIndex((c) => c._id === id)
    state.catalogue_verres.splice(index, 1)
  },

  ADD_GAMME_VERRE (state, gamme) {
    state.gammes_verres.unshift(gamme)
  },
  SET_GAMME_VERRE (state, gammes) {
    state.gammes_verres = gammes
  },
  UPDATE_GAMME_VERRE (state, gamme) {
    const index = state.gammes_verres.findIndex((g) => g._id === gamme._id)
    Object.assign(state.gammes_verres[index], gamme)
  },
  REMOVE_GAMME_VERRE (state, id) {
    const index = state.gammes_verres.findIndex((g) => g._id === id)
    state.gammes_verres.splice(index, 1)
  },
  UPDATE_GAMME_VERRE_FILTER (state, payload) {
    state.gammeFilterObj = payload
  },

  ADD_TRAITEMENT (state, traitement) {
    state.traitements.unshift(traitement)
  },

  SET_TRAITEMENT (state, traitements) {
    state.traitements = traitements
  },

  UPDATE_TRAITEMENT (state, traitement) {
    const index = state.traitements.findIndex((t) => t._id === traitement._id)
    Object.assign(state.traitements[index], traitement)
  },

  REMOVE_TRAITEMENT (state, id) {
    const index = state.traitements.findIndex((t) => t._id === id)
    state.traitements.splice(index, 1)
  },
  UPDATE_TRAITEMENT_FILTER (state, payload) {
    state.TraitemetFilterObj = payload
  },
  ADD_COLORATION (state, data) {
    state.colorations.unshift(data)
  },
  SET_COLORATION (state, data) {
    state.colorations = data
  },
  UPDATE_COLORATION (state, data) {
    const index = state.colorations.findIndex((t) => t._id === data._id)
    Object.assign(state.colorations[index], data)
  },
  REMOVE_COLORATION (state, id) {
    const index = state.colorations.findIndex((t) => t._id === id)
    state.colorations.splice(index, 1)
  },
  UPDATE_COLORATION_FILTER (state, payload) {
    state.colorationFilterObj = payload
  },
  ADD_FABRICATION (state, data) {
    state.fabrications.unshift(data)
  },
  SET_FABRICATION (state, data) {
    state.fabrications = data
  },
  UPDATE_FABRICATION (state, data) {
    const index = state.fabrications.findIndex((t) => t._id === data._id)
    Object.assign(state.fabrications[index], data)
  },
  REMOVE_FABRICATION (state, id) {
    const index = state.fabrications.findIndex((t) => t._id === id)
    state.fabrications.splice(index, 1)
  },
  UPDATE_FABRICATION_FILTER (state, payload) {
    state.fabricationFilterObj = payload
  },
  ADD_TEINTE (state, data) {
    state.teintes.unshift(data)
  },
  SET_TEINTE (state, data) {
    state.teintes = data
  },
  UPDATE_TEINTE (state, data) {
    const index = state.teintes.findIndex((t) => t._id === data._id)
    Object.assign(state.teintes[index], data)
  },
  REMOVE_TEINTE (state, id) {
    const index = state.teintes.findIndex((t) => t._id === id)
    state.teintes.splice(index, 1)
  },

  ADD_MODELE_VERRE (state, ref) {
    state.modeles_verre.unshift(ref)
  },
  SET_MODELE_VERRE (state, refs) {
    state.modeles_verre = refs
  },
  UPDATE_MODELE_VERRE (state, ref) {
    const index = state.modeles_verre.findIndex((r) => r._id === ref._id)
    Object.assign(state.modeles_verre[index], ref)
  },
  REMOVE_MODELE_VERRE (state, id) {
    const index = state.modeles_verre.findIndex((r) => r._id === id)
    state.modeles_verre.splice(index, 1)
  },

  ADD_TYPE_VERRE (state, type) {
    state.types_verre.unshift(type)
  },
  SET_TYPE_VERRE (state, types) {
    state.types_verre = types
  },
  UPDATE_TYPE_VERRE (state, type) {
    const index = state.types_verre.findIndex((t) => t._id === type._id)
    Object.assign(state.types_verre[index], type)
  },
  REMOVE_TYPE_VERRE (state, id) {
    const index = state.types_verre.findIndex((t) => t._id === id)
    state.types_verre.splice(index, 1)
  },

  ADD_MATIERE_VERRE (state, matiere) {
    state.matieres_verres.unshift(matiere)
  },
  SET_MATIERE_VERRE (state, matieres) {
    state.matieres_verres = matieres
  },
  UPDATE_MATIERE_VERRE (state, matiere) {
    const index = state.matieres_verres.findIndex((m) => m._id === matiere._id)
    Object.assign(state.matieres_verres[index], matiere)
  },
  REMOVE_MATIERE_VERRE (state, id) {
    const index = state.matieres_verres.findIndex((m) => m._id === id)
    state.matieres_verres.splice(index, 1)
  },

  ADD_INDICE_VERRE (state, indice) {
    state.indices.unshift(indice)
  },
  SET_INDICES_VERRE (state, indices) {
    state.indices = indices
  },
  UPDATE_INDICE_VERRE (state, indice) {
    const index = state.indices.findIndex((In) => In._id === indice._id)
    Object.assign(state.indices[index], indice)
  },
  REMOVE_INDICE_VERRE (state, indice) {
    const index = state.indices.findIndex((In) => In._id === indice._id)
    state.indices.splice(index, 1)
  },

  ADD_VERSION (state, version) {
    state.versions.unshift(version)
  },
  SET_VERSION (state, versions) {
    state.versions = versions
  },
  UPDATE_VERSION (state, version) {
    const index = state.versions.findIndex((d) => d._id === version._id)
    Object.assign(state.versions[index], version)
  },
  REMOVE_VERSION (state, id) {
    const index = state.versions.findIndex((d) => d._id === id)
    state.versions.splice(index, 1)
  },
  UPDATE_VERSION_FILTER (state, payload) {
    state.optionFilterObj = payload
  },

  SET_MATRICE_PRIX (state, matricePrix) {
    state.matrice_prix = matricePrix
  }
}
