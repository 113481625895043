/*=========================================================================================
  M.Djezou
  File Name: moduleOfficine.js
  Description: Officine Module
  ----------------------------------------------------------------------------------------

==========================================================================================*/


import state from './moduleBilletageState.js'
import mutations from './moduleBilletageMutations.js'
import actions from './moduleBilletageActions.js'
import getters from './moduleBilletageGetters.js'

export default {

  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

