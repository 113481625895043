export default{
  fournisseurs_verres: [],
  catalogue_verres: [],
  gammes_verres: [],
  gammeFilterObj: {},
  modeles_verre: [],
  types_verre: [],
  matieres_verres: [],
  indices: [],
  versions: [],
  optionFilterObj: {},
  matrice_prix: [],
  traitements: [],
  TraitemetFilterObj: {},
  colorations: [],
  colorationFilterObj: {},
  fabrications: [],
  fabricationFilterObj: {},
  teintes: []
}
