/*=========================================================================================
  M.Djezou
  File Name: moduleInventaireActions.js
  Description: Inventaire Module Actions
  ----------------------------------------------------------------------------------------
==========================================================================================*/

import axios from '@/axios.js'

export default {
  NouveauInventaire ({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/inventaire/nouveau', {payload})
        .then((response) => {
          commit('ADD_INVENTAIRE', response.data)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getActiveInventairesByOfficine ({commit}, officine) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/inventaires-active/${officine}`)
        .then((response) => {
          console.log('inventaire', response.data)
          commit('SET_INVENTAIRES', response.data)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  updateInventaire ({commit}, payload) {
    const {id} = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/inventaire/update/${id}`, {payload})
        .then((response) => {
          commit('UPDATE_INVENTAIRE', response.data)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  updateInventaireToStock ({commit}, payload) {
    const {id} = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/inventaire/update-stock/${id}`, {payload})
        .then((response) => {
          commit('REMOVE_RECORD', id)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}

