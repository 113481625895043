/*=========================================================================================
  File Name: moduleConfigsGetters.js
  Description: Configs Module Getters
  ----------------------------------------------------------------------------------------
==========================================================================================*/

export default {
  selectGarantieMonture: state => prix => {
    const politique = state.garantie.advancedGarantie ? state.garantie.garantiesMontue.find((g) => {
      if (g.prixMax !== 0 && prix > g.prixMin &&  prix < g.prixMax) {
        return true
      } else if (g.prixMax === 0 && prix >= g.prixMin) return true
      return false
    }) : state.garantie.garantieMontureDefaut
    return politique ? politique.garantie : ''
  },
  selectGarantieVerre: state => prix => {
    const politique = state.garantie.advancedGarantie ? state.garantie.garantiesVerre.find((g) => {
      if (g.prixMax !== 0 && prix > g.prixMin &&  prix < g.prixMax) {
        return true
      } else if (g.prixMax === 0 && prix >= g.prixMin) return true
      return false
    }) : state.garantie.garantieVerreDefaut

    return politique ? politique.garantie : ''
  },
  // dayIsFerie: state => date => {
  //   const ferieMap = new Map(state.feries.map(f => [new Date(f.dateFerie), true]));
  //   return ferieMap.has(new Date(date));
  // }  
  dayIsFerie: state => date => {
    const currentDate = new Date(date).toISOString().split('T')[0]
    const ferie = state.feries.length > 0 ? (!!state.feries.find((f) => {
      const date_ferie = new Date(f.dateFerie).toISOString().split('T')[0]
      if (date_ferie === currentDate) {
        return true
      }
    })) : false
    return ferie
  }
}
