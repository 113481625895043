/*=========================================================================================
  File Name: modulePersonnel.js
  Description: Calendar Module
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import state from './moduleTypeState.js'
import mutations from './moduleTypeMutations.js'
import actions from './moduleTypeActions.js'
import getters from './moduleTypeGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

