/*=========================================================================================
  File Name: moduleDepenseGetters.js
  Description: Depense Module Getters
  ----------------------------------------------------------------------------------------
==========================================================================================*/

export default {
  CaisseIsInit: state => {
    const init_array = state.approCaisse.length > 0 ? state.approCaisse.find((a) => a.Motif === 'initialisation') : false
    return !!init_array
  },
  getApproMontant: state => {
    return state.approCaisse.length > 0 ? state.approCaisse.reduce((acc, appro) => {
      return acc + +(appro.Montant ? appro.Montant : 0)
    }, 0) : 0
  },
  getDepenseMontant: state => {
    return state.depenses.length > 0 ? state.depenses.reduce((acc, depense) => {
      return acc + +(depense.Montant ? depense.Montant : 0)
    }, 0) : 0
  },
  decaissementToDay: state => id => {
    const agence = state.decaissements_toDay_by_agence ? state.decaissements_toDay_by_agence.find((a) => a.officine === id) : null
    const decaissements = agence ? (agence.decaissements ? agence.decaissements.reduce((acc, operation) => {
      return acc + (operation.Montant ? +operation.Montant : 0)
    }, 0) : 0) : 0

    return decaissements
  },
  decaissementPrevDay: state => id => {
    const agence = state.decaissements_prev_day_by_agence ? state.decaissements_prev_day_by_agence.find((a) => a.officine === id) : null
    const decaissements = agence ? (agence.decaissements ? agence.decaissements.reduce((acc, operation) => {
      return acc + (operation.Montant ? +operation.Montant : 0)
    }, 0) : 0) : 0

    return decaissements
  }
}
