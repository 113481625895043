/*=========================================================================================
  File Name: moduleEtat.js
  Description: Etat Module
  ----------------------------------------------------------------------------------------
==========================================================================================*/


import state from './moduleEtatState.js'
import mutations from './moduleEtatMutations.js'
import actions from './moduleEtatActions.js'
import getters from './moduleEtatGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

