/*=========================================================================================
  File Name: moduleFonctionActions.js
  Description: Fonction Module Actions
  ----------------------------------------------------------------------------------------
==========================================================================================*/

import axios from '@/axios.js'

export default {

  addFonction ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/fonction/create', {payload})
        .then((response) => {
          commit('ADD_FONCTION', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateFonction ({commit}, payload) {
    const {id} = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/fonction/${id}`, {payload})
        .then((response) => {
          commit('UPDATE_FONCTION', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getfonctions ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/fonctions')
        .then((response) => {
          commit('SET_FONCTIONS', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  removeFonction ({ commit }, fonctionId) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/fonction/drop/${fonctionId}`)
        .then((response) => {
          commit('REMOVE_RECORD', fonctionId)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
