/*=========================================================================================
  M.Djezou
  File Name: modulePaysActions.js
  Description: Pays Module Actions
  ----------------------------------------------------------------------------------------

==========================================================================================*/

import axios from '@/axios.js'

export default {

  getPays ({commit}) {
    return new Promise((resolve, reject) => {
      axios.get('/api/pays')
        .then((response) => {
          commit('SET_PAYS', response.data)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  createPays ({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/pays/create', {payload})
        .then((response) => {
          commit('ADD_PAYS', response.data)
          resolve(response)
        })
        .catch(error => {

          reject(error)
        })
    })
  },

  updatePays ({commit}, payload) {
    const {id} = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/pays/update/${id}`, {payload})
        .then((response) => {
          commit('UPDATE_PAYS', response.data)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  dropPays ({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/pays/drop/${id}`)
        .then((response) => {
          commit('REMOVE_RECORD', id)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getVilles ({commit}) {
    return new Promise((resolve, reject) => {
      axios.get('/api/villes')
        .then((response) => {
          commit('SET_VILLES', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  createVille ({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/ville/create', {payload})
        .then((response) => {
          commit('ADD_VILLE', response.data)
          resolve(response)
        })
        .catch(error => {

          reject(error)
        })
    })
  },

  updateVille ({commit}, payload) {
    const {id} = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/ville/update/${id}`, {payload})
        .then((response) => {
          commit('UPDATE_VILLE', response.data)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  dropVille ({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/ville/drop/${id}`)
        .then((response) => {
          commit('REMOVE_VILLE', id)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getQuartiers ({commit}) {
    return new Promise((resolve, reject) => {
      axios.get('/api/quartiers')
        .then((response) => {
          commit('SET_QUARTIERS', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  createQuartier ({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/quartier/create', {payload})
        .then((response) => {
          commit('ADD_QUARTIER', response.data)
          resolve(response)
        })
        .catch(error => {

          reject(error)
        })
    })
  },

  updateQuartier ({commit}, payload) {
    const {id} = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/quartier/update/${id}`, {payload})
        .then((response) => {
          commit('UPDATE_QUARTIER', response.data)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  dropQuartier ({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/quartier/drop/${id}`)
        .then((response) => {
          commit('REMOVE_QUARTIER', id)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}

