/*=========================================================================================
  File Name: moduleClientState.js
  Description: state of module client
  ----------------------------------------------------------------------------------------
==========================================================================================*/

export default {
  clients: [],
  clientsBirthday: [],
  commandesBirthday: [],
  ordonnances: [],
  commandes: [],
  commandes_jour: [],
  commandes_jour_precedent: [],
  commandes_today_by_agence: [],
  commandes_toDay: [],
  commandes_hier_by_agence: [],
  commandes_hier: [],
  commandes_current_week_by_agence: [],
  commandes_current_week: [],
  commandes_prev_Week_by_agence: [],
  commandes_prev_Week: [],
  commandes_current_month_by_agence: [],
  commandes_current_month: [],
  commandes_prev_month_by_agence: [],
  commandes_prev_month: [],
  commandes_current_year_by_agence: [],
  commandes_current_year: [],
  commandes_prev_year_by_agence: [],
  commandes_prev_year: []
}
