/*=========================================================================================
  File Name: moduleFacturationMutations.js
  Description: Facturation Module Mutations
  ----------------------------------------------------------------------------------------
==========================================================================================*/


export default {
  SET_FACTURATION (state, factures) {
    state.facture_assurances = factures
  },
  RESET_FACTURATION (state) {
    state.facture_assurances = []
  },
  REMOVE_VENTE_ASSURANCE (state, id) {
    const index = state.facture_assurances.findIndex((f) => f._id === id)
    state.facture_assurances.splice(index, 1)
  },
  SET_COMMANDE_NON_TRAITER (state, commandes) {
    state.commandes_non_traiter = commandes
  },
  VALIDE_TRAITEMENT_VENTE (state, index) {
    state.commandes_non_traiter.splice(index, 1)
  },
  SET_FACTURE_A_DEPOSER (state, data) {
    state.factures_assurance_a_deposer = data
  },
  DELETE_FACTURE_A_DEPOSER (state, id) {
    const index = state.factures_assurance_a_deposer.findIndex((f) => f._id === id)
    state.factures_assurance_a_deposer.splice(index, 1)
  },
  SET_FACTURE_DEPOSER (state, data) {
    state.factures_assurance_deposer = data
  },
  ARCHIVE_FACTURE_DEPOSER (state, id) {
    const index = state.factures_assurance_deposer.findIndex((f) => f._id === id)
    state.factures_assurance_deposer.splice(index, 1)
  },
  UPDATE_FACTURE_INFO (state, data) {
    const indexFactureDeposer = state.factures_assurance_deposer ? state.factures_assurance_deposer.findIndex((f) => f._id === data._id) : null
    const indexFactureADeposer = state.factures_assurance_a_deposer ? state.factures_assurance_a_deposer.findIndex((f) => f._id === data._id) : null

    if (indexFactureDeposer !== -1) {
      console.log('indexFactureDeposer:', indexFactureDeposer)
      Object.assign(state.factures_assurance_deposer[indexFactureDeposer], data)
    }

    if (indexFactureADeposer !== -1) {
      console.log('indexFactureADeposer:', indexFactureADeposer)
      Object.assign(state.factures_assurance_a_deposer[indexFactureADeposer], data)
    }
  },
  UPDATE_FACTURE_DEPOSER (state, data) {
    const indexFactureDeposer = state.factures_assurance_deposer ? state.factures_assurance_deposer.findIndex((f) => f._id === data._id) : null

    if (indexFactureDeposer !== -1) {
      Object.assign(state.factures_assurance_deposer[indexFactureDeposer], data)
    }
  },
  ADD_FACTURE_ASSURANCE_MODEL (state, data) {
    const index = state.factures_assurance_modele.findIndex((f) => f.IdAssurance === data.IdAssurance)

    if (index > -1) {
      Object.assign(state.factures_assurance_modele[index], data)
    } else {
      state.factures_assurance_modele.unshift(data)
    }
  },
  SET_FACTURE_ASSURANCE_MODEL (state, data) {
    state.factures_assurance_modele = data
  }
}
