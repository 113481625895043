/*=========================================================================================
  File Name: moduleCategorieArticle.js
  Description: CategorieArticle Module
  ----------------------------------------------------------------------------------------
==========================================================================================*/


import state from './moduleCategorieArticleState.js'
import mutations from './moduleCategorieArticleMutations.js'
import actions from './moduleCategorieArticleActions.js'
import getters from './moduleCategorieArticleGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

