
import axios from '@/axios.js'

export default {
  createCompteSmsOfficine ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/v1/officine/create-compte-sms', {payload})
        .then((response) => {
          commit('CREATE_COMPTE_SMS_OFFICINE', response.data)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  updateCompteSmsOfficine ({ commit }, payload) {
    const { id } = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/v1/officine/update/compte-sms/${id}`, {payload})
        .then((response) => {
          commit('UPDATE_COMPTE_SMS_OFFICINE', response.data)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  getCompteSmsOfficines ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/v1/officines/comptes-sms')
        .then((response) => {
          commit('SET_COMPTE_SMS_OFFICINE', response.data)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  getCompteSmsByOfficineId (context, idOfficine) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/v1/officine/compte-sms/${idOfficine}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  deleteCompteSmsOfficine ({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/v1/officine/delete/compte-sms/${id}`)
        .then((response) => {
          commit('DELETE_COMPTE_SMS_OFFICINE', id)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },

  getBalanceSMS () {
    return new Promise((resolve, reject) => {
      axios.get('/api/balance/sms')
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  getHistoriqueSMSSendedByClientId (context, id) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/historique/sms/${id}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  getDiffusions ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/diffusions')
        .then((response) => {
          commit('SET_DIFFUSION', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  getDiffusionByOfficine ({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/diffusions/${id}`)
        .then((response) => {
          commit('SET_DIFFUSION', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  getDiffusionById (context, id) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/diffusion/${id}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  createDiffusion ({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/campagne-sms/create', {payload})
        .then((response) => {
          commit('ADD_DIFFUSION', response.data.compagneSMS)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  SendTestSmsMessage (context, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/test-campagne', {payload})
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  startCampagneSmsMessage (context, campagneId) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/start-campagne/${campagneId}`).then((response) => {
        resolve(response)
      }).catch((error) => { reject(error) })
    })
  },
  sendAnnivClientSMSMessage (context, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/client/send-sms-anniv', {payload})
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  sendAnnivCommandeSMSMessage (context, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/client/commande/send-sms-anniv', {payload})
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  updateDiffusion (context, payload) {
    const {id} = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/update/diffusion/${id}`, {payload})
        .then((response) => {
          // commit('UPDATE_DIFFUSION', response.data.compagneSMS)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  deleteDiffusion ({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/delete/diffusion/${id}`)
        .then((response) => {
          commit('REMOVE_DIFFUSION', id)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  getRepertoiresByOfficine ({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/repertoire/${id}`)
        .then((response) => {
          commit('SET_REPERTOIRE', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  createRepertoire ({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/repertoire/create', {payload})
        .then((response) => {
          commit('ADD_REPERTOIRE', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  updateRepertoire ({commit}, payload) {
    const {id} = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/repertoire/update/${id}`, {payload})
        .then((response) => {
          commit('UPDATE_REPERTOIRE', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  removeRepertoire ({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/repertoire/delete/${id}`)
        .then((response) => {
          commit('REMOVE_REPERTOIRE', id)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  formatNomPrenomAndContact ({commit}, id_repertoire) {
    commit('FORMAT_NOM_PRENOM_AND_CONTACT', id_repertoire)
  },

  searchContactOnClientDb (context, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/repertoire-client-builder', {payload})
        .then((response) => {
          // commit('SET_CONTACT', response.data)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },

  removeContactOnClientContactDb ({commit}, id) {
    return  commit('DELETE_CONTACT', id)
  }

}
