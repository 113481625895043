export default {
  CaisseIsInit: state => {
    const init_array = state.operationsToday ? state.operationsToday.find((o) => o.typeOperation === 'Initialisation') : false
    return !!init_array
  },
  EncaisseSelectDate: state => {
    const reg_cmd_client_array_all = state.operationsToday ? state.operationsToday.filter((o) => o.typeOperation === 'Règlement cmde cli') : []
    const reg_cmd_client_array = reg_cmd_client_array_all.length > 0 ? reg_cmd_client_array_all.filter((o) => o.modepaiement === 'Espèce') : []
    const reg_cpt_array = state.operationsToday ? state.operationsToday.filter((o) => o.typeOperation === 'Règlement cpt') : []
    const init_array = state.operationsToday ? state.operationsToday.filter((o) => o.typeOperation === 'Initialisation') : []
    const appro_array = state.operationsToday ? state.operationsToday.filter((o) => o.typeOperation === 'Approvisionnement') : []

    const reg_cmd_client = reg_cmd_client_array.length > 0 ? reg_cmd_client_array.reduce((acc, reg) => {
      return acc + reg.montant
    }, 0) : 0

    const reg_cpt = reg_cpt_array.length > 0 ? reg_cpt_array.reduce((acc, reg) => {
      return acc + reg.montant
    }, 0) : 0

    const init = init_array.length > 0 ? init_array.reduce((acc, reg) => { return acc + reg.montant }, 0) : 0

    const appro = appro_array.length > 0 ? appro_array.reduce((acc, reg) => { return acc + reg.montant }, 0) : 0

    return (reg_cmd_client + reg_cpt + init + appro)

  },
  MontantCheqRecu: state => {
    const reg_cmd_client_array_all = state.operationsToday ? state.operationsToday.filter((o) => o.typeOperation === 'Règlement cmde cli') : []
    const reg_cmd_client_array = reg_cmd_client_array_all.length > 0 ? reg_cmd_client_array_all.filter((o) => o.modepaiement === 'Chèque') : []
    const reg_cpt_array_all = state.operationsToday ? state.operationsToday.filter((o) => o.typeOperation === 'Règlement cpt') : []
    const reg_cpt_array = reg_cpt_array_all.length > 0 ? reg_cpt_array_all.filter((o) => o.modepaiement === 'Chèque') : []

    const reg_cmd_client = reg_cmd_client_array.length > 0 ? reg_cmd_client_array.reduce((acc, reg) => {
      return acc + reg.montant
    }, 0) : 0
    const reg_cpt = reg_cpt_array.length > 0 ? reg_cpt_array.reduce((acc, reg) => {
      return acc + reg.montant
    }, 0) : 0

    return (reg_cmd_client + reg_cpt)
  },
  MontantDecaisse: state => {
    const versement_array_all = state.operationsToday ? state.operationsToday.filter((o) => o.typeOperation === 'Versement') : []
    const versement_cheq_array = versement_array_all.length > 0 ? versement_array_all.filter((o) => o.modepaiement === 'Espèce') : []

    const montant = versement_cheq_array.length > 0 ? versement_cheq_array.reduce((acc, vers) => {
      return acc + vers.montant
    }, 0) : 0

    return montant
  },

  MontantCheqEmis: state => {
    const versement_array_all = state.operationsToday ? state.operationsToday.filter((o) => o.typeOperation === 'Versement') : []
    const versement_cheq_array = versement_array_all.length > 0 ? versement_array_all.filter((o) => o.modepaiement === 'Chèque') : []

    const montant = versement_cheq_array.length > 0 ? versement_cheq_array.reduce((acc, vers) => {
      return acc + vers.montant
    }, 0) : 0

    return montant
  }
}
