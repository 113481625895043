/*=========================================================================================
  File Name: moduleSociete.js
  Description: Societe Module
  ----------------------------------------------------------------------------------------
==========================================================================================*/


import state from './moduleStockState.js'
import mutations from './moduleStockMutations.js'
import actions from './moduleStockActions.js'
import getters from './moduleStockGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

