/*=========================================================================================
  M.Djezou
  File Name: moduleRoleActions.js
  Description: Role Module Actions
  ----------------------------------------------------------------------------------------

==========================================================================================*/

import axios from '@/axios.js'

export default {

  getRoles ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/roles')
        .then((response) => {
          commit('SET_ROLES', response.data)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  addRole ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/role/add', {payload})
        .then((response) => {
          commit('ADD_ROLE', response.data)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updateRole ({ commit }, payload) {
    const { id } = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/role/update/${id}`, {payload})
        .then((response) => {
          commit('UPDATE_ROLE', response.data)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  deleteRole ({ commit}, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/role/delete/${id}`)
        .then((response) => {
          commit('DELETE_ROLE', id)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

}

