/*=========================================================================================
  File Name: moduleEntetePiedpageGetters.js
  Description: EntetePiedpage Module Getters
  ----------------------------------------------------------------------------------------
==========================================================================================*/

export default {
  getModeleEntetePiedPageByOfficine: state => idOfficine => {
    const modele = state.modeles.find((m) => m.Officines.includes(idOfficine))
    return modele ? modele : ''
  },
  getLogoUrl: state => {
    return state.logo ? state.logo.url !== null ? state.logo.url : '@/assets/images/logo/logo.png' : '@/assets/images/logo/logo.png'
  },
  getLogoId: state => {
    return state.logo._id !== null ? state.logo._id : null
  },
  getPiedpageUrl: state => {
    return state.piedpage ? state.piedpage.url !== null ? state.piedpage.url : '' : ''
  },
  getPiedpageId: state => {
    return state.piedpage ? state.piedpage._id !== null ? state.piedpage._id : null : null
  }
}
