

export default {

  ADD_PROFESSION (state, professions) {
    state.professions.unshift(professions)
  },

  SET_PROFESSION (state, professions) {
    state.professions = professions
  },

  UPDATE_FOURNISSEUR (state, profession) {
    const professionIndex = state.professions.findIndex((p) => p._id === profession._id)
    Object.assign(state.professions[professionIndex], profession)
  },

  REMOVE_PROFESSION (state, id) {
    const professionIndex = state.professions.findIndex((p) => p._id === id)
    state.professions.splice(professionIndex, 1)
  }

}
