/*=========================================================================================
  M.Djezou
  File Name: moduleRubriqueDepenseActions.js
  Description: Rubrique Depense Module Actions
  ----------------------------------------------------------------------------------------

==========================================================================================*/

import axios from '@/axios.js'

export default {
  getRubriques ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/rubriques-depense')
        .then((response) => {
          commit('SET_RUBRIQUE', response.data)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  createRubrique ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/rubrique-depense/add', {payload})
        .then((response) => {
          commit('ADD_RUBRIQUE', response.data)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updateRubrique ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.put('/api/rubrique-depense/update', {payload})
        .then((response) => {
          commit('UPDATE_RUBRIQUE', response.data)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  softDeleteRubrique ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.put('/api/rubrique-depense/softdelete', {payload})
        .then((response) => {
          commit('DELETE_RUBRIQUE', response.data)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}