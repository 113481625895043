/*=========================================================================================
  File Name: moduleProforma.js
  Description: module proforma
  ----------------------------------------------------------------------------------------
==========================================================================================*/


import state from './moduleProformaState.js'
import mutations from './moduleProformaMutations.js'
import actions from './moduleProformaActions.js'
import getters from './moduleProformaGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

