/*=========================================================================================
  File Name: modulePrescripteurAction.js
  Description: mutation of module precripteur
  ----------------------------------------------------------------------------------------
==========================================================================================*/


export default {

  SET_PRESCRIPTEURS (state, prescripteurs) {
    state.prescripteurs = prescripteurs
  },

  ADD_PRESCRIPTEUR (state, prescripteur) {
    state.prescripteurs.unshift(prescripteur)
  },

  UPDATE_PRESCRIPTEUR (state, prescripteur) {
    const prescripteurIndex = state.prescripteurs.findIndex((m) => m._id === prescripteur._id)
    Object.assign(state.prescripteurs[prescripteurIndex], prescripteur)
  },

  REMOVE_PRESCRIPTEUR (state, itemId) {
    const prescripteurIndex = state.prescripteurs.findIndex((u) => u._id === itemId)
    state.prescripteurs.splice(prescripteurIndex, 1)
  }
}
