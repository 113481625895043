/*=========================================================================================
  File Name: moduleSociete.js
  Description: Societe Module
  ----------------------------------------------------------------------------------------
==========================================================================================*/


import state from './moduleSocieteState.js'
import mutations from './moduleSocieteMutations.js'
import actions from './moduleSocieteActions.js'
import getters from './moduleSocieteGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

