/*=========================================================================================
  File Name: moduleCommandeFourn.js
  Description: CommandeFour Module
  ----------------------------------------------------------------------------------------
==========================================================================================*/


import state from './moduleCommandeComptoireState.js'
import mutations from './moduleCommandeComptoireMutations.js'
import actions from './moduleCommandeComptoireActions.js'
import getters from './moduleCommandeComptoireGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

