/*=========================================================================================
  File Name: modulePersonnelActions.js
  Description: Personnel Module Actions
  ----------------------------------------------------------------------------------------
==========================================================================================*/

import axios from '@/axios.js'

export default {
  // addItem({ commit }, item) {
  //   return new Promise((resolve, reject) => {
  //     axios.post("/api/data-list/products/", {item: item})
  //       .then((response) => {
  //         commit('ADD_ITEM', Object.assign(item, {id: response.data.id}))
  //         resolve(response)
  //       })
  //       .catch((error) => { reject(error) })
  //   })
  // },
  createPersonnel ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/api/personnel/create', {payload})
        .then((response) => {
          console.log(response.data.data)
          commit('ADD_PERSONNEL', response.data.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  addCNIInfo ({commit}, payload) {
    const {PersonnelId} = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/personnel/update-cni/${PersonnelId}`, {payload})
        .then((response) => {
          commit('UPDATE_PERSONNEL', response.data)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })

    })
  },
  updatePersonnel ({commit}, payload) {
    const {id} = payload
    return new Promise((resolve, reject) => {
      axios.put(`/api/personnel/update/${id}`, {payload})
        .then((response) => {
          commit('UPDATE_PERSONNEL', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getPersonnels ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/api/personnels')
        .then((response) => {
          commit('SET_PERSONNELS', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchPersonnel (context, personnelId) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/personnels/${personnelId}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  removePersonnel ({ commit }, personnelId) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/personnel/drop/${personnelId}`)
        .then((response) => {
          commit('REMOVE_RECORD', personnelId)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
