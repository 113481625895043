export default {
  getOfficineById:  state =>   id  => {
    return state.officines.find((o) => o._id === id)
  },
  officineIsPrincipale: state => id => {
    const officine = state.officines.find((o) => o._id === id)
    return officine ? officine.OfficinePrincipale : false
  },
  getOfficineJoursOuvrable: state => id => {
    const officine = state.officines.find((o) => o._id === id)
    return officine ? officine.joursOuvrable : []
  },
  getOfficines: state => state.officines

}
