/*=========================================================================================
  File Name: moduleCommandeFournGetters.js
  Description: CommandeFourn Module Getters
  ----------------------------------------------------------------------------------------
==========================================================================================*/

export default {
  nbreToDay: state => id => {
    const agence = state.commandes_comptoire_today_by_agence ? state.commandes_comptoire_today_by_agence.find((a) => a.officine === id) : null
    return agence ? agence.commandes.length : 0
  },
  nbreHier: state => id => {
    const agence = state.commandes_comptoire_hier_by_agence ? state.commandes_comptoire_hier_by_agence.find((a) => a.officine === id) : null
    return agence ? agence.commandes.length : 0
  },
  nbreCurrentWeek: state => id => {
    const agence = state.commandes_comptoire_current_week_by_agence ? state.commandes_comptoire_current_week_by_agence.find((a) => a.officine === id) : null
    return agence ? agence.commandes.length : 0
  },
  nbrePrevWeek: state => id => {
    const agence = state.commandes_comptoire_prev_week_by_agence ? state.commandes_comptoire_prev_week_by_agence.find((a) => a.officine === id) : null
    return agence ? agence.commandes.length : 0
  },
  nbreCurrentMonth: state => id => {
    const agence = state.commandes_comptoire_current_month_by_agence ? state.commandes_comptoire_current_month_by_agence.find((a) => a.officine === id) : null
    return agence ? agence.commandes.length : 0
  },
  nbrePrevMonth: state => id => {
    const agence = state.commandes_comptoire_prev_month_by_agence ? state.commandes_comptoire_prev_month_by_agence.find((a) => a.officine === id) : null
    return agence ? agence.commandes.length : 0
  },
  nbreCurrentYear: state => id => {
    const agence = state.commandes_comptoire_current_year_by_agence ? state.commandes_comptoire_current_year_by_agence.find((a) => a.officine === id) : null
    return agence ? agence.commandes.length : 0
  },
  nbrePrevYear: state => id => {
    const agence = state.commandes_comptoire_prev_year_by_agence ? state.commandes_comptoire_prev_year_by_agence.find((a) => a.officine === id) : null
    return agence ? agence.commandes.length : 0
  },
  venteToDayTTC: state => id => {
    const agence = state.commandes_comptoire_today_by_agence ? state.commandes_comptoire_today_by_agence.find((a) => a.officine === id) : null
    const commandes = agence ? (agence.commandes ? agence.commandes.reduce((acc, commande) => {
      return acc + (commande.PartClient ? +commande.PartClient : 0)
    }, 0) : 0) : 0

    return commandes
  },
  venteHierTTC: state => id => {
    const agence = state.commandes_comptoire_hier_by_agence ? state.commandes_comptoire_hier_by_agence.find((a) => a.officine === id) : null
    const commandes = agence ? (agence.commandes ? agence.commandes.reduce((acc, commande) => {
      return acc + (commande.PartClient ? +commande.PartClient : 0)
    }, 0) : 0) : 0

    return commandes
  },
  venteCurrentWeekTTC: state => id => {
    const agence = state.commandes_comptoire_current_week_by_agence ? state.commandes_comptoire_current_week_by_agence.find((a) => a.officine === id) : null
    const commandes = agence ? (agence.commandes ? agence.commandes.reduce((acc, commande) => {
      return acc + (commande.PartClient ? +commande.PartClient : 0)
    }, 0) : 0) : 0

    return commandes
  },
  ventePrevWeekTTC: state => id => {
    const agence = state.commandes_prev_week_by_agence ? state.commandes_prev_week_by_agence.find((a) => a.officine === id) : null
    const commandes = agence ? (agence.commandes ? agence.commandes.reduce((acc, commande) => {
      return acc + (commande.PartClient ? +commande.PartClient : 0)
    }, 0) : 0) : 0

    return commandes
  },
  venteCurrentMonthTTC: state => id => {
    const agence = state.commandes_comptoire_current_month_by_agence ? state.commandes_comptoire_current_month_by_agence.find((a) => a.officine === id) : null
    const commandes = agence ? (agence.commandes ? agence.commandes.reduce((acc, commande) => {
      return acc + (commande.PartClient ? +commande.PartClient : 0)
    }, 0) : 0) : 0

    return commandes
  },
  ventePrevMonthTTC: state => id => {
    const agence = state.commandes_comptoire_prev_month_by_agence ? state.commandes_comptoire_prev_month_by_agence.find((a) => a.officine === id) : null
    const commandes = agence ? (agence.commandes ? agence.commandes.reduce((acc, commande) => {
      return acc + (commande.PartClient ? +commande.PartClient : 0)
    }, 0) : 0) : 0

    return commandes
  },
  venteCurrentYearTTC: state => id => {
    const agence = state.commandes_comptoire_current_year_by_agence ? state.commandes_comptoire_current_year_by_agence.find((a) => a.officine === id) : null
    const commandes = agence ? (agence.commandes ? agence.commandes.reduce((acc, commande) => {
      return acc + (commande.PartClient ? +commande.PartClient : 0)
    }, 0) : 0) : 0

    return commandes
  },
  ventePrevYearTTC: state => id => {
    const agence = state.commandes_comptoire_prev_year_by_agence ? state.commandes_comptoire_prev_year_by_agence.find((a) => a.officine === id) : null
    const commandes = agence ? (agence.commandes ? agence.commandes.reduce((acc, commande) => {
      return acc + (commande.PartClient ? +commande.PartClient : 0)
    }, 0) : 0) : 0

    return commandes
  }
}
