/*=========================================================================================
  File Name: moduleSmsMarketingState.js
  Description: state of module SmsMarketing
  ----------------------------------------------------------------------------------------
==========================================================================================*/

export default {
  comptes: [],
  diffusions: [],
  repertoires: [],
  contacts: []
}
