/*=========================================================================================
  File Name: moduleCommandeComptoireMutations.js
  Description: CommandeComptoire Module Mutations
  ----------------------------------------------------------------------------------------
==========================================================================================*/

export default {
  SET_COMMANDES_COMPTOIRE (state, commande_comptoires) {
    state.commande_comptoires = commande_comptoires
  },
  ADD_COMMANDE_COMPTOIRE (state, commande_comptoire) {
    state.commande_comptoires.unshift(commande_comptoire)
  },
  UPDATE_COMMANDE_COMPTOIRE (state, commande_comptoire) {
    const commandeIndex = state.commande_comptoires.findIndex((m) => m._id === commande_comptoire._id)
    Object.assign(state.commande_comptoires[commandeIndex], commande_comptoire)
  },
  DELETE_COMMANDE_COMPTOIRE (state, itemId) {
    const commandeIndex = state.commande_comptoires.findIndex((u) => u._id === itemId)
    state.commande_comptoires.splice(commandeIndex, 1)
  },
  ADD_COMMANDE_COMPTOIRE_TODAY_BY_AGENCE (state, data) {
    const {OfficineId, respData, dateDashBoard} = data
    respData.filter((client) => {
      client.commandes.filter((commande) => {
        const date_commande = (new Date(commande.DateCommd)).getTime()
        if (date_commande === (new Date(dateDashBoard)).getTime()) {
          state.commandes_comptoire_toDay.push(commande)
        }
      })
    })
    state.commandes_comptoire_today_by_agence.push({officine: OfficineId, commandes: state.commandes_comptoire_toDay})
    state.commandes_comptoire_toDay = []
  },
  ADD_COMMANDE_COMPTOIRE_PREVDAY_BY_AGENCE (state, data) {
    const {OfficineId, respData, dateDashBoard} = data
    respData.filter((client) => {
      client.commandes.filter((commande) => {
        const date_commande = (new Date(commande.DateCommd)).getTime()
        if (date_commande === (new Date(dateDashBoard)).getTime()) {
          state.commandes_comptoire_hier.push(commande)
        }
      })
    })
    state.commandes_comptoire_hier_by_agence.push({officine: OfficineId, commandes: state.commandes_comptoire_hier})
    state.commandes_comptoire_hier = []
  },
  ADD_COMMANDE_COMPTOIRE_CURRENTWEEK_BY_AGENCE (state, data) {
    const {OfficineId, respData, from, to} = data
    respData.filter((client) => {
      client.commandes.filter((commande) => {
        const date_commande = (new Date(commande.DateCommd)).getTime()
        if (date_commande >= (new Date(from)).getTime() && date_commande <= (new Date(to)).getTime()) {
          state.commandes_comptoire_current_week.push(commande)
        }
      })
    })
    state.commandes_comptoire_current_week_by_agence.push({officine: OfficineId, commandes: state.commandes_comptoire_current_week})
    state.commandes_comptoire_current_week = []
  },
  ADD_COMMANDE_COMPTOIRE_PREVWEEK_BY_AGENCE (state, data) {
    const {OfficineId, respData, from, to} = data
    respData.filter((client) => {
      client.commandes.filter((commande) => {
        const date_commande = (new Date(commande.DateCommd)).getTime()
        if (date_commande >= (new Date(from)).getTime() && date_commande <= (new Date(to)).getTime()) {
          state.commandes_comptoire_prev_Week.push(commande)
        }
      })
    })
    state.commandes_comptoire_current_week.push({officine: OfficineId, commandes: state.commandes_comptoire_prev_Week})
    state.commandes_comptoire_prev_Week = []
  },
  ADD_COMMANDE_COMPTOIRE_CURRENTMONTH_BY_AGENCE (state, data) {
    const {OfficineId, respData, from, to} = data
    respData.filter((client) => {
      client.commandes.filter((commande) => {
        const date_commande = (new Date(commande.DateCommd)).getTime()
        if (date_commande >= (new Date(from)).getTime() && date_commande <= (new Date(to)).getTime()) {
          state.commandes_comptoire_current_month.push(commande)
        }
      })
    })
    state.commandes_comptoire_current_month_by_agence.push({officine: OfficineId, commandes: state.commandes_comptoire_current_month})
    state.commandes_comptoire_current_month = []
  },
  ADD_COMMANDE_COMPTOIRE_PREVMONTH_BY_AGENCE (state, data) {
    const {OfficineId, respData, from, to} = data
    respData.filter((client) => {
      client.commandes.filter((commande) => {
        const date_commande = (new Date(commande.DateCommd)).getTime()
        if (date_commande >= (new Date(from)).getTime() && date_commande <= (new Date(to)).getTime()) {
          state.commandes_comptoire_prev_month.push(commande)
        }
      })
    })
    state.commandes_comptoire_prev_month_by_agence.push({officine: OfficineId, commandes: state.commandes_comptoire_prev_month})
    state.commandes_comptoire_prev_month = []
  },
  ADD_COMMANDE_COMPTOIRE_CURRENTYEAR_BY_AGENCE (state, data) {
    const {OfficineId, respData, from, to} = data
    respData.filter((client) => {
      client.commandes.filter((commande) => {
        const date_commande = (new Date(commande.DateCommd)).getTime()
        if (date_commande >= (new Date(from)).getTime() && date_commande <= (new Date(to)).getTime()) {
          state.commandes_comptoire_current_year.push(commande)
        }
      })
    })
    state.commandes_comptoire_current_year_by_agence.push({officine: OfficineId, commandes: state.commandes_comptoire_current_year})
    state.commandes_comptoire_current_year = []
  },
  ADD_COMMANDE_COMPTOIRE_PREVYEAR_BY_AGENCE (state, data) {
    const {OfficineId, respData, from, to} = data
    respData.filter((client) => {
      client.commandes.filter((commande) => {
        const date_commande = (new Date(commande.DateCommd)).getTime()
        if (date_commande >= (new Date(from)).getTime() && date_commande <= (new Date(to)).getTime()) {
          state.commandes_comptoire_prev_year.push(commande)
        }
      })
    })
    state.commandes_comptoire_prev_year_by_agence.push({officine: OfficineId, commandes: state.commandes_comptoire_prev_year})
    state.commandes_comptoire_prev_year = []
  }
}
